import React from 'react';
import { StyleSheet } from 'react-native';

import { palette, typography } from 'src/styles';

import { StyledText } from './StyledText';

interface Props {
  message: string;
  type: 'error' | 'info';
}

export const InputMessage: React.FC<Props> = ({ message, type }) => (
  <StyledText
    testID={type === 'error' ? 'input-error' : 'input-info'}
    style={[styles.description, type === 'error' && styles.errorText]}
  >
    {message}
  </StyledText>
);

const styles = StyleSheet.create({
  description: {
    color: palette.grey5,
    marginTop: 4,
    ...typography.text1,
  },
  errorText: {
    color: palette.red,
  },
});
