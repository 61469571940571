import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { palette, typography } from 'src/styles';

import { Icon } from '../Icon/Icon';
import { Pressable } from '../Pressable';
import { StyledText } from '../StyledText';

interface Props {
  title: string;
  checked?: boolean;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  testID?: string;
}

export const Radio: React.FC<Props> = ({ title, checked, onPress, style, disabled, testID }) => (
  <Pressable onPress={onPress} disabled={disabled} testID={testID || 'radio-button'}>
    <View style={[styles.wrapper, style]} dataSet={{ checked }}>
      <Icon
        width={20}
        name={checked ? 'checked-dot' : 'unchecked-dot'}
        color={disabled ? palette.grey5 : palette.blue}
      />
      <StyledText style={[typography.body2, styles.label, disabled && styles.labelDisabled]}>
        {title}
      </StyledText>
    </View>
  </Pressable>
);

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    color: palette.blue,
    marginLeft: 9,
  },
  labelDisabled: {
    color: palette.grey5,
  },
});
