import Quill, { SelectionChangeHandler } from 'quill';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, TextInput, View } from 'react-native';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import './quill.css';

import { deltaToMarkdown } from 'src/helpers';
import { i18n } from 'src/locale';
import { fonts, palette, typography } from 'src/styles';

import type { Props } from './Editor';
import { EditorToolbar, SetFormatHandler } from './EditorToolbar';
import { markdownToTHML } from './common';

export const Editor: React.FC<Props> = ({ initialValue, onChange, showTitle, title }) => {
  const [currentFormats, setCurrentFormats] = useState({});

  const wrapper = useRef<HTMLDivElement>(null);
  const titleInput = useRef<TextInput>(null);
  const editorRef = useRef<Quill>();

  const handleTextChange = useCallback(
    (editor: Quill) => {
      const content = editor.getContents();
      const md = deltaToMarkdown(content);
      onChange({ text: md });
    },
    [onChange],
  );

  const handleTitleChange = (value: string) => {
    onChange({ title: value });
  };

  const handleSelectionChange: SelectionChangeHandler = useCallback((range) => {
    try {
      const rangeFormat = editorRef.current!.getFormat(range);
      setCurrentFormats(rangeFormat);
    } catch (e) {}
  }, []);

  const setFormat: SetFormatHandler = (formatType, value) => {
    editorRef.current?.format(formatType, value, 'user');
    setCurrentFormats((state) => ({ ...state, [formatType]: value }));
  };

  useEffect(() => {
    const editor = new Quill(wrapper.current as HTMLDivElement, {
      formats: ['bold', 'italic', 'underline', 'script'],
      placeholder: i18n.t('notes:placeholder'),
    });
    const initParsed = markdownToTHML(initialValue);

    editorRef.current = editor;
    editor.clipboard.dangerouslyPasteHTML(initParsed);
    editor.on('text-change', () => handleTextChange(editor));
    editor.on('selection-change', handleSelectionChange);
  }, [initialValue, onChange, handleTextChange, handleSelectionChange]);

  useEffect(() => {
    if (titleInput.current) {
      titleInput.current.focus();
    }
  }, []);

  return (
    <View style={styles.wrapper}>
      <EditorToolbar formats={currentFormats} setFormat={setFormat} />
      {showTitle && (
        <TextInput
          value={title}
          style={styles.titleInput}
          placeholder={i18n.t('notes:title')}
          placeholderTextColor={palette.grey4}
          onChangeText={handleTitleChange}
          testID="note-editor-title-input"
          ref={titleInput}
        />
      )}
      <div ref={wrapper} style={styleVariables} data-testid="note-editor-textarea" />
    </View>
  );
};

const styleVariables = {
  '--grey4': palette.grey4,
  '--grey8': palette.grey8,
  '--font': fonts.sourceSans,
} as React.CSSProperties;

const styles = StyleSheet.create({
  wrapper: {
    borderWidth: 1,
    borderColor: palette.grey2,
    borderRadius: 5,
    overflow: 'hidden',
  },
  titleInput: {
    ...typography.body3Bold,
    borderColor: palette.grey2,
    paddingTop: 16,
    paddingHorizontal: 16,
    color: palette.grey8,
  },
});
