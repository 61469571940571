import { ComponentProps } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { SecondaryButton } from 'src/components';
import { StyledText } from 'src/components/StyledText';
import { formatDateWithMonths } from 'src/helpers';
import { palette } from 'src/styles';

import { PlanDetailsBox } from './PlanDetailsBox';
import { isStudentRecurlyPlan } from '../../helpers';
import { useSubscriptionRenewalModal, useBillingInformation, useSubscriptionInfo } from '../../hooks';
import { ReactivateRenewalModal } from '../modals/ReactivateRenewalModal';

interface Props {
  boxProps?: Partial<ComponentProps<typeof PlanDetailsBox>>;
}

export const AccountStatusBox: React.FC<Props> = ({ boxProps }) => {
  const { t } = useTranslation('subscriptionDetails');
  const { data: subscriptionData } = useSubscriptionInfo();
  const { data: billingInformation } = useBillingInformation();
  const { showReactivateConfirmation, reactivateModal } =
    useSubscriptionRenewalModal(ReactivateRenewalModal);

  const expirationDate = formatDateWithMonths(
    subscriptionData!.inTrial ? subscriptionData!.currentPeriodEndsAt : subscriptionData!.currentTermEndsAt,
  );
  const isStudent = isStudentRecurlyPlan(subscriptionData!.planCode);

  const needsReactivation = !isStudent && subscriptionData && !subscriptionData.isActive;
  const displayReactivateInfo = needsReactivation && !!billingInformation?.creditCardInformation;
  const displayBillingInfo = needsReactivation && !billingInformation?.creditCardInformation;

  const getSubscriptionRenewalInfoTransKey = () => {
    if (subscriptionData!.inTrial) {
      return 'subscriptionDetails:' + (subscriptionData!.isActive ? 'trialWillRenew' : 'trialWillExpire');
    } else {
      return (
        'subscriptionDetails:' +
        (subscriptionData!.autoRenew && subscriptionData!.isActive
          ? 'subscriptionWillRenew'
          : 'subscriptionWillExpire')
      );
    }
  };

  return (
    <PlanDetailsBox
      title={t('accountStatus')}
      subtitle={!subscriptionData ? '' : t(subscriptionData.isActive ? 'active' : 'canceled')}
      testID="mini-box-account-status"
      {...boxProps}
    >
      <>
        {subscriptionData?.isActive && (
          <StyledText style={styles.details} testID="renewal-info">
            <Trans
              i18nKey={getSubscriptionRenewalInfoTransKey()}
              components={{
                b: <StyledText style={[styles.details, styles.boldText]} />,
              }}
              values={{ date: expirationDate }}
            />
          </StyledText>
        )}
        {displayReactivateInfo && (
          <>
            <SecondaryButton
              title={t('reactivateRenewal')}
              size="tiny"
              containerStyle={styles.button}
              onPress={showReactivateConfirmation}
              testID="button-reactivate-renewal"
            />
            <StyledText style={styles.warningText} testID="subscription-expiration-info">
              <Trans
                i18nKey="subscriptionDetails:subscriptionWillExpireWarning"
                components={{
                  b: <StyledText style={[styles.warningText, styles.boldText]} />,
                  u: <StyledText style={[styles.warningText, styles.underlineText]} />,
                }}
                values={{ date: expirationDate }}
              />
            </StyledText>
          </>
        )}
        {displayBillingInfo && (
          <StyledText style={styles.warningText}>{t('enterBillingInformationToReactivate')}</StyledText>
        )}
        {reactivateModal}
      </>
    </PlanDetailsBox>
  );
};

const styles = StyleSheet.create({
  warningText: {
    color: palette.red,
  },
  boldText: {
    fontWeight: 'bold',
  },
  underlineText: {
    textDecorationLine: 'underline',
  },
  details: {
    color: palette.grey5,
    lineHeight: 20,
  },
  button: {
    marginBottom: 5,
  },
});
