import React, { useCallback, useState } from 'react';

import { useRenewSubscription, useSubscriptionInfo } from './queries';
import { ReactivateRenewalModalProps } from '../types';

export function useSubscriptionRenewalModal(
  ModalComponent: React.ComponentType<ReactivateRenewalModalProps>,
) {
  const { data: subscriptionStatus } = useSubscriptionInfo();
  const { mutate: renewSubscription, isPending } = useRenewSubscription();
  const [showModal, setShowModal] = useState(false);

  const showReactivateConfirmation = useCallback(() => {
    setShowModal(true);
  }, []);

  const cancel = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleSubscriptionRenewal = useCallback(async () => {
    renewSubscription(undefined, {
      onSuccess: () => {
        setShowModal(false);
      },
    });
  }, [renewSubscription]);

  const reactivateModal = showModal ? (
    <ModalComponent
      subscriptionStatus={subscriptionStatus!}
      cancel={cancel}
      confirm={handleSubscriptionRenewal}
      isLoading={isPending}
    />
  ) : null;

  return { showReactivateConfirmation, reactivateModal };
}
