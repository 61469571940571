import { useMutation, useQueryClient, useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { client } from 'src/api/client';
import { markContentListAsSeen } from 'src/api/content';
import { CONTENT_LIST_ITEMS_PER_PAGE, CONTENT_LIST_ITEMS_PER_PAGE_NATIVE } from 'src/constants/constants';
import { NEW_LIST_PARAM, UPDATED_LIST_PARAM, contentQueryKeys } from 'src/constants/content';
import {
  AccordionOpenListType,
  AccordionOpenPageType,
  ContentInList,
  ContentListResponse,
  ContentListType,
  ItemListByLetterFetchParams,
} from 'src/constants/types';
import { convertObjectToSnakeCase, getDataFromInfiniteQuery, isWeb } from 'src/helpers';

const listUrls: {
  [contentType in ContentListType]: string;
} = {
  [ContentListType.Drugs]: '/contents/monograph/',
  [ContentListType.DrugHandouts]: '/contents/veterinary-medication-guide/',
  [ContentListType.ClinicalHandouts]: '/contents/patient-guides/',
  [ContentListType.Algorithms]: '/contents/algorithm/',
  [ContentListType.DxTx]: '/contents/clinical-brief/',
  [ContentListType.Appendix]: '/contents/appendix/',
};

const pageTypes: {
  [contentType in ContentListType]: AccordionOpenPageType;
} = {
  [ContentListType.Drugs]: 'monograph',
  [ContentListType.DrugHandouts]: 'veterinary-medication-guide',
  [ContentListType.ClinicalHandouts]: 'patient-guides',
  [ContentListType.Algorithms]: 'algorithm',
  [ContentListType.DxTx]: 'clinical-brief',
  [ContentListType.Appendix]: 'appendix',
};

interface MiscQueryParams {
  refetchOnMount?: boolean | 'always';
}

const contentListQueryFunction = async <CT extends ContentListType, C = ContentInList[CT]>(
  contentListType: CT,
  pageNumber: number,
  params?: Partial<ItemListByLetterFetchParams>,
) => {
  const response = await client.request<ContentListResponse<C>>({
    url: listUrls[contentListType],
    params: convertObjectToSnakeCase({
      pageSize: isWeb ? CONTENT_LIST_ITEMS_PER_PAGE : CONTENT_LIST_ITEMS_PER_PAGE_NATIVE,
      page: pageNumber,
      ...params,
    }),
  });

  const { results, next, count, unseenCount } = response.data;

  return {
    results,
    count,
    nextPage: next ? pageNumber + 1 : undefined,
    unseenCount,
  };
};

export const useContentListByContentType = <CT extends ContentListType, C = ContentInList[CT]>(
  contentListType: CT,
  params?: Partial<ItemListByLetterFetchParams>,
  reactQueryParams: MiscQueryParams = {},
) => {
  return useSuspenseInfiniteQuery({
    queryKey: contentQueryKeys.list(contentListType, params),
    queryFn: async ({ pageParam }) => contentListQueryFunction<CT, C>(contentListType, pageParam, params),
    initialPageParam: 1,
    getNextPageParam: (page) => page.nextPage,
    select: (data) => ({
      ...getDataFromInfiniteQuery(data),
      unseenCount: data.pages[0]?.unseenCount,
    }),
    refetchOnMount: isWeb, // for native we refetch on component level
    ...reactQueryParams,
  });
};

export const useOpenAccordionAction = (contentListType: ContentListType) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (accordionType: AccordionOpenListType) =>
      markContentListAsSeen({
        accordion: accordionType,
        pageType: pageTypes[contentListType],
      }),
    onSuccess: (_, accordionType) => {
      const listParam = listParamByAccordionType[accordionType];
      if (listParam) {
        queryClient.invalidateQueries({
          queryKey: contentQueryKeys.list(contentListType, listParam),
          refetchType: 'none',
        });
      }
    },
  });
};

const listParamByAccordionType: Record<AccordionOpenListType, Partial<ItemListByLetterFetchParams>> = {
  New: NEW_LIST_PARAM,
  Updated: UPDATED_LIST_PARAM,
};
