import React from 'react';

import { InfoModal } from 'src/components';
import { i18n } from 'src/locale';

import { ReactivateRenewalModalProps } from '../../types';

export const ReactivateRenewalModal: React.FC<ReactivateRenewalModalProps> = ({
  subscriptionStatus,
  isLoading,
  cancel,
  confirm,
}) => (
  <InfoModal
    close={cancel}
    header={i18n.t('subscriptionDetails:reactivateRenewal')}
    testID="modal-reactivate-renewal"
  >
    <InfoModal.Content>
      {i18n.t('subscriptionDetails:reactivateRenewalConfirm', {
        renewalDate: subscriptionStatus.inTrial
          ? subscriptionStatus.currentPeriodEndsAt
          : subscriptionStatus.currentTermEndsAt,
        amount: subscriptionStatus.priceAtRenewal,
      })}
    </InfoModal.Content>
    <InfoModal.Buttons direction="row">
      <InfoModal.Button title={i18n.t('goBack')} onPress={cancel} variant="secondary" />
      <InfoModal.Button
        title={i18n.t('subscriptionDetails:reactivateRenewal')}
        onPress={confirm}
        loading={isLoading}
        testID="modal-submit-btn"
      />
    </InfoModal.Buttons>
  </InfoModal>
);
