import * as R from 'ramda';
import React from 'react';

import { FullScreenTemplate } from 'src/components';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { SubscriptionForm } from '../components';
import { DEFAULT_PLAN_VALUES } from '../constants';
import { getBasePlanSetup } from '../helpers';
import { useSubscriptionInvitationVerification } from '../hooks/queries/subscription';

type Props = ScreenProps<Route.SubscriptionInvitation>;

export const SubscriptionInvitationScreen: React.FC<Props> = ({ route: { params } }) => {
  const { invitationId } = params ?? {};

  const { data, isLoading } = useSubscriptionInvitationVerification(invitationId);

  if (isLoading) {
    return <FullScreenTemplate isLoading />;
  }

  return (
    <SubscriptionForm
      initialPlanSetup={
        data
          ? R.mergeWith(R.defaultTo, DEFAULT_PLAN_VALUES, {
              ...getBasePlanSetup(data.plan),
              quantity: data.quantity,
            })
          : DEFAULT_PLAN_VALUES
      }
      subscriptionInvitationId={data ? invitationId : undefined}
    />
  );
};
