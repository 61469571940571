import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';

import { DividerButton, IconButton, InputItem, LoadingIndicator } from 'src/components';
import { i18n } from 'src/locale';
import { palette } from 'src/styles';

type Props = {
  setDiscountCode: (value: string) => void;
  savedValue?: string;
  alwaysVisible?: boolean;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  error?: string;
};

export const DiscountCodeInput: React.FC<Props> = ({
  setDiscountCode,
  savedValue,
  alwaysVisible,
  label,
  loading,
  disabled = false,
  error,
}) => {
  const [isCodeInputVisible, setCodeInputVisible] = useState(!!savedValue);
  const [value, setValue] = useState(savedValue ?? '');

  const changeValue = (newValue: string) => {
    setValue(newValue);
  };

  const clear = () => {
    setValue('');
    setDiscountCode('');
  };

  useEffect(() => {
    setValue(savedValue || '');
  }, [savedValue]);

  const toggleCodeInput = () => setCodeInputVisible((prev) => !prev);

  const applyDiscount = () => {
    setDiscountCode(value);
  };

  return !(isCodeInputVisible || alwaysVisible || value || error) ? (
    <DividerButton
      title={i18n.t('subscriptionProcess:addDiscountLabel')}
      onPress={toggleCodeInput}
      disabled={disabled}
      testID="plan-details-add-discount-code-btn"
    />
  ) : (
    <>
      <InputItem
        value={value}
        onChangeText={changeValue}
        label={label || i18n.t('subscriptionProcess:discountCode')}
        placeholder={i18n.t('subscriptionProcess:enterDiscountCode')}
        error={error}
        touched
        readOnly={disabled}
        testID="plan-details-discount-code-input"
      >
        {loading ? (
          <LoadingIndicator size="small" style={styles.loadingIndicator} />
        ) : savedValue && savedValue === value ? (
          error ? (
            <DividerButton title={i18n.t('subscriptionProcess:clear')} onPress={clear} noLines />
          ) : (
            <IconButton
              name="checkmark"
              containerStyle={styles.checkmarkIconContainer}
              color={palette.blue}
              width={14}
            />
          )
        ) : (
          <DividerButton
            title={i18n.t('subscriptionProcess:applyDiscount')}
            onPress={applyDiscount}
            noLines
            disabled={disabled}
            testID="plan-details-apply-discount-btn"
          />
        )}
      </InputItem>
    </>
  );
};

const styles = StyleSheet.create({
  checkmarkIconContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: 50,
  },
  loadingIndicator: {
    paddingRight: 20,
    justifyContent: 'center',
  },
});
