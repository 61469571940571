import React from 'react';

import { SubscriptionDetails as SubscriptionDetailsContent } from 'src/features/subscription';

export const SubscriptionDetails: React.FC = () => {
  return (
    <>
      <SubscriptionDetailsContent />
    </>
  );
};
