import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { SecondaryButton, PrimaryButton, StyledText } from 'src/components';
import { ApiErrorMessages, StudentVerificationResponseMessage } from 'src/constants/types';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { showNotification } from 'src/helpers';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

import { useStudentVerificationContext } from '../context/StudentVerificationContext';
import { useSkipSSOStep } from '../hooks/queries';
import { useStudentVerificationWebsocket } from '../hooks/useStudentVerificationWebsocket';

export const StudentSSOWindow: React.FC = () => {
  const { t } = useTranslation('subscriptionProcess');

  const {
    ssoLoginUrl,
    verificationId,
    onSSOProcessSuccess,
    currentStep,
    onSSOProcessSkip,
    onSSOExternalLoginSitePress,
  } = useStudentVerificationContext();

  const { mutate: skipSSOStep, isPending } = useSkipSSOStep();

  const handleDocumentsButtonPress = () => {
    skipSSOStep(verificationId!, {
      onSuccess: onSSOProcessSkip,
      onError: (err: any) => {
        const { detail } = getMessagesFromErrorResponse<ApiErrorMessages>(err);
        showNotification({ type: 'error', title: detail, autoHide: false });
      },
    });
  };

  const handleExternalLoginSitePress = () => {
    onSSOExternalLoginSitePress();
  };

  const handleWebsocketMessage = (message: StudentVerificationResponseMessage) => {
    // if user waits too long to sign in on university page, the process is automatically moved to docUpload step
    if (message.status === 'docUpload') {
      onSSOProcessSkip();
    }
  };

  useStudentVerificationWebsocket({
    verificationId: verificationId!,
    onSuccessMessage: onSSOProcessSuccess,
    onMessage: handleWebsocketMessage,
  });

  return (
    <>
      <StyledText style={styles.title}>{t('studentSSOHeader')}</StyledText>
      <StyledText style={styles.description}>{t('studentSSODescription')}</StyledText>

      {ssoLoginUrl && currentStep === 'sso' && (
        <Link to={ssoLoginUrl} external style={styles.link}>
          <PrimaryButton
            title={t('signInToMySchool')}
            onPress={handleExternalLoginSitePress}
            testID="sign-to-school-button"
          />
        </Link>
      )}
      {currentStep === 'sso-pending' && (
        <View style={styles.documentsInfoWrapper}>
          <StyledText style={styles.documentsDescription}>{t('ssoCantSignIn')}</StyledText>
          <SecondaryButton
            title={t('uploadProofOfEnrollment')}
            size="small"
            containerStyle={styles.documentsButton}
            onPress={handleDocumentsButtonPress}
            loading={isPending}
          />
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  title: {
    ...typography.body3Bold,
    color: palette.blue,
    marginBottom: 8,
  },
  description: {
    ...typography.body2,
    marginBottom: 28,
  },
  link: {
    alignSelf: 'flex-start',
  },
  documentsInfoWrapper: {
    marginTop: 40,
  },
  documentsDescription: {
    ...typography.body2,
    marginBottom: 14,
  },
  documentsButton: {
    alignSelf: 'flex-start',
  },
});
