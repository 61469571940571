import * as R from 'ramda';

import { ApiError } from 'src/constants/types';
import { getErrorMessageForApiError } from 'src/errorHandling/utils';
import type { TokenPayloadUserInfo } from 'src/features/auth/types';
import { GoogleAnalyticsService } from 'src/features/tracking';
import { convertObjectToSnakeCase, kebabCaseToNormal } from 'src/helpers/functions';
import { Nullable } from 'src/helpers/types';

import {
  BASIC_PLAN_MAX_LICENCE_QUANTITY,
  PRO_PLAN_MAX_LICENCE_QUANTITY,
  ROLES_CONFIG,
  STUDENT_ROLES,
} from './constants';
import {
  BasePlanSetup,
  BillingPeriodDependentPlan,
  CardType,
  isBillingPeriodDependentPlan,
  isQuantityDependentPlan,
  PlanConfig,
  PlanSetup,
  PromotionSetup,
  QuantityDependentPlan,
  RoleConfig,
  SingleLicensePlan,
  BillingPeriod,
  Plan,
  RecurlyPlan,
  Role,
  GetBillingInformationResponse,
  BillingInformation,
  SubscriptionData,
  Purchase,
  RecurlyStudentPlan,
  StudentRole,
  SubscriptionStatusInfo,
  SubscriptionStatus,
} from './types';

export function getRecurlyPlan(
  role: Role,
  quantity: number,
  plan: Plan = Plan.NORMAL,
  billingPeriod: BillingPeriod = BillingPeriod.ANNUAL,
): RecurlyPlan {
  const roleConfig: RoleConfig = ROLES_CONFIG[role];
  let planConfig = roleConfig[plan];

  if (!planConfig) {
    throw Error(`Cannot find plan setup for ${role} role and ${plan} plan`);
  }

  if (isQuantityDependentPlan(planConfig)) {
    planConfig = quantity > 1 ? planConfig.multiple : planConfig.single;
  } else if (quantity > 1) {
    // there's no plan with only multiple licences option, so if plan is not 'quantity dependand', it means that's only for single subscribers
    throw Error(`Cannot find plan setup for ${role} role, ${plan} plan and multiple licences`);
  }

  if (!Object.values(BillingPeriod).includes(billingPeriod)) {
    throw Error(`Cannot find plan setup for ${billingPeriod} billing period`);
  }

  if (isBillingPeriodDependentPlan(planConfig)) {
    return planConfig[billingPeriod];
  } else if (billingPeriod === BillingPeriod.MONTHLY) {
    // there's no plan with only monthly billing period, so if plan is not 'billing period dependent', it means that's only annual
    throw Error(`Cannot find plan setup for ${role} role, ${plan} and monthly billing period`);
  }

  return planConfig;
}

type MaybeBillingPeriod = BillingPeriod | null;

function findBillingPeriodInQuantityDependentPlan(
  recurlyPlan: RecurlyPlan,
  planConfig: QuantityDependentPlan,
): MaybeBillingPeriod {
  for (const plan of Object.values(planConfig)) {
    if (isBillingPeriodDependentPlan(plan)) {
      const billingPeriod = findBillingPeriodInPlanConfig(recurlyPlan, plan);
      if (billingPeriod) return billingPeriod;
    }
    if (Object.values(planConfig).includes(recurlyPlan)) {
      return BillingPeriod.ANNUAL;
    }
  }

  return null;
}

function findBillingPeriodInBillingPeriodDependentPlan(
  recurlyPlan: RecurlyPlan,
  planConfig: BillingPeriodDependentPlan,
): MaybeBillingPeriod {
  for (const [billingPeriod, billingPeriodDependentPlan] of Object.entries(planConfig) as [
    BillingPeriod,
    RecurlyPlan,
  ][]) {
    if (billingPeriodDependentPlan === recurlyPlan) {
      return billingPeriod;
    }
  }
  return null;
}

function findBillingPeriodInSingleLicensePlan(
  recurlyPlan: RecurlyPlan,
  planConfig: SingleLicensePlan,
): MaybeBillingPeriod {
  return planConfig === recurlyPlan ? BillingPeriod.ANNUAL : null;
}

function findBillingPeriodInPlanConfig(
  recurlyPlan: RecurlyPlan,
  planConfig: PlanConfig,
): MaybeBillingPeriod {
  if (isQuantityDependentPlan(planConfig)) {
    return findBillingPeriodInQuantityDependentPlan(recurlyPlan, planConfig);
  }
  if (isBillingPeriodDependentPlan(planConfig)) {
    return findBillingPeriodInBillingPeriodDependentPlan(recurlyPlan, planConfig);
  }
  return findBillingPeriodInSingleLicensePlan(recurlyPlan, planConfig);
}

type MaybePlanAndBillingPeriod = { plan: Plan; billingPeriod: BillingPeriod } | null;

function findPlanAndBillingPeriodInRole(
  recurlyPlan: RecurlyPlan,
  roleConfig: RoleConfig,
): MaybePlanAndBillingPeriod {
  for (const [plan, planConfig] of Object.entries(roleConfig) as [Plan, PlanConfig][]) {
    const billingPeriod: MaybeBillingPeriod = findBillingPeriodInPlanConfig(recurlyPlan, planConfig);
    if (billingPeriod) {
      return {
        plan,
        billingPeriod,
      };
    }
  }
  return null;
}

export function getPlanByRole(role: Role, defaultPlan: Plan): Plan {
  const roleConfig = ROLES_CONFIG[role];
  if (roleConfig[defaultPlan]) return defaultPlan;

  if (roleConfig[Plan.NORMAL]) return Plan.NORMAL;
  return Plan.PRO;
}

export function getBasePlanSetup(recurlyPlan: RecurlyPlan): BasePlanSetup {
  for (const [role, roleConfig] of Object.entries(ROLES_CONFIG) as [Role, RoleConfig][]) {
    const planAndBillingPeriod: MaybePlanAndBillingPeriod = findPlanAndBillingPeriodInRole(
      recurlyPlan,
      roleConfig,
    );
    if (planAndBillingPeriod) {
      return {
        role,
        ...planAndBillingPeriod,
      };
    }
  }
  throw Error(`Cannot find plan setup for plan ${recurlyPlan}`);
}

export const isStudentRole = (role: Role): role is StudentRole =>
  [Role.VETERINARY_STUDENT, Role.PHARMACY_STUDENT].includes(role);

export const isStudentRecurlyPlan = (recurlyPlan: RecurlyPlan) =>
  [RecurlyPlan.VETERINARY_PRO_STUDENT_ANNUAL, RecurlyPlan.PHARMACY_BASIC_STUDENT].includes(recurlyPlan);

export const getRecurlyStudentPlan = (role: StudentRole): RecurlyStudentPlan => {
  return role === Role.PHARMACY_STUDENT
    ? RecurlyPlan.PHARMACY_BASIC_STUDENT
    : RecurlyPlan.VETERINARY_PRO_STUDENT_ANNUAL;
};

export const getCvvPlaceholder = (cardType: CardType): string =>
  cardType === CardType.AmericanExpress ? '••••' : '•••';

export const getCreditCardNumberPlaceholder = (lastFour: string, cardType?: CardType): string => {
  switch (cardType) {
    case CardType.AmericanExpress:
      return `•••• •••••• •${lastFour}`;
    case CardType.DinersClub:
      return `•••• •••••• ${lastFour}`;
    default:
      return `•••• •••• •••• ${lastFour}`;
  }
};

export const arePlanParamsValid = (data: PlanSetup) => {
  const { billingPeriod, role, quantity, plan } = data;

  try {
    const recurlyPlan = getRecurlyPlan(role, quantity, plan, billingPeriod);
    if (!recurlyPlan) return false;
    if (quantity > getPlanMaxLicenseQuantity(plan)) return false;
  } catch (err) {
    return false;
  }
  return true;
};

export const hasRoleNormalAndProVariants = (role: Role): boolean =>
  R.allPass([R.has(Plan.NORMAL), R.has(Plan.PRO)])(ROLES_CONFIG[role]);

export const hasPlanSetupMonthlyAndAnnualPaymentOptions = (
  role: Role,
  plan: Plan,
  quantity: number,
): boolean => {
  const roleConfig = ROLES_CONFIG[role];
  let planConfig = roleConfig[plan];
  if (!planConfig) return false;
  if (isQuantityDependentPlan(planConfig)) {
    planConfig = planConfig[quantity === 1 ? 'single' : 'multiple'];
  }
  return !!planConfig && R.allPass([R.has(BillingPeriod.ANNUAL), R.has(BillingPeriod.MONTHLY)])(planConfig);
};

export const isRoleAStudentRole = (role: Role): boolean => STUDENT_ROLES.includes(role);

export const getPlanMaxLicenseQuantity = (plan: Plan): number =>
  plan === Plan.NORMAL ? BASIC_PLAN_MAX_LICENCE_QUANTITY : PRO_PLAN_MAX_LICENCE_QUANTITY;

export const doesRoleHaveProVariant = (role: Role) => !!ROLES_CONFIG[role].pro;

export const promotionAllowsPlanSelection = (promotion: PromotionSetup) =>
  promotion.basicPrice !== null && doesPromotionHaveProPlan(promotion);

export const doesPromotionHaveProPlan = (promotion: PromotionSetup) =>
  promotion.proAnnualPrice !== null || promotion.proMonthlyPrice !== null;

export const doesProPromotionAllowDifferentBillingPeriods = (promotion: PromotionSetup) =>
  promotion.proAnnualPrice !== null && promotion.proMonthlyPrice !== null;

export const getInitialPromoBillingPeriod = (promotion: PromotionSetup, plan: Plan) => {
  if (plan === Plan.PRO) {
    return promotion.proAnnualPrice ? BillingPeriod.ANNUAL : BillingPeriod.MONTHLY;
  }

  return BillingPeriod.ANNUAL;
};

export const getPlanDetailsFromPromotion = (
  promotion: PromotionSetup,
  predefinedData: Partial<PlanSetup> = {},
): PlanSetup => {
  const hasProPlan = doesPromotionHaveProPlan(promotion);
  const plan = predefinedData.plan ?? (hasProPlan ? Plan.PRO : Plan.NORMAL);
  const billingPeriod = predefinedData.billingPeriod ?? getInitialPromoBillingPeriod(promotion, plan);

  return {
    role: Role.VETERINARY,
    quantity: 1,
    discountCode: '',
    ...predefinedData,
    billingPeriod,
    plan,
  };
};

export const isBillingInformation = (
  response: GetBillingInformationResponse,
): response is BillingInformation => {
  return !!response.billingAddress;
};

export const trackSubscriptionStepGAEvent = (
  sectionData: Partial<SubscriptionData>,
  formData: Nullable<SubscriptionData>,
) => {
  if (sectionData.createAccount) {
    GoogleAnalyticsService.track('signup');
  } else if (!!sectionData.planSetup && !!sectionData.purchasePreview) {
    GoogleAnalyticsService.track(
      'add_to_cart',
      getGAEventData({
        planSetup: sectionData.planSetup!,
        purchasePreview: sectionData.purchasePreview!,
      }),
    );
  } else if (sectionData.completeYourProfile) {
    GoogleAnalyticsService.track('begin_checkout', getGAEventData(formData));
  } else if (sectionData.paymentDetails) {
    const data = convertObjectToSnakeCase({
      ...getGAEventData(formData),
      coupon: formData.planSetup?.discountCode,
      paymentType: sectionData.paymentDetails.creditCardInformation.cardType,
    });
    GoogleAnalyticsService.track('add_payment_info', data);
  }
};

export const trackPurchaseGAEvent = (
  subscriptionData: Nullable<SubscriptionData>,
  invoiceNumber: string,
) => {
  GoogleAnalyticsService.track(
    'purchase',
    convertObjectToSnakeCase({
      ...getGAEventData(subscriptionData!),
      transactionId: invoiceNumber,
      coupon: subscriptionData.planSetup?.discountCode,
    }),
  );
};

const getGAEventData = (params: { purchasePreview: Purchase | null; planSetup: PlanSetup | null }) => {
  const { planSetup, purchasePreview } = params;
  return {
    currency: 'USD',
    value: purchasePreview?.dueToday,
    items: [
      convertObjectToSnakeCase({
        itemId: planSetup
          ? planSetup.plan === Plan.NORMAL
            ? 'plumbs-veterinary-drugs'
            : 'plumbs-pro'
          : undefined,
        itemName: planSetup
          ? planSetup.plan === Plan.NORMAL
            ? "Plumb's Veterinary Drugs"
            : "Plumb's Pro"
          : undefined,
        price: purchasePreview?.dueToday,
        quantity: planSetup?.quantity,
        itemCategory: planSetup ? kebabCaseToNormal(planSetup.role) : undefined,
        itemVariant: planSetup
          ? planSetup.billingPeriod === BillingPeriod.ANNUAL
            ? 'Bill Annualy'
            : 'Bill Monthly'
          : undefined,
      }),
    ],
  };
};

export const isExpiredManagedGroup = (
  data: Pick<TokenPayloadUserInfo, 'groupInfo' | 'expiredSubscription'>,
) => data.groupInfo?.removed === false && data.groupInfo.isManagedGroup && data.expiredSubscription;

export const getErrorMessagesForSubscriptionForm = (errors: any) => {
  const {
    firstName,
    lastName,
    email,
    password,
    discountCode,
    quantity,
    address,
    demographics: demographicsRaw,
    ...other
  } = errors;

  const getErrors = R.pipe<
    Record<string, ApiError | ApiError[]>,
    Record<string, ApiError | ApiError[]>,
    Record<string, string>
  >(R.reject(R.anyPass([R.isEmpty, R.isNil])), R.mapObjIndexed(getErrorMessageForApiError));

  const { practice, ...demographics } = demographicsRaw || {};

  const addressErrors = getErrors(address || {});
  const demographicsErrors = getErrors(demographics || {});
  const accountErrors = getErrors({ email, password });
  const planErrors = getErrors({ discountCode, quantity });
  const profileErrors = { ...getErrors({ firstName, lastName, practice }), ...addressErrors };
  const otherErrors = getErrors(other);

  return {
    accountErrors,
    profileErrors,
    demographicsErrors,
    planErrors,
    otherErrors,
  };
};

export const isPromotionForNewSubscribersOnlyError = (error: any) =>
  error?.response?.data?.detail?.code === 'new_subscribers_only';

export const isPromotionForExistingSubscribersOnlyError = (error: any) =>
  error?.response?.data?.detail?.code === 'existing_subscribers_only';

interface CanUpgradeToProParams {
  isPro: boolean;
  inTrial: boolean;
  role: Role;
  numberOfLicences: number;
  appliedPromotion?: PromotionSetup;
}

export const canUpgradeToPro = (params: CanUpgradeToProParams) => {
  const baseValue =
    !params.isPro &&
    !isStudentRole(params.role) &&
    !params.inTrial &&
    doesRoleHaveProVariant(params.role) &&
    (!params.appliedPromotion || doesPromotionHaveProPlan(params.appliedPromotion));

  return {
    now: baseValue && params.numberOfLicences <= PRO_PLAN_MAX_LICENCE_QUANTITY,
    afterLicencesReduction: baseValue && params.numberOfLicences > PRO_PLAN_MAX_LICENCE_QUANTITY,
  };
};

export const parseSubscriptionDataFromBackend = (data: SubscriptionStatusInfo) => {
  const isCancelled = data.state === SubscriptionStatus.CANCELED;
  const isActive = data.state === SubscriptionStatus.ACTIVE;
  const isExpired = data.state === SubscriptionStatus.EXPIRED;
  const planSetup = getBasePlanSetup(data.planCode);
  const isPro = planSetup.plan === Plan.PRO;
  const isStudent = isStudentRecurlyPlan(data.planCode);

  const canManageLicencesNumber = !isStudent && !data.inTrial;
  const canUserUpgradeToPro =
    data &&
    planSetup &&
    canUpgradeToPro({
      inTrial: !!data.inTrial,
      isPro,
      appliedPromotion: data.promotion,
      numberOfLicences: data.numberOfLicences,
      role: planSetup.role,
    });

  const canAddMoreLicences =
    canManageLicencesNumber &&
    data.numberOfLicences < getPlanMaxLicenseQuantity(planSetup.plan) &&
    !data.promotion;

  return {
    ...data,
    isCancelled,
    isActive,
    isExpired,
    planSetup,
    canManageLicencesNumber,
    canUserUpgradeToPro,
    canAddMoreLicences,
  };
};
