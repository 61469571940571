import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { PrimaryButton, EmailsInput, RadioGroup, StyledText } from 'src/components';
import { SUPPORT_LINK } from 'src/constants/externalLinks';
import { getMessagesFromErrorResponse } from 'src/errorHandling/utils';
import { useInviteGroupMembers, useLicencesInfo } from 'src/features/adminDashboard/hooks';
import { GroupMemberAccessLevel } from 'src/features/adminDashboard/types';
import { GroupRole } from 'src/features/auth/enums';
import { useUserInfo } from 'src/features/auth/hooks';
import { showNotification } from 'src/helpers';
import { Route } from 'src/navigation';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

interface Props {
  close: () => void;
}

type AccessLevel = GroupMemberAccessLevel.Basic | GroupMemberAccessLevel.Pro;

export const InviteMembers: React.FC<Props> = ({ close }) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [error, setError] = useState<string>();
  const [accessLevel, setAccessLevel] = useState<AccessLevel>();

  const { mutate: inviteGroupMembers, isPending, data } = useInviteGroupMembers();

  const { t } = useTranslation('adminDashboard');

  const {
    permissions: { canManagePlan },
  } = useUserInfo();
  const { data: licencesData } = useLicencesInfo();

  const availableLicenses = (() => {
    if (!licencesData) {
      return 0;
    }
    if (licencesData.isMixedGroup) {
      const licences =
        accessLevel === GroupMemberAccessLevel.Basic
          ? licencesData.licences.basic
          : licencesData.licences.pro;
      return licences.available;
    } else {
      return licencesData.licences.available;
    }
  })();

  useEffect(() => {
    if (licencesData?.isMixedGroup) {
      setAccessLevel(GroupMemberAccessLevel.Basic);
    }
  }, [licencesData?.isMixedGroup]);

  useEffect(() => {
    if (emails.length > availableLicenses) {
      setError(t('tooManyInvitationsError', { count: availableLicenses }));
    } else {
      setError(undefined);
    }
  }, [emails, availableLicenses, t]);

  const submitForm = async () => {
    inviteGroupMembers(
      { emails, role: GroupRole.MEMBER, accessLevel },
      {
        onSuccess: (data) => {
          if (!data.memberEmails.length) {
            showNotification({ type: 'success' });
            close();
          }
        },
        onError: (err: any) => {
          const status = err?.response?.status;
          const { emails, detail } = getMessagesFromErrorResponse<{ emails: string }>(err);
          if (emails) {
            setError(emails);
          } else {
            showNotification({ type: 'error', title: detail });
          }
          if (status === 403) {
            // Cannot invite users because group is frozen; closing modal so the frozen group message is visible
            close();
          }
        },
      },
    );
  };

  const needMoreLicensesComponent = (
    <StyledText style={typography.body2}>
      {/* eslint-disable-next-line react-native/no-raw-text */}
      {t('needMoreLicenses')}{' '}
      {canManagePlan ? (
        <Link to={{ route: Route.SubscriptionDetails }} onPress={close}>
          <StyledText style={styles.highlightedText}>{t('upgradePlan')}</StyledText>
        </Link>
      ) : (
        <Link to={SUPPORT_LINK} external>
          <StyledText style={styles.highlightedText}>{t('contactUs', { ns: 'common' })}</StyledText>
        </Link>
      )}
    </StyledText>
  );

  return (
    <View testID="group-member-tab">
      {data?.memberEmails?.length ? (
        <>
          <StyledText style={[typography.body3SemiBold, styles.header]}>
            {t('membersOfOtherGroupWarningHeader', { count: data.memberEmails.length })}
          </StyledText>
          <StyledText style={[typography.body2, styles.warningInfo]}>
            {t('membersOfOtherGroupWarningInfo', { count: data.memberEmails.length })}
          </StyledText>
          {data.memberEmails.map((email) => (
            <StyledText key={email} style={typography.body2}>
              • {email}
            </StyledText>
          ))}
          <View style={styles.buttonWrapper}>
            <PrimaryButton title={t('ok', { ns: 'common' })} onPress={close} />
          </View>
        </>
      ) : (
        <>
          {licencesData?.isMixedGroup && accessLevel && (
            <RadioGroup
              items={[
                {
                  title: t('basicLicense'),
                  value: GroupMemberAccessLevel.Basic,
                  testID: 'basic-licenses-radio',
                },
                {
                  title: t('proLicense'),
                  value: GroupMemberAccessLevel.Pro,
                  testID: 'pro-licenses-radio',
                },
              ]}
              value={accessLevel}
              onChange={(value) => setAccessLevel(value as AccessLevel)}
            />
          )}
          {availableLicenses < 1 ? (
            <>
              <StyledText style={[typography.body2]}>
                {t('noAvailableLicenses', {
                  role: t(
                    licencesData?.isMixedGroup
                      ? accessLevel === GroupMemberAccessLevel.Pro
                        ? 'proGroupMember'
                        : 'basicGroupMember'
                      : 'member',
                  ),
                })}
              </StyledText>
              {needMoreLicensesComponent}
            </>
          ) : (
            <>
              <Trans
                i18nKey="adminDashboard:inviteDescription"
                count={availableLicenses}
                components={{
                  p: <StyledText style={[typography.body2, styles.description]} />,
                  highlighted: <StyledText style={styles.highlightedText} />,
                }}
              />
              <EmailsInput
                value={emails}
                onChange={setEmails}
                error={error}
                label={t('emailsInputLabel')}
              />
              <View style={styles.bottomRow}>
                {needMoreLicensesComponent}
                <PrimaryButton
                  loading={isPending}
                  title={t('invite')}
                  disabled={isPending || emails.length === 0 || !!error}
                  onPress={submitForm}
                  testID="invite-member-btn"
                />
              </View>
            </>
          )}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    marginBottom: 16,
  },
  description: {
    marginBottom: 16,
  },
  highlightedText: {
    color: palette.blue,
    fontSize: 16,
  },
  bottomRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: 20,
  },
  warningInfo: {
    marginBottom: 10,
  },
  buttonWrapper: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
