import * as R from 'ramda';
import React, { ChangeEvent, FocusEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Icon, IconButton, LoadingIndicator, StyledText } from 'src/components';
import { InputBase } from 'src/components/InputBase';
import { BaseSelect as SelectBase } from 'src/components/Select/BaseSelect';
import { types } from 'src/constants';
import { SelectOption } from 'src/constants/types';
import { getSelectOptionsWithoutGroups, isSelectOptionsGroup } from 'src/helpers';
import { useKeyboardPress, useOutsideClickDetector } from 'src/hooks';
import { ifWeb, palette, typography } from 'src/styles';

import { SelectDropdown } from './SelectDropdown';
import { SelectInput, SelectInputRef } from './SelectInput';

type Props = React.ComponentProps<typeof SelectBase>;

function getOptionByValue(options: types.SelectOptions, value?: any | null): types.SelectOption | null {
  if (!value) return null;
  for (const option of options) {
    if (R.equals(option.value, value)) return option;
    if (isSelectOptionsGroup(option)) {
      const maybeOption = getOptionByValue(option.options, value);
      if (maybeOption) return maybeOption;
    }
  }
  return null;
}

export const BaseSelect: React.FC<Props> = ({
  label,
  readOnly,
  clearable,
  error,
  touched,
  options = [],
  placeholder,
  value,
  onChange,
  onBlur,
  onFocus,
  containerStyle,
  valueStyle,
  setDisplayedValue,
  testID,
  searchType,
  selectorStyles,
  maxDropdownHeight,
  inputValue,
  setInputValue,
  isLoading,
  onOpenChange,
  dropdownStyle,
}) => {
  const [isOpen, toggleOpen] = useState(false);
  const [isFocused, toggleFocused] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<SelectInputRef>(null);

  useEffect(() => {
    onOpenChange?.(isOpen);
  }, [isOpen, onOpenChange]);

  const handleChange = useCallback(
    (option: SelectOption) => {
      if (!option || Array.isArray(option)) return;
      onChange?.(option as SelectOption);
      toggleOpen(false);
      setInputValue('');
    },
    [onChange, setInputValue],
  );

  const handleBlur: FocusEventHandler = (e) => {
    setTimeout(() => {
      // @ts-ignore
      if (e.relatedTarget && !wrapperRef.current?.contains(e.relatedTarget)) {
        toggleOpen(false);
        onBlur?.(e);
      }
      toggleFocused(false);
    });
  };

  useEffect(() => {
    if (!isOpen) {
      setInputValue('');
    }
  }, [isOpen, setInputValue]);

  const handleFocus = () => {
    toggleFocused(true);
    onFocus?.();
  };

  const handleNativeElementChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const option = options.find((item) => item.value === value);
    if (option) {
      onChange?.(option);
    }
  };

  const handleWrapperPress = () => {
    if (!readOnly) {
      toggleOpen(true);
      inputRef.current?.focus();
    }
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
    toggleOpen(true);
  };

  useKeyboardPress(
    isFocused &&
      !isOpen && {
        ' ': (_, event) => {
          event.preventDefault();
          toggleOpen((state) => !state);
        },
      },
  );

  useOutsideClickDetector(wrapperRef, () => {
    toggleOpen(false);
  });

  const parseValue = setDisplayedValue || ((value: string) => value);

  const textStyle = [typography.body2, styles.selectorText, readOnly && styles.selectorTextDisabled];
  const shouldShowError = !!error && touched;
  const displayInput = !!searchType && !readOnly;

  return (
    <>
      <div
        tabIndex={searchType ? undefined : 0}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleWrapperPress}
        ref={wrapperRef}
        style={cssStyles.wrapper}
        data-testid="select-container"
      >
        <InputBase
          label={label}
          focused={isFocused}
          readOnly={readOnly}
          error={shouldShowError ? error : undefined}
          inputContainerStyle={containerStyle}
        >
          <View
            style={[styles.selector, readOnly && styles.selectorDisabled, selectorStyles]}
            testID={testID}
          >
            {displayInput && (
              <SelectInput
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                isOptionSelected={!!value}
                ref={inputRef}
              />
            )}
            {!inputValue &&
              (value ? (
                <StyledText style={[textStyle, valueStyle]} singleLine>
                  {parseValue(getOptionByValue(options, value)?.label || '')}
                </StyledText>
              ) : (
                !!placeholder && (
                  <StyledText style={[styles.placeholder, textStyle]} singleLine>
                    {placeholder}
                  </StyledText>
                )
              ))}
            {!!value && clearable && (
              <IconButton
                name="close"
                width={10}
                containerStyle={styles.clearButton}
                onPress={() => onChange?.({ label: '' })}
              />
            )}
            {isLoading ? (
              <LoadingIndicator size="small" />
            ) : (
              <Icon
                name="chevron-down"
                width={10}
                style={[styles.arrow, isOpen && styles.arrowOpen]}
                color={readOnly ? palette.grey5 : palette.blue}
              />
            )}
          </View>
        </InputBase>

        <SelectDropdown
          options={options}
          isOpen={isOpen && !isLoading}
          onChange={handleChange}
          value={value}
          maxDropdownHeight={maxDropdownHeight}
          style={dropdownStyle}
        />
      </div>
      <select
        value={value || undefined}
        onChange={handleNativeElementChange}
        style={cssStyles.selectNative}
      >
        {getSelectOptionsWithoutGroups(options).map((option) => (
          <option value={option.value} key={option.label}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};

const styles = StyleSheet.create({
  arrow: {
    marginLeft: 15,
    ...ifWeb({
      transitionProperty: 'transform',
      pointerEvents: 'none',
    }),
  },
  arrowOpen: {
    transform: [
      {
        rotate: '180deg',
      },
    ],
  },
  clearButton: {
    marginLeft: 15,
    padding: 10,
  },
  selector: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%',
    ...ifWeb({
      cursor: 'pointer',
    }),
  },
  selectorDisabled: {
    ...ifWeb({
      cursor: 'default',
    }),
  },
  placeholder: {
    color: palette.grey5,
  },
  selectorText: {
    flex: 1,
  },
  selectorTextDisabled: {
    color: palette.grey5,
  },
});

const cssStyles: Record<string, React.CSSProperties> = {
  wrapper: {
    outline: 'none',
  },
  selectNative: {
    display: 'none',
  },
};
