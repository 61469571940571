import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { SecondaryButton } from 'src/components';
import { StyledText } from 'src/components/StyledText';
import { useUserInfo } from 'src/features/auth/hooks';
import { closeInactiveAccountModal } from 'src/features/auth/state';
import { ApplicationState } from 'src/state';
import { AppDispatch } from 'src/state/store';
import { palette, typography } from 'src/styles';

import { isExpiredManagedGroup } from '../../helpers';
import { ExpiredAccountModal } from '../modals/ExpiredAccountModal';
import { ExpiredManagedGroupModal } from '../modals/ExpiredManagedGroupModal';

interface Props {
  handleReactivateSubscription(): void;
  hideActionButtons?: boolean;
}

export const InactiveAccountWindow: React.FC<Props> = ({
  handleReactivateSubscription,
  hideActionButtons,
}) => {
  const { shouldDisplayInactiveAccountModal } = useSelector((state: ApplicationState) => state.auth);

  const { t } = useTranslation('subscriptionDetails');
  const userInfo = useUserInfo();
  const dispatch = useDispatch<AppDispatch>();

  // name of the group the user was removed from
  const groupName = userInfo.groupInfo?.groupName;
  const wasRemovedFromGroup = userInfo.groupInfo?.removed && !!groupName;
  const wasMemberOfManagedGroup = isExpiredManagedGroup(userInfo);

  const closeModal = () => {
    dispatch(closeInactiveAccountModal());
  };

  const submitModal = () => {
    closeModal();
    handleReactivateSubscription();
  };

  return (
    <>
      <View style={styles.wrapper} testID="inactive-account-box">
        <StyledText style={[styles.title, typography.body3Bold]} testID="inactive-account-title">
          {t('accountStatus')}
        </StyledText>
        <StyledText style={[styles.subtitle, typography.body3]} testID="inactive-account-status">
          {t('inactive')}
        </StyledText>
        <View style={styles.detailsWrapper}>
          <StyledText style={styles.details} testID="inactive-account-details">
            {t(
              wasMemberOfManagedGroup
                ? 'inactiveSubscriptionExpiredManagedGroup'
                : wasRemovedFromGroup
                ? 'inactiveSubscriptionFormerGroupMemberDetails'
                : 'yourSubscriptionIsInactive',
              { groupName: groupName },
            )}
          </StyledText>
          {!hideActionButtons && (
            <Trans
              i18nKey="subscriptionDetails:inactiveSubscriptionReactivate"
              components={{
                p: <StyledText style={styles.details} testID="inactive-account-reactivate-info" />,
                b: <StyledText style={[styles.details, typography.body2Bold]} />,
              }}
            />
          )}
        </View>
        {!hideActionButtons && (
          <SecondaryButton
            title={t('reactivateSubscription')}
            size="tiny"
            onPress={handleReactivateSubscription}
            testID="button-reactivate-subscription"
          />
        )}
      </View>
      {shouldDisplayInactiveAccountModal &&
        (wasMemberOfManagedGroup ? (
          <ExpiredManagedGroupModal close={closeModal} accept={submitModal} />
        ) : (
          <ExpiredAccountModal
            close={closeModal}
            accept={submitModal}
            groupNameUserWasRemovedFrom={wasRemovedFromGroup ? groupName : undefined}
          />
        ))}
    </>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    paddingVertical: 12,
  },
  title: {
    color: palette.blue,
    marginBottom: 5,
  },
  subtitle: {
    marginBottom: 5,
  },
  detailsWrapper: {
    maxWidth: 280,
    alignItems: 'center',
    marginBottom: 10,
  },
  details: {
    color: palette.grey5,
    textAlign: 'center',
  },
});
