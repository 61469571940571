import { Trans, useTranslation } from 'react-i18next';

import { InfoModal, StyledText } from 'src/components';
import { typography } from 'src/styles';

interface Props {
  /** If passed, it assumes that user was removed from a group */
  groupNameUserWasRemovedFrom?: string;
  close(): void;
  accept(): void;
}

export const ExpiredAccountModal: React.FC<Props> = ({ groupNameUserWasRemovedFrom, close, accept }) => {
  const { t } = useTranslation('subscriptionDetails');

  return (
    <InfoModal
      header={t('yourSubscriptionIsInactive')}
      icon="moon"
      close={close}
      testID="modal-account-inactive"
    >
      <InfoModal.Content>
        {groupNameUserWasRemovedFrom &&
          t('inactiveSubscriptionFormerGroupMemberDetails', { groupName: groupNameUserWasRemovedFrom }) +
            '\n'}
        <Trans
          i18nKey="subscriptionDetails:inactiveSubscriptionReactivate"
          components={{
            p: <StyledText testID="modal-description" />,
            b: <StyledText style={typography.weightBold} />,
          }}
        />
      </InfoModal.Content>
      <InfoModal.Buttons>
        <InfoModal.Button title={t('reactivateSubscription')} onPress={accept} testID="modal-submit-btn" />
      </InfoModal.Buttons>
    </InfoModal>
  );
};
