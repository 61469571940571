import { useQuery } from '@tanstack/react-query';

import { client } from 'src/api/client';
import { pdfRequestConfig } from 'src/helpers/pdf';

export const usePDFdataURL = (pdfUrl: string) => {
  return useQuery({
    queryKey: ['pdf-file-data-url', pdfUrl],
    queryFn: async () => {
      const response = await client.request<string>({
        method: 'GET',
        url: pdfUrl,
        ...pdfRequestConfig,
      });
      return response.data;
    },
  });
};

export const usePDFFile = (pdfUrl: string) => {
  return useQuery({
    queryKey: ['pdf-file-uint8', pdfUrl],
    queryFn: async () => {
      const response = await client.request<ArrayBuffer>({
        url: pdfUrl,
        responseType: 'arraybuffer',
      });
      return new Uint8Array(response.data);
    },
  });
};
