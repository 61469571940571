import { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { InfoModal, StyledText } from 'src/components';
import { CONTACT_SALES_LINK } from 'src/constants/externalLinks';
import { isWeb } from 'src/helpers';
import { i18n } from 'src/locale';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

interface Props {
  accept?(): void;
  close(): void;
}

export const ExpiredManagedGroupModal: React.FC<Props> = ({ accept, close }) => {
  const seeMyOptions = useCallback(() => {
    accept?.();
    close();
  }, [accept, close]);

  return (
    <InfoModal
      icon="moon"
      header={i18n.t('signIn:expiredAccount:yourAccountIsNotActive')}
      close={close}
      testID="modal-account-inactive"
    >
      <InfoModal.Content>
        <Trans
          i18nKey="signIn:expiredAccount:multiUserPlanInfo"
          components={{
            p: <StyledText />,
            sales: <Link style={styles.link} to={CONTACT_SALES_LINK} external wrapper="text" />,
          }}
        />
      </InfoModal.Content>

      {isWeb && (
        <InfoModal.Content small>
          <Trans
            i18nKey="signIn:expiredAccount:needOwnAccountInfo"
            components={{
              p: <StyledText />,
              b: <StyledText style={typography.weightBold} />,
            }}
          />
        </InfoModal.Content>
      )}
      <InfoModal.Buttons>
        {isWeb ? (
          <InfoModal.Button
            title={i18n.t('signIn:expiredAccount:seeMyOptions')}
            onPress={seeMyOptions}
            testID="btn-see-my-options"
          />
        ) : (
          <InfoModal.Button title={i18n.t('okay')} onPress={close} />
        )}
      </InfoModal.Buttons>
    </InfoModal>
  );
};

const styles = StyleSheet.create({
  link: {
    textDecorationLine: 'underline',
    color: palette.blue,
  },
});
