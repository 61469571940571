export enum Route {
  AccountInformation = 'AccountInformation',
  AdminDashboard = 'AdminDashboard',
  Algorithms = 'Algorithms',
  AlgorithmItem = 'AlgorithmItem',
  AppendixItem = 'AppendixItem',
  AppendixList = 'AppendixList',
  Auth = 'Auth',
  Calculator = 'Calculator',
  CanadianDisclaimer = 'CanadianDisclaimer',
  DxTxList = 'DxTxList',
  DxTxItem = 'DxTxItem',
  DxTxMedia = 'DxTxMedia',
  ClinicalHandoutItem = 'ClinicalHandoutItem',
  ClinicalHandoutsList = 'ClinicalHandoutsList',
  DIC = 'DIC',
  DICSearch = 'DICSearch',
  Drawer = 'Drawer',
  Init = 'Init',
  DrugHandoutsList = 'DrugHandoutsList',
  DrugHandoutItem = 'DrugHandoutItem',
  DrugItem = 'DrugItem',
  DrugsList = 'DrugsList',
  EmailChangeLandingScreen = 'EmailChangeConfirmation',
  Features = 'Features',
  FindDrugByName = 'FindDrugByName',
  GroupConversion = 'GroupConversion',
  GroupInvitation = 'GroupInvitation',
  Home = 'Home',
  NoInternetConnection = 'NoInternetConnection',
  Note = 'Note',
  Notes = 'Notes',
  PasswordRecovery = 'PasswordRecovery',
  PrintableHandoutsList = 'PrintableHandoutsList',
  PromotionLanding = 'PromotionLanding',
  ResetPassword = 'ResetPassword',
  Search = 'Search',
  SearchWeb = 'SearchWeb',
  SelectedHandouts = 'SelectedHandouts',
  ShareHandout = 'ShareHandout',
  SignIn = 'SignIn',
  SignUp = 'SignUp',
  StudentReverification = 'StudentReverification',
  SubscriptionDetails = 'SubscriptionDetails',
  SubscriptionInvitation = 'SubscriptionInvitation',
  TabNav = 'TabNav',
  Tools = 'Tools',
}

export const NotFoundRoute = '404' as const;
