import { StyleSheet, View } from 'react-native';

import { CliniciansBriefGreyLogo, PlumbsLogo, VetMeduxGreyLogo } from 'src/assets/images/logos';
import { GradientBar } from 'src/components';
import { CLINICIANS_BRIEFS_SITE_LINK, VETMEDUX_SITE_LINK } from 'src/constants/externalLinks';
import { palette } from 'src/styles';

import { Link } from '../Link';

export const BrandsPanel: React.FC = () => {
  return (
    <View style={styles.wrapper}>
      <GradientBar gradientType="brandsGradient" style={StyleSheet.absoluteFill} />
      <Link to={VETMEDUX_SITE_LINK} external style={styles.logoWrapper}>
        <VetMeduxGreyLogo height={11} />
      </Link>
      <Link to={CLINICIANS_BRIEFS_SITE_LINK} external style={styles.logoWrapper}>
        <CliniciansBriefGreyLogo width={96} height={11} />
      </Link>
      <View style={[styles.logoWrapper, styles.plumbsWrapper]}>
        <PlumbsLogo height={11} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    height: 40,
    flexDirection: 'row',
  },
  logoWrapper: {
    zIndex: 1,
    paddingHorizontal: 24,
    borderRightWidth: 1,
    borderRightColor: palette.grey6,
    justifyContent: 'center',
    height: '100%',
  },
  plumbsWrapper: {
    backgroundColor: palette.white,
    borderRightWidth: 0,
  },
});
