import React, { ComponentProps, CSSProperties } from 'react';
import { StyleSheet, View } from 'react-native';

import { BaseButton, PrimaryButton } from 'src/components';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';

import RecurlyLogo from './Recurly.svg';
import { SubscriptionSection as Section } from '../SubscriptionSections';

export interface Props {
  buttonProps?: ComponentProps<typeof BaseButton>;
}

export const PaymentDetailsFooter: React.FC<Props> = ({ buttonProps }) => {
  const { isTablet } = useDeviceInfo();

  return (
    <Section.ButtonsWrapper style={styles.buttonsWrapper}>
      <Section.Item>
        <View style={!isTablet && styles.recurlyButtonWrapperMobile}>
          <a style={cssStyles.recurlyImg} href="https://recurly.com/" target="_blank" rel="noreferrer">
            <img src={RecurlyLogo} alt="Recurly Logo" />
          </a>
        </View>
      </Section.Item>
      {buttonProps && <PrimaryButton {...buttonProps} />}
    </Section.ButtonsWrapper>
  );
};

const cssStyles: Record<string, CSSProperties> = {
  recurlyImg: { width: 208, height: 52 },
};

const styles = StyleSheet.create({
  recurlyButtonWrapperMobile: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 20,
    marginTop: 20,
  },
  buttonsWrapper: {
    justifyContent: 'space-between',
  },
});
