import { useReducer } from 'react';

const SCALE_STEP = 0.5;
const MAX_SCALE = 5;
const MIN_SCALE = 1;

const reducer = (state: number, action: 'increase' | 'decrease'): number => {
  if (action === 'increase') {
    return state + SCALE_STEP;
  } else {
    return state - SCALE_STEP;
  }
};

export const useScale = () => {
  const [scale, dispatch] = useReducer(reducer, 1);

  const isScaleUpDisabled = scale >= MAX_SCALE;
  const isScaleDownDisabled = scale <= MIN_SCALE;

  const onScaleUpPress = () => {
    if (!isScaleUpDisabled) {
      dispatch('increase');
    }
  };

  const onScaleDownPress = () => {
    if (!isScaleDownDisabled) {
      dispatch('decrease');
    }
  };

  return {
    scale,
    onScaleUpPress,
    onScaleDownPress,
    isScaleDownDisabled,
    isScaleUpDisabled,
  };
};
