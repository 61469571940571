import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { FullScreenTemplate, Container } from 'src/components';
import { MixpanelEvent, type MixpanelFeatureButton, MixpanelService } from 'src/features/tracking';
import { useDeviceInfo, useScreenAccess } from 'src/hooks';
import { Route } from 'src/navigation';
import { ScreenProps, MobileNavMenuItemProps } from 'src/navigation/types';

import { MobileNavMenuItem } from '../components';

interface Props extends ScreenProps<Route.Features> {}

export const FeaturesScreen: React.FC<Props> = () => {
  const { t } = useTranslation('features');
  const { isScreenAccessible } = useScreenAccess();
  const { isTabletApp } = useDeviceInfo();

  const trackMixpanelEvent = (tabName: MixpanelFeatureButton) => {
    MixpanelService.track(MixpanelEvent.FeatureButtonClicked, { 'Feature Name': tabName });
  };

  const items: MobileNavMenuItemProps[] = [
    {
      title: t('dxTx'),
      description: t('clinicalBriefsDescription'),
      route: Route.DxTxList,
      isPro: true,
      titleStyle: styles.title,
      icon: 'virus',
      onPress: () => trackMixpanelEvent('Dx & Tx'),
    },
    {
      title: t('drugs'),
      description: t('drugsDescription'),
      route: Route.DrugsList,
      icon: 'drug-monograph',
      onPress: () => trackMixpanelEvent('Drugs'),
    },
    {
      title: t('dic'),
      description: t('dicDescription'),
      route: Route.DIC,
      icon: 'dic',
      onPress: () => trackMixpanelEvent('Interaction Checker'),
    },
    {
      title: t('algorithms'),
      description: t('algorithmsDescription'),
      route: Route.Algorithms,
      icon: 'algorithm',
      onPress: () => trackMixpanelEvent('Algorithms'),
      isPro: true,
    },
    {
      title: t('clinicalHandouts'),
      description: t('clinicalHandoutsDescription'),
      route: Route.ClinicalHandoutsList,
      icon: 'stethoscope-text',
      onPress: () => trackMixpanelEvent('Clinical Handouts'),
      isPro: true,
    },
    {
      title: t('drugHandouts'),
      description: t('drugHandoutsDescription'),
      route: Route.DrugHandoutsList,
      icon: 'medication-guide',
      onPress: () => trackMixpanelEvent('Drug Handouts'),
    },
    {
      title: t('appendix'),
      description: t('appendixDescription'),
      route: Route.AppendixList,
      icon: 'document-attachment',
      onPress: () => trackMixpanelEvent('Appendix'),
    },
  ];

  return (
    <FullScreenTemplate title={t('header')}>
      <Container verticalPadding={isTabletApp} fullWidthOnMobile>
        {items
          .filter((item) => isScreenAccessible(item.route))
          .map((item) => (
            <MobileNavMenuItem {...item} key={item.title} />
          ))}
      </Container>
    </FullScreenTemplate>
  );
};

const styles = StyleSheet.create({
  title: {
    textTransform: 'none',
  },
});
