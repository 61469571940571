import { StyleSheet, View } from 'react-native';

import { ContentItemDetails } from 'src/constants/types';
import { useUserInfo } from 'src/features/auth/hooks';
import { useFavorites } from 'src/features/favorites';
import {
  ExisitingNoteCardProps,
  MAIN_NOTE_SECTION_ID,
  NewNoteCardProps,
  NoteCard,
  useContentNotes,
} from 'src/features/notes';
import {
  MixpanelEvent,
  MixpanelQuickLinkName,
  MixpanelService,
  getMixpanelFavoritesLocation,
  getMixpanelQuickLinkLocation,
} from 'src/features/tracking';
import { getQuickLinksFromContentData, isContentWithNotes, isDrug } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { CONTAINER_PADDING_H_MOBILE, ifWeb, palette, typography } from 'src/styles';

import { QuickLinks, QuickLink } from './QuickLinks';
import { ContentTypeBadge } from '../../ContentTypeBadge';
import { FeatureButtonProps } from '../../FeatureButton';
import { FeatureButtons } from '../../FeatureButtons';
import { IconButton } from '../../IconButton';
import { MarkdownContent } from '../../MarkdownContent';

interface Props {
  item: ContentItemDetails;
  featureButtons?: FeatureButtonProps[];
  headerDescription?: JSX.Element | null;
  onQuickLinkPress?(linkId: string): void;
}

export const Header: React.FC<Props> = ({ item, featureButtons, headerDescription, onQuickLinkPress }) => {
  const { isTablet } = useDeviceInfo();

  const {
    permissions: { canAccessIndividualAccountFeatures },
  } = useUserInfo();
  const notes = useContentNotes(item.id);
  const { isFavorite, toggleFavorite } = useFavorites({
    id: item.id,
    title: item.title,
    location: getMixpanelFavoritesLocation('content-item'),
  });

  const handleQuickLinkPress = (linkId: string, linkTitle: string) => {
    MixpanelService.track(MixpanelEvent.QuickLinkClicked, {
      'Drug ID': item.id,
      'Drug Title': item.title,
      'Link Name': linkTitle as MixpanelQuickLinkName,
      ...getMixpanelQuickLinkLocation('content-item', isDrug(item) ? 'drug' : 'dx-tx')!,
    });

    onQuickLinkPress?.(linkId);
  };

  const quickLinks: QuickLink[] | undefined = (() => {
    const links = getQuickLinksFromContentData(item);
    return links
      ? links.map((link) => ({
          content: link.title,
          key: link.title,
          onPress: () => handleQuickLinkPress(link.id, link.title),
        }))
      : undefined;
  })();

  const shouldDisplayNoteCard = isContentWithNotes(item) && canAccessIndividualAccountFeatures;
  const shouldDisplayButtonsWrapper =
    !!quickLinks?.length || !!featureButtons?.length || shouldDisplayNoteCard;

  const getNoteCardProps = (): NewNoteCardProps | ExisitingNoteCardProps => {
    const note = notes?.[MAIN_NOTE_SECTION_ID];
    if (note) return { note, type: 'existing' };

    return {
      type: 'new',
      contentData: {
        contentId: item.id,
        contentTitle: item.title,
        contentType: item.contentType,
        sectionId: MAIN_NOTE_SECTION_ID,
        sectionTitle: '',
      },
    };
  };

  return (
    <View style={[!isTablet && styles.wrapperMobile]} testID="content-page-card">
      <View style={styles.badgeContainer}>
        <ContentTypeBadge contentType={item.contentType} statusType={item.status} showBoth />
      </View>
      <View style={styles.titleContainer}>
        <MarkdownContent inline style={styles.title} limited testID="content-card-title">
          {item.title}
        </MarkdownContent>
        {canAccessIndividualAccountFeatures && (
          <IconButton
            name={isFavorite ? 'star' : 'star-outline'}
            width={25}
            onPress={toggleFavorite}
            containerStyle={styles.favoriteIconContainer}
            testID={(isFavorite ? 'remove-from' : 'add-to') + '-favorites-btn'}
          />
        )}
      </View>
      {headerDescription}
      {shouldDisplayButtonsWrapper && (
        <View style={styles.linksContainer}>
          <View style={styles.featureButtonsWrapper}>
            {!!featureButtons?.length && <FeatureButtons buttonItems={featureButtons} />}
            {shouldDisplayNoteCard && <NoteCard style={styles.noteCardWrapper} {...getNoteCardProps()} />}
          </View>
          {quickLinks && (
            <View style={styles.quickLinksWrapper}>
              <QuickLinks quickLinks={quickLinks} />
            </View>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapperMobile: {
    ...ifWeb({
      paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
    }),
  },
  badgeContainer: {
    marginBottom: 18,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  favoriteIconContainer: {
    justifyContent: 'flex-start',
    paddingTop: 1,
  },
  linksContainer: {
    marginTop: 26,
  },
  quickLinksWrapper: {
    marginVertical: 10,
  },
  featureButtonsWrapper: {
    marginVertical: 10,
  },
  noteCardWrapper: {
    marginTop: 10,
  },
  title: {
    flexShrink: 1,
    marginRight: 10,
    color: palette.navy,
    ...typography.heading3,
    ...ifWeb({
      wordBreak: 'break-word',
    }),
  },
});
