import React from 'react';
import { StyleSheet, View } from 'react-native';

import { OVERVIEW_TOC_ITEM_ID } from 'src/constants/content';
import { TableOfContentsProps } from 'src/constants/types';
import { useTableOfContentsItems } from 'src/hooks/useTableOfContentsItems';
import { BOTTOM_BAR_HEIGHT, TOC_DESKTOP_PADDING, TOC_DESKTOP_WIDTH, ifWeb } from 'src/styles';

import { TableOfContentsMenu } from './TableOfContensMenu';

interface Props extends TableOfContentsProps {
  top?: number;
}

export const TableOfContentsDesktop: React.FC<Props> = ({
  item,
  activeSectionId = OVERVIEW_TOC_ITEM_ID,
  onItemPress,
  scrollToTop,
  top = 0,
}) => {
  const items = useTableOfContentsItems({ item, onItemPress, scrollToTop });

  if (!items.length) return null;

  return (
    <View style={[styles.wrapper, { top, maxHeight: `calc(100vh - ${top + BOTTOM_BAR_HEIGHT}px)` }]}>
      <View testID="content-page-toc" style={styles.tocWrapper}>
        <TableOfContentsMenu items={items} active={activeSectionId} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: TOC_DESKTOP_WIDTH,
    paddingRight: TOC_DESKTOP_PADDING,
    paddingLeft: 8,
    position: 'sticky',
    alignSelf: 'flex-start',
  },
  tocWrapper: {
    flex: 1,
    paddingBottom: 20,
    ...ifWeb({
      overflowY: 'auto',
    }),
  },
});
