import { Formik, FormikHelpers, FormikProps } from 'formik';
import React from 'react';
import { StyleSheet } from 'react-native';

import { InputItem, Divider, TextButton, BaseButton } from 'src/components';
import { isWeb } from 'src/helpers';
import { Yup } from 'src/helpers/validation';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';

import type { ResetPasswordFormData } from '../types';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('validation:enterValidEmail'))
    .required(i18n.t('validation:emailRequired')),
});

interface Props {
  onSubmit: (values: ResetPasswordFormData, formikHelpers: FormikHelpers<ResetPasswordFormData>) => void;
  email?: string;
}

interface FormProps extends FormikProps<ResetPasswordFormData> {
  handleSubmit: () => void;
}

export const ResetPasswordForm: React.FC<Props> = ({ onSubmit, email }) => {
  const { isTablet } = useDeviceInfo();

  const initialValues = {
    email: email || '',
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(props: FormProps) => {
        const { handleChange, handleBlur, values, handleSubmit, errors, touched, isSubmitting } = props;
        return (
          <>
            <InputItem
              onChangeText={handleChange('email')}
              onBlur={handleBlur('email')}
              value={values.email}
              error={errors.email}
              touched={touched.email}
              label={i18n.t('common:email')}
              inputMode="email"
              textContentType="emailAddress"
              blurOnSubmit
              onSubmitEditing={isWeb ? handleSubmit : undefined}
              placeholder={i18n.t('common:enterEmail')}
              testID="forgot-password-email-input"
              autoCompleteType="email"
            />
            <BaseButton
              onPress={handleSubmit}
              variant="gradient"
              title={i18n.t('resetPassword:resetPasswordButtonLabel')}
              containerStyle={styles.button}
              fullWidth={!isTablet}
              testID="forgot-password-submit-btn"
              loading={isSubmitting}
            />
            {isWeb && (
              <>
                <Divider style={styles.dividerMargin} />
                <TextButton
                  to={{ route: Route.SignIn }}
                  wrapperStyle={styles.textLinkWrapper}
                  testID="forgot-password-back-to-login-btn"
                >
                  {i18n.t('resetPassword:backToLogin')}
                </TextButton>
              </>
            )}
          </>
        );
      }}
    </Formik>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 26,
    alignSelf: 'flex-start',
  },
  dividerMargin: {
    marginBottom: 0,
  },
  textLinkWrapper: {
    marginTop: 24,
    alignSelf: 'flex-start',
  },
});
