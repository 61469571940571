import { StyleSheet } from 'react-native';

import { DropdownWithHeader } from 'src/components';
import { ContentType, DropdownItem } from 'src/constants/types';
import { useUserInfo } from 'src/features/auth/hooks';
import { isWeb } from 'src/helpers';
import { i18n } from 'src/locale';
import { ifWeb, palette } from 'src/styles';

import { FavoritesFilter } from '../types';

interface Props {
  filter: FavoritesFilter;
  setFilter(filter: FavoritesFilter): void;
  disabled?: boolean;
}

interface Item extends DropdownItem<FavoritesFilter> {
  isHidden?: boolean;
}

export const FavoritesFilterDropdown: React.FC<Props> = ({ filter, setFilter, disabled }) => {
  const {
    permissions: {
      hasFullAccessToAlgorithms,
      hasFullAccessToBasicContentTypes,
      hasFullAccessToClinicalBriefs,
      hasFullAccessToClinicalHandouts,
    },
  } = useUserInfo();

  const items: Item[] = [
    {
      value: 'all',
      label: i18n.t('favorites:all'),
      testID: 'filter-all',
    },
    {
      value: `${ContentType.DxTxSignSituation},${ContentType.DxTxMisc},${ContentType.DxTxCondition}`,
      label: i18n.t('favorites:dxTx'),
      listLabel: i18n.t('favorites:clinicalBriefsDropdown'),
      isPro: true,
      isHidden: !hasFullAccessToClinicalBriefs,
      titleStyle: styles.dxTxTitle,
      testID: 'filter-dx-tx',
    },
    {
      value: ContentType.Drug,
      label: i18n.t('favorites:drugs'),
      isHidden: !hasFullAccessToBasicContentTypes,
      testID: 'filter-monograph',
    },
    {
      value: ContentType.Algorithm,
      label: i18n.t('favorites:algorithms'),
      isPro: true,
      isHidden: !hasFullAccessToAlgorithms,
      testID: 'filter-algorithm',
    },
    {
      value: `${ContentType.HandoutProcedure},${ContentType.HandoutClinicalConditions},${ContentType.HandoutVaccine},${ContentType.HandoutMisc}`,
      label: i18n.t('favorites:clinicalHandouts'),
      isPro: true,
      isHidden: !hasFullAccessToClinicalHandouts,
      testID: 'filter-clinical-handout',
    },
    {
      value: `${ContentType.VeterinaryMedicationGuide},${ContentType.DrugHandout}`,
      label: i18n.t('favorites:drugHandouts'),
      testID: 'filter-drug-handout',
    },
    {
      value: ContentType.Appendix,
      label: i18n.t('favorites:appendix'),
      isHidden: !hasFullAccessToBasicContentTypes,
      testID: 'filter-appendix',
    },
  ];

  const handleFilterChange = (item: DropdownItem) => {
    setFilter(item.value!);
  };

  return (
    <DropdownWithHeader
      items={items.filter((item) => !item.isHidden)}
      value={filter}
      onChange={handleFilterChange}
      disabled={disabled}
      getTitle={(item) => (!item || disabled ? i18n.t('favorites:favorites') : item.label)}
      getTitleStyle={(item) => [item?.titleStyle, styles.title]}
      dropdownStyle={styles.dropdown}
      arrowColor={isWeb ? palette.white : undefined}
    />
  );
};

const styles = StyleSheet.create({
  dxTxTitle: {
    textTransform: 'none',
  },
  title: {
    ...ifWeb({
      color: palette.white,
    }),
  },
  dropdown: {
    ...ifWeb({
      width: 275,
    }),
  },
});
