import React from 'react';
import { View, StyleSheet } from 'react-native';

import { Icon } from 'src/components';
import { IconName } from 'src/constants/types';
import { palette } from 'src/styles';

const cards = ['masterCard', 'american', 'discover', 'visa'];
type Cards = IconName[];

export const CardsSection: React.FC<{ error: boolean }> = ({ error }) => {
  return (
    <View style={styles.wrapper}>
      {(cards as Cards).map((card) => (
        <Icon
          name={card}
          color={!error ? palette.blue : palette.red}
          key={card}
          style={styles.marginLeft}
          width={30}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: { flexDirection: 'row', height: 20 },
  marginLeft: {
    marginLeft: 8,
  },
});
