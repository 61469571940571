import { isEmpty } from 'lodash-es';
import * as R from 'ramda';
import { useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import { linkingConfig, orderedWebRoutes } from 'src/navigation/config/linkingConfig';
import { NotFoundRoute } from 'src/navigation/routes';
import { LinkedRoute } from 'src/navigation/types';

const findRoute = (pathname: string) => {
  for (const name of orderedWebRoutes) {
    const config = linkingConfig[name];
    const match = matchPath(pathname, { path: config.path, exact: config.exact });
    if (match) {
      return {
        name,
        path: match.path,
        pathParams: match.params,
      };
    }
  }

  return {
    name: NotFoundRoute,
    path: pathname,
    pathParams: {},
  };
};

const parseParams = (routeName: LinkedRoute | typeof NotFoundRoute, params: Record<string, string>) => {
  const parseConfig = linkingConfig[routeName]?.parse;

  if (parseConfig) {
    return R.mapObjIndexed<string, string>((value, key) =>
      key in parseConfig ? parseConfig[key](value) : value,
    )(params);
  }

  return isEmpty(params) ? undefined : params;
};

export const useRoute = () => {
  const location = useLocation();

  const { name, path, pathParams } = useMemo(() => findRoute(location.pathname), [location.pathname]);

  const queryParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    [location.search],
  );

  const params = useMemo(
    () => parseParams(name, { ...pathParams, ...queryParams }),
    [name, pathParams, queryParams],
  );

  return useMemo(
    () => ({
      key: '',
      name,
      params,
      path,
    }),
    [name, params, path],
  );
};
