import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { StyledText, SecondaryButton, Subheader } from 'src/components';
import { palette, typography } from 'src/styles';

interface Props {
  title: string;
  data: Record<string, string>;
  scrollToStep?: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  testID?: string;
}

export const StepSummary: React.FC<Props> = ({ title, data, scrollToStep, containerStyle, testID }) => {
  return (
    <View style={[styles.wrapper, containerStyle]} testID={testID}>
      <View style={styles.header}>
        <Subheader title={title} wrapperStyle={styles.titleWrapper} />
        {!!scrollToStep && (
          <SecondaryButton size="tiny" title="Edit" onPress={scrollToStep} testID="edit-btn" />
        )}
      </View>
      {Object.entries(data).map(([key, value], index) => (
        <View key={key} style={index !== 0 && styles.entrySpacing} testID={testID + '-' + key}>
          <StyledText style={[typography.body1Short, styles.entryTitle]}>{key}</StyledText>
          <StyledText style={typography.body2SemiBold}>{value}</StyledText>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    borderWidth: 1,
    borderColor: palette.grey2,
    padding: 20,
  },
  titleWrapper: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  entrySpacing: {
    marginTop: 12,
  },
  entryTitle: {
    marginBottom: 4,
  },
});
