import { useSelector } from 'react-redux';

import { Route, RouteProp } from 'src/navigation';
import { useRoute } from 'src/navigation/hooks/useRoute';

import { useHandoutItem } from './queries';
import { handoutsSelector } from '../state';

export const useHandoutsToShare = () => {
  const { params } = useRoute<RouteProp<Route.ShareHandout>>();
  const singleHandoutId = params?.handout;

  const { selectedHandouts } = useSelector(handoutsSelector);
  const { data: singleHandoutData, error, isLoading, fetchStatus } = useHandoutItem(singleHandoutId);

  return {
    isLoading,
    fetchStatus,
    error,
    handouts: singleHandoutId ? (singleHandoutData ? [singleHandoutData] : []) : selectedHandouts,
    singleHandoutMode: !!singleHandoutId,
  };
};
