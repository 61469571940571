import React from 'react';
import { StyleProp, StyleSheet, TextStyle, View } from 'react-native';

import { PrimaryButton, Icon, Pressable } from 'src/components';
import { IconName } from 'src/constants/types';
import { isWeb } from 'src/helpers';
import { i18n } from 'src/locale';
import { palette } from 'src/styles';

export type FormatState = {
  bold?: boolean;
  underline?: boolean;
  italic?: boolean;
  script?: 'super' | 'sub' | false;
};

export type SetFormatHandler = <T extends keyof FormatState>(formatType: T, value: FormatState[T]) => void;

interface ToolbarProps {
  handleBlur?(): void;
  formats: FormatState;
  setFormat: SetFormatHandler;
}

export const EditorToolbar: React.FC<ToolbarProps> = ({ handleBlur, formats, setFormat }) => {
  const sub = formats.script === 'sub';
  const sup = formats.script === 'super';

  const formatButtons: FormatButtonProps[] = [
    {
      icon: 'bold',
      selected: formats.bold,
      onPress: () => setFormat('bold', !formats.bold),
    },
    {
      icon: 'italic',
      style: styles.italic,
      selected: formats.italic,
      onPress: () => setFormat('italic', !formats.italic),
    },
    {
      icon: 'underline',
      style: styles.underline,
      selected: formats.underline,
      size: 16,
      onPress: () => setFormat('underline', !formats.underline),
    },
    {
      icon: 'subscript',
      style: styles.subscript,
      selected: sub,
      size: 16,
      onPress: () => setFormat('script', sub ? false : 'sub'),
    },
    {
      icon: 'superscript',
      style: styles.superscript,
      selected: sup,
      size: 16,
      onPress: () => setFormat('script', sup ? false : 'super'),
    },
  ];

  const renderFormatButton = ({ icon, style, selected, size = 14, onPress }: FormatButtonProps) => (
    <Pressable onPress={onPress} key={icon}>
      {(hovered, pressed) => (
        <View style={[styles.formatButton, pressed && styles.formatButtonPressed]}>
          <Icon
            name={icon}
            color={selected || hovered ? palette.blue : palette.grey8}
            width={size}
            style={style}
          />
        </View>
      )}
    </Pressable>
  );

  return (
    <View style={styles.toolbar}>
      <View style={styles.formats}>{formatButtons.map(renderFormatButton)}</View>
      {!isWeb && handleBlur && (
        <PrimaryButton
          onPress={handleBlur}
          height={28}
          containerStyle={buttonStyles.container}
          innerContainerStyle={buttonStyles.innerContainer}
          titleStyle={buttonStyles.title}
          title={i18n.t('done')}
          size="tiny"
        />
      )}
    </View>
  );
};

interface LabelProps {
  icon: IconName;
  style?: StyleProp<TextStyle>;
  selected?: boolean;
  size?: number;
}

interface FormatButtonProps extends LabelProps {
  onPress(): void;
}

const buttonStyles = StyleSheet.create({
  container: {
    backgroundColor: palette.transparent,
    alignSelf: 'center',
  },
  innerContainer: {
    borderRadius: 25,
    paddingHorizontal: 12,
  },
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});

const styles = StyleSheet.create({
  toolbar: {
    height: 42,
    paddingLeft: 4,
    paddingRight: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: palette.grey1,
  },
  formats: {
    flexDirection: 'row',
  },
  formatButton: {
    width: 32,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formatButtonPressed: {
    backgroundColor: palette.grey2,
  },
  subscript: {
    top: 2,
  },
  superscript: {
    top: -1,
  },
  italic: {
    fontStyle: 'italic',
  },
  underline: {
    top: 2,
  },
});
