import React from 'react';
import { StyleSheet, View } from 'react-native';

import { isWeb } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { fonts, palette, typography } from 'src/styles';

import { Header as HeaderBase } from './Header';
import { StyledText } from './StyledText';

type Props = React.ComponentProps<typeof HeaderBase>;

export const Header: React.FC<Props> = ({ title, subtitle }) => {
  const { isTablet, isDesktop } = useDeviceInfo();

  return (
    <View
      style={[
        styles.container,
        isTablet && styles.containerTablet,
        isDesktop && isWeb && styles.containerDesktop,
      ]}
      testID="page-header"
    >
      <View style={styles.titleWrapper}>
        <StyledText style={[styles.title, isTablet && styles.titleTablet]}>{title}</StyledText>
        {!!subtitle && isWeb && (
          <StyledText style={[styles.subtitle, typography.body2, isTablet && styles.subtitleTablet]}>
            {subtitle}
          </StyledText>
        )}
      </View>
    </View>
  );
};

export default Header;
const styles = StyleSheet.create({
  container: {
    backgroundColor: palette.white,
    alignItems: 'center',
    paddingHorizontal: 12,
    height: 'auto',
    paddingVertical: 28,
  },
  containerTablet: {
    paddingVertical: 48,
  },
  containerDesktop: {
    paddingVertical: 60,
  },
  titleWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    color: palette.navy,
    flex: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: fonts.din,
    fontWeight: '700',
    lineHeight: 56,
    fontSize: 40,
  },
  titleTablet: {
    fontSize: 52,
    lineHeight: 60,
  },
  subtitle: {
    color: palette.navy,
    textAlign: 'center',
    marginTop: 5,
    maxWidth: 1060,
  },
  subtitleTablet: {
    marginTop: 10,
  },
});
