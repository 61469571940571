import { useIsFetching } from '@tanstack/react-query';
import { ComponentProps, PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { BoxWithHeader } from 'src/components/BoxWithHeader';
import { LoadingIndicator } from 'src/components/LoadingIndicator';
import { StyledText } from 'src/components/StyledText';
import { SUPPORT_LINK } from 'src/constants/externalLinks';
import { QueryErrorBanner } from 'src/errorHandling/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

import { AccountStatusBox } from './AccountStatusBox';
import { BillingCycleBox } from './BillingCycleBox';
import { InactiveAccountWindow } from './InactiveAccountWindow';
import { PlanDetailsBox } from './PlanDetailsBox';
import { PlanTypeBox } from './PlanTypeBox';
import { SubscriptionTermBox } from './SubscriptionTermBox';
import { UsersBox } from './UsersBox';
import { PROMOTION_QUERY_KEY } from '../../constants';
import { useSubscriptionInfo } from '../../hooks';

interface Props extends PropsWithChildren {
  handleAddMoreUsers(): void;
  handleUpgradeToPro(): void;
  handleReactivateSubscription(): void;
  hideActionButtons?: boolean;
}

export const PlanDetails: React.FC<Props> = ({
  children,
  handleAddMoreUsers,
  handleUpgradeToPro,
  handleReactivateSubscription,
  hideActionButtons,
}) => {
  const { t } = useTranslation('subscriptionDetails');
  const { hasRecurlyAccount } = useUserInfo();
  const { isLargeDesktop } = useDeviceInfo();
  const { error, data: subscriptionData, fetchStatus } = useSubscriptionInfo();
  const isPromotionLoading = useIsFetching({ queryKey: [PROMOTION_QUERY_KEY] });

  const commonBoxProps: Partial<ComponentProps<typeof PlanDetailsBox>> = {
    inColumn: !isLargeDesktop,
  };

  return (
    <BoxWithHeader
      title={t('planDetails')}
      subtitleContent={
        !!subscriptionData &&
        !subscriptionData.isExpired && (
          <Trans
            i18nKey="subscriptionDetails:planDetailsSubtitle"
            components={{
              p: <StyledText style={typography.body2} />,
              support: <Link to={SUPPORT_LINK} wrapper="text" external style={styles.highlightedText} />,
            }}
          />
        )
      }
      testID="box-plan-details"
    >
      <QueryErrorBanner
        error={error}
        fetchStatus={fetchStatus}
        isDataAvailable={!!subscriptionData}
        errorComponentType="message"
      />
      {subscriptionData?.isExpired || !hasRecurlyAccount ? (
        <InactiveAccountWindow
          handleReactivateSubscription={handleReactivateSubscription}
          hideActionButtons={hideActionButtons}
        />
      ) : fetchStatus === 'fetching' || isPromotionLoading ? (
        <LoadingIndicator style={styles.spinner} displayStandbyText />
      ) : subscriptionData ? (
        <View style={[styles.boxesWrapper, isLargeDesktop && styles.boxesWrapperDesktop]}>
          <PlanTypeBox
            boxProps={{ isFirst: true, ...commonBoxProps }}
            handleUpgradeToPro={handleUpgradeToPro}
            hideActionButtons={hideActionButtons}
          />
          <BillingCycleBox boxProps={commonBoxProps} />
          <UsersBox
            boxProps={commonBoxProps}
            handleAddMoreUsers={handleAddMoreUsers}
            hideActionButtons={hideActionButtons}
          />
          <SubscriptionTermBox boxProps={commonBoxProps} />
          <AccountStatusBox boxProps={{ ...commonBoxProps, isLast: true }} />
        </View>
      ) : null}
      {children}
    </BoxWithHeader>
  );
};

const styles = StyleSheet.create({
  highlightedText: {
    color: palette.blue,
  },
  boxesWrapper: {
    flexDirection: 'column',
    marginTop: -20,
  },
  boxesWrapperDesktop: {
    flexDirection: 'row',
    marginTop: 0,
  },
  spinner: {
    height: 100,
    justifyContent: 'center',
  },
});
