import React, { useState, useCallback, useRef } from 'react';
import { StyleSheet, View } from 'react-native';

import { FullScreenTemplate, Container, ConfirmationScreen } from 'src/components';
import { SteppedForm } from 'src/components/SteppedForm';
import { isObjectNotEmpty, Nullable, showNotification } from 'src/helpers';
import { i18n } from 'src/locale';
import { ifWeb } from 'src/styles';

import { getErrorMessagesForSubscriptionForm } from '../../helpers';
import { useRegisterAsGroupMember } from '../../hooks';
import { RegisterData } from '../../types';
import { CompleteYourProfile, CompleteYourProfileRef } from '../CompleteYourProfile';
import { CreateAnAccount, CreateAnAccountRef } from '../CreateAnAccount';
import { TermsAndConditionsCheck } from '../TermsAndConditionsCheck';

interface Props {
  email: string;
  memberId: string;
}

const initialState: Nullable<RegisterData> = {
  createAccount: null,
  completeYourProfile: null,
};

enum SectionIndex {
  Account = 0,
  Profile = 1,
}

export const GroupInvitationRegisterForm: React.FC<Props> = ({ email, memberId }) => {
  const [registerData, setRegisterData] = useState<Nullable<RegisterData>>(initialState);
  const [termsAccepted, setTerms] = useState(false);
  const accountInitialData = {
    email,
    confirmEmail: email,
    password: '',
    confirmPassword: '',
  };

  const {
    mutate: registerAsGroupMember,
    isPending: isSubmitting,
    isSuccess: isFinished,
  } = useRegisterAsGroupMember();

  const createAnAccountRef = useRef<CreateAnAccountRef>(null);
  const completeYourProfileRef = useRef<CompleteYourProfileRef>(null);

  const submitStep = useCallback((sectionData: Partial<RegisterData>) => {
    setRegisterData((prev) => ({ ...prev, ...sectionData }));
  }, []);

  const [focusedStep, setFocusedStep] = useState<number | undefined>();

  const register = useCallback(
    async (sectionData: Partial<RegisterData>) => {
      registerAsGroupMember(
        { data: { ...registerData, ...sectionData } as RegisterData, membershipId: memberId },
        {
          onError: (error: any) => {
            const errors = error?.response?.data;

            if (!errors) {
              showNotification({ type: 'error' });
              return;
            }

            const { accountErrors, demographicsErrors, otherErrors, profileErrors } =
              getErrorMessagesForSubscriptionForm(errors);

            createAnAccountRef.current?.setErrors(accountErrors);
            completeYourProfileRef.current?.setInformationErrors(profileErrors);
            completeYourProfileRef.current?.setOccupationErrors(demographicsErrors);

            if (isObjectNotEmpty(accountErrors)) {
              setFocusedStep(SectionIndex.Account);
            } else if (isObjectNotEmpty(profileErrors) || isObjectNotEmpty(demographicsErrors)) {
              setFocusedStep(SectionIndex.Profile);
            } else if (isObjectNotEmpty(otherErrors)) {
              const messages = Object.values(otherErrors).join('\n');
              showNotification({ type: 'error', autoHide: false, title: messages || '' });
            } else {
              showNotification({ type: 'error', autoHide: false });
            }
          },
        },
      );
    },
    [registerAsGroupMember, registerData, memberId, setFocusedStep],
  );

  if (isFinished) {
    return (
      <ConfirmationScreen
        icon="checkmark"
        title={i18n.t('groupInvitation:thankYouForRegistering')}
        body={i18n.t('groupInvitation:registrationConfirmed')}
        email={email}
      />
    );
  }

  return (
    <FullScreenTemplate title={i18n.t('screens:groupInvitationRegister')}>
      <Container verticalPadding>
        <SteppedForm
          stepStyle={styles.zIndexUnset}
          submitCallback={submitStep}
          focusedStep={focusedStep}
          setFocusedStep={setFocusedStep}
        >
          {(submit, stepNo) => (
            <CreateAnAccount
              stepNo={stepNo}
              data={accountInitialData}
              isSubmitted={false}
              submit={submit}
              active
              groupInvitationForm
              ref={createAnAccountRef}
            />
          )}
          {(_, stepNo) => (
            <CompleteYourProfile
              stepNo={stepNo}
              data={null}
              isSubmitted={false}
              submit={register}
              submitLabel={i18n.t('register')}
              submitLoading={isSubmitting}
              active={!!registerData.createAccount}
              ref={completeYourProfileRef}
              submitDisabled={!termsAccepted}
              additionalContent={
                <View style={styles.termsWrapper}>
                  <TermsAndConditionsCheck isChecked={termsAccepted} onChange={setTerms} />
                </View>
              }
            />
          )}
        </SteppedForm>
      </Container>
    </FullScreenTemplate>
  );
};

const styles = StyleSheet.create({
  zIndexUnset: {
    ...ifWeb({
      zIndex: 'auto',
    }),
  },
  termsWrapper: {
    marginTop: 20,
  },
});
