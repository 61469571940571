import { ComponentProps } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { SecondaryButton } from 'src/components';
import { StyledText } from 'src/components/StyledText';
import { SUPPORT_LINK } from 'src/constants/externalLinks';
import { Link } from 'src/navigation/components';
import { palette } from 'src/styles';

import { PlanDetailsBox } from './PlanDetailsBox';
import { useSubscriptionInfo } from '../../hooks';

interface Props {
  boxProps?: Partial<ComponentProps<typeof PlanDetailsBox>>;
  handleAddMoreUsers(): void;
  hideActionButtons?: boolean;
}

export const UsersBox: React.FC<Props> = ({ boxProps, handleAddMoreUsers, hideActionButtons }) => {
  const { t } = useTranslation('subscriptionDetails');
  const { data } = useSubscriptionInfo();

  const displayUsersReductionInfo = data?.canManageLicencesNumber && data!.numberOfLicences > 1;

  return (
    <PlanDetailsBox
      title={t('users')}
      subtitle={data?.numberOfLicences.toString()}
      {...boxProps}
      testID="mini-box-users"
    >
      <>
        {data?.canAddMoreLicences && !hideActionButtons && (
          <SecondaryButton
            size="tiny"
            title={t('addMoreUsers')}
            containerStyle={styles.button}
            onPress={handleAddMoreUsers}
            testID="button-add-more-users"
          />
        )}
        {displayUsersReductionInfo && (
          <Trans
            i18nKey="subscriptionDetails:usersReductionInfo"
            components={{
              p: <StyledText style={styles.details} testID="reduce-users-info" />,
              support: <Link to={SUPPORT_LINK} external style={styles.highlightedText} wrapper="text" />,
            }}
          />
        )}
      </>
    </PlanDetailsBox>
  );
};

const styles = StyleSheet.create({
  highlightedText: {
    color: palette.blue,
  },
  details: {
    color: palette.grey5,
    lineHeight: 20,
  },
  button: {
    marginBottom: 5,
  },
});
