import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { BaseButton, MarkdownContent, StyledText, Subheader } from 'src/components';
import { TRIAL_DURATION_IN_DAYS } from 'src/constants/constants';
import { TilesSelectorOption } from 'src/constants/types';
import { formatDateWithMonths } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { palette, typography } from 'src/styles';

import { SelectorsSection } from './SelectorsSection';
import { usePurchasePreview } from '../../hooks';
import { PlanSetup, SubscriptionBaseProps, BillingPeriod, Plan } from '../../types';
import { PurchasePreview } from '../PurchasePreview';
import { SubscriptionSection as Section } from '../SubscriptionSections';

export interface Props extends SubscriptionBaseProps {
  data: PlanSetup;
  email: string;
}

const billingPlanOptions: TilesSelectorOption<BillingPeriod>[] = [
  {
    label: i18n.t('subscriptionProcess:annual'),
    value: BillingPeriod.ANNUAL,
    badge: i18n.t('subscriptionProcess:bestValue'),
    testID: 'trial-annual-billing',
  },
  {
    label: i18n.t('subscriptionProcess:monthly'),
    value: BillingPeriod.MONTHLY,
    testID: 'trial-monthly-billing',
  },
];

export const ReviewPlanDetailsTrial: React.FC<Props> = ({
  submit,
  onDirtyChange,
  stepNo,
  isSubmitted,
  active,
  data,
  email,
}) => {
  const { t } = useTranslation('subscriptionProcess');
  const { isTablet } = useDeviceInfo();

  const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>(
    data?.billingPeriod || BillingPeriod.ANNUAL,
  );

  const isPro = data.plan === Plan.PRO;

  const {
    purchasePreviewComponentProps,
    isLoading: isPurchasePreviewLoading,
    purchasePreview,
  } = usePurchasePreview({
    billingPeriod,
    email,
    plan: data.plan,
    isTrial: true,
    quantity: data.quantity,
    role: data.role,
  });

  const handleSubmit = () => {
    submit({
      planSetup: {
        ...data,
        billingPeriod,
      },
      purchasePreview,
    });
  };

  const dirty = useMemo(() => data.billingPeriod !== billingPeriod, [data.billingPeriod, billingPeriod]);
  const isSubmitButtonDisabled = isPurchasePreviewLoading || (isSubmitted && !dirty);

  useEffect(() => {
    onDirtyChange?.(dirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty]);

  const columnStyles = [styles.column, isTablet && styles.columnTablet];

  const start = formatDateWithMonths(dayjs());
  const end = formatDateWithMonths(dayjs().add(TRIAL_DURATION_IN_DAYS, 'day'));

  return (
    <Section
      title={i18n.t('subscriptionProcess:reviewPlanDetails')}
      active={active}
      checked={isSubmitted}
      stepNo={stepNo}
      testID="trial-review-plan-details-section"
    >
      <View style={styles.wrapper}>
        <View style={columnStyles} testID="trial-info-details">
          <Subheader title={t(isPro ? 'trialProPlanDetailsHeader' : 'trialPlanDetailsHeader')} />
          <StyledText style={[styles.text, typography.body3]}>
            {t(isPro ? 'trialProDetails' : 'trialDetails')}
          </StyledText>
          <StyledText style={[styles.text, typography.body3]}>
            {t(isPro ? 'trialProListHeader' : 'trialListHeader')}
          </StyledText>
          <MarkdownContent style={typography.body3}>
            {t(isPro ? 'trialProList' : 'trialList')}
          </MarkdownContent>
        </View>
        <View style={columnStyles}>
          {isPro && (
            <>
              <Subheader title={t('trialBillingCycleHeader')} />
              <StyledText style={[styles.text, typography.body3]}>
                {t('trialBillingCycleDetails')}
              </StyledText>
              <SelectorsSection
                selected={billingPeriod}
                options={billingPlanOptions}
                onPress={setBillingPeriod}
              />
            </>
          )}
          <PurchasePreview {...purchasePreviewComponentProps} taxNotCalculated />
        </View>
      </View>
      <Section.ButtonsWrapper>
        <BaseButton
          title={i18n.t(isSubmitted ? 'saveChanges' : 'next')}
          onPress={handleSubmit}
          disabled={isSubmitButtonDisabled}
          testID="trial-plan-details-next-btn"
          variant="gradient"
        />
      </Section.ButtonsWrapper>
      <Section.Footer>
        <StyledText style={styles.footerText} testID="trial-subscription-period">
          <Trans
            i18nKey="subscriptionProcess:trialFooter"
            components={{
              highlight1: <StyledText style={styles.textHighlighted} testID="trial-start-date" />,
              highlight2: <StyledText style={styles.textHighlighted} testID="trial-end-date" />,
              highlight3: <StyledText style={styles.textHighlighted} />,
            }}
            values={{ start, end }}
          />
        </StyledText>
      </Section.Footer>
    </Section>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  column: {
    width: '100%',
    marginBottom: 20,
  },
  columnTablet: {
    width: '48%',
    marginBottom: 0,
  },
  text: {
    marginBottom: 16,
  },
  footerText: {
    textAlign: 'center',
  },
  textHighlighted: {
    color: palette.blue,
  },
});
