import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { StyledText } from 'src/components/StyledText';
import { ifWeb, palette, typography } from 'src/styles';

import { InputMessage } from './InputMessage';

export interface Props {
  label?: string;
  error?: string | boolean;
  info?: string;
  focused?: boolean;
  readOnly?: boolean;
  description?: string;
  containerStyle?: StyleProp<ViewStyle>;
  inputContainerStyle?: StyleProp<ViewStyle>;
  AdditionalLabelComponent?: React.ComponentType<{ error: boolean }>;
}

export const InputBase: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  inputContainerStyle,
  containerStyle,
  description,
  focused,
  error,
  info,
  readOnly,
  children,
  AdditionalLabelComponent,
}) => {
  return (
    <View style={[styles.container, containerStyle]} testID="input-container">
      {!!label && (
        <View style={styles.labelContainer}>
          <StyledText style={[styles.label, !!error && styles.errorText]}>{label}</StyledText>
          {AdditionalLabelComponent && <AdditionalLabelComponent error={!!error} />}
        </View>
      )}
      <View
        style={[
          styles.inputContainer,
          inputContainerStyle,
          readOnly && styles.inputContainerDisabled,
          !readOnly && focused && styles.inputContainerFocus,
          !!error && styles.inputContainerError,
          !!info && styles.inputContainerInfo,
        ]}
      >
        {children}
      </View>

      {error && typeof error === 'string' ? (
        <InputMessage type="error" message={error} />
      ) : info ? (
        <InputMessage type="info" message={info} />
      ) : description ? (
        <InputMessage type="info" message={description} />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    ...ifWeb({
      zIndex: 'auto',
    }),
  },
  labelContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  label: {
    color: palette.navy,
    ...typography.weightSemiBold,
    ...typography.text2,
    lineHeight: 20,
  },
  errorText: {
    color: palette.red,
  },
  inputContainer: {
    backgroundColor: palette.white,
    height: 52,
    flexDirection: 'row',
    borderColor: palette.grey2,
    borderRadius: 5,
    overflow: 'hidden',
    // we need this specific border width for android
    borderWidth: 1.1,
    ...ifWeb({
      zIndex: 'auto',
      transitionProperty: 'border-color',
    }),
  },
  inputContainerDisabled: {
    backgroundColor: palette.grey1,
  },
  inputContainerFocus: {
    borderColor: palette.blue,
  },
  inputContainerError: {
    borderColor: palette.red,
  },
  inputContainerInfo: {
    borderColor: palette.blue,
  },
});
