import { equals, isEmpty, mapObjIndexed } from 'ramda';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { BaseButton, LoadingIndicator, PrimaryButton, SecondaryButton } from 'src/components';
import { Divider } from 'src/components/Divider';
import { StyledText } from 'src/components/StyledText';
import { Switch } from 'src/components/Switch';
import { PRIVACY_POLICY_LINK, SUPPORT_LINK, TERMS_AND_CONDITIONS_LINK } from 'src/constants/externalLinks';
import { getErrorMessageForApiError } from 'src/errorHandling/utils';
import { useUserInfo } from 'src/features/auth/hooks';
import { StudentVerification } from 'src/features/studentVerification/components/StudentVerification';
import {
  StudentVerificationProvider,
  useStudentVerificationContext,
} from 'src/features/studentVerification/context/StudentVerificationContext';
import { showNotification } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { Link } from 'src/navigation/components';
import { useRoute } from 'src/navigation/hooks';
import { RouteProp } from 'src/navigation/types';
import { NAVBAR_HEIGHT, palette, typography } from 'src/styles';

import { PaymentDetailsSection } from './PaymentDetailsSection';
import { PlanDetailSection } from './PlanDetailSection';
import { PlansComparison } from './PlansComparison';
import { billingPlanSelectorOptions, planSelectorOptions, roleSelectorOptions } from '../../constants';
import {
  doesProPromotionAllowDifferentBillingPeriods,
  getRecurlyPlan,
  hasRoleNormalAndProVariants,
  promotionAllowsPlanSelection,
} from '../../helpers';
import {
  usePromotion,
  usePlanDetailsForm,
  useBillingInformation,
  useApplyPromotion,
  useSubscriptionInfo,
  usePurchaseSubscriptionAsExistingUser,
  useChangePlan,
} from '../../hooks';
import { Plan, PlanSetup, PlanUpdateParams, Purchase } from '../../types';
import { PurchasePreview } from '../PurchasePreview';
import { DiscountCodeInput } from '../ReviewPlanDetails/DiscountCodeInput';
import { SelectorsSection } from '../ReviewPlanDetails/SelectorsSection';
import { UsersQuantitySlider } from '../ReviewPlanDetails/UsersQuantitySlider';

interface Props {
  data: PlanSetup;
  buyingNewSubscription?: boolean;
  close(): void;
}

const Form: React.FC<Props> = ({ data, buyingNewSubscription, close }) => {
  const route = useRoute<RouteProp<Route.AdminDashboard>>();
  const { isSmallMobile, isTablet } = useDeviceInfo();

  const { data: promotion } = usePromotion();
  const isPromotion = !!promotion;

  const {
    verificationId: studentVerificationId,
    isStudentVerified,
    isFetching: isCurrentStudentStatusLoading,
    setRole,
  } = useStudentVerificationContext();

  const { hasRecurlyAccount, email } = useUserInfo();
  const { data: billingInformation, isLoading: isBillingInformationLoading } = useBillingInformation();
  const { mutateAsync: applyPromotion, isPending: isApplyingPromotion } = useApplyPromotion();
  const { mutateAsync: purchaseAsExistingUser, isPending: isPurchasingSubscription } =
    usePurchaseSubscriptionAsExistingUser();
  const { mutateAsync: changePlan, isPending: isChangingPlan } = useChangePlan();
  const { data: subscriptionInfo, isFetching: isSubscriptionInfoFetching } = useSubscriptionInfo();
  const isSubmittingForm = isApplyingPromotion || isPurchasingSubscription || isChangingPlan;

  const initialData = useMemo(
    () =>
      subscriptionInfo
        ? {
            ...subscriptionInfo.planSetup,
            quantity: subscriptionInfo.numberOfLicences,
            discountCode: subscriptionInfo.couponCode,
          }
        : undefined,
    [subscriptionInfo],
  );

  const [editingPaymentInfo, setEditingPaymentInfo] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isSubmittingPaymentDetails, setIsSubmittingPaymentDetails] = useState(false);

  const isSubmitting = isSubmittingForm || isSubmittingPaymentDetails;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const scrollToFormTop = useCallback(() => {
    if (wrapperRef.current) {
      window.scrollTo({
        top: window.scrollY + wrapperRef.current.getBoundingClientRect().top - NAVBAR_HEIGHT,
        behavior: 'smooth',
      });
    }
  }, []);

  const submit = async (params: {
    planSetup: PlanSetup;
    purchasePreview: Purchase;
    setErrors(errors: Record<string, any>): void;
  }) => {
    try {
      const { discountCode, quantity, plan, role, billingPeriod } = params.planSetup;
      const isUpgradingToPro = plan === Plan.PRO && initialData?.plan === Plan.NORMAL;
      const isIncreasingNumberOfLicences = !!(initialData && quantity > initialData.quantity);
      const planCode = getRecurlyPlan(role, quantity, plan, billingPeriod);
      const updateParams: PlanUpdateParams = {
        isIncreasingNumberOfLicences,
        isUpgradingToPro,
        source: route.params?.formSource,
      };

      if (isPromotion) {
        // promotion.params can be empty when user is upgrading
        // via Subscription Details page, without opening a promotion URL
        await applyPromotion({
          data: {
            planCode,
            promotionId: promotion.id,
            userIdentifier: promotion.params?.userID,
            hospitalName: promotion.params?.hospital,
          },
          updateParams,
        });
      } else {
        const payload = {
          planCode,
          discountCode: discountCode || undefined,
          quantity,
          billingPeriod,
        };

        if (buyingNewSubscription) {
          // purchase-plan
          await purchaseAsExistingUser({
            data: { ...payload, verificationId: studentVerificationId },
            updateParams,
          });
        } else {
          // change-plan
          await changePlan({ data: payload, updateParams });
        }
      }
      close();
    } catch (error: any) {
      const planErrors = mapObjIndexed(getErrorMessageForApiError, error?.response?.data || {});
      const { quantity, discountCode, ...otherErrors } = planErrors;
      if (quantity || discountCode) {
        params.setErrors({ quantity, discountCode });
      } else if (otherErrors) {
        const message = Object.values(otherErrors).join('\n');
        showNotification({ type: 'error', title: message, autoHide: false });
      } else {
        showNotification({ type: 'error', autoHide: false });
      }
      scrollToFormTop();
    }
  };

  const minQuantity = subscriptionInfo?.numberOfLicences || 1;
  const {
    values,
    purchasePreview,
    handlePlanChange,
    handleSubmit,
    handleRoleChange,
    handleBillingPeriodChange,
    setValue,
    isPurchasePreviewLoading,
    isDiscountLoading,
    maxQuantity,
    overMaxQuantity,
    errors,
    isStudent,
  } = usePlanDetailsForm({
    data,
    minQuantity,
    onSubmit: submit,
    email,
    promotionId: promotion?.id,
    billingAddress: billingInformation?.billingAddress,
    studentVerificationId: isStudentVerified ? studentVerificationId : undefined,
  });

  useEffect(() => {
    setRole(values.role);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.role]);

  // user uses promotion only to apply a discount without chaning the plan or buying a new subscription
  const isApplyingPromotionDiscountOnly =
    isPromotion &&
    values.plan === initialData?.plan &&
    values.billingPeriod === initialData?.billingPeriod &&
    !promotion.enableSubscriptionTermination &&
    !buyingNewSubscription;

  const needToFillPaymentInformation = (() => {
    const hasSpecialCreditCardInstructions = !!promotion?.specialInstruction;

    return (
      (buyingNewSubscription && !hasRecurlyAccount) ||
      (hasSpecialCreditCardInstructions && !isApplyingPromotionDiscountOnly) ||
      (!isBillingInformationLoading && !billingInformation?.creditCardInformation)
    );
  })();

  useEffect(() => {
    setEditingPaymentInfo(needToFillPaymentInformation);
  }, [needToFillPaymentInformation]);

  useEffect(() => {
    scrollToFormTop();
  }, [scrollToFormTop]);

  const toggleTerms = () => setTermsAccepted((state) => !state);

  const sameAsCurrentPlan =
    !promotion &&
    !buyingNewSubscription &&
    equals(
      {
        quantity: values.quantity,
        plan: values.plan,
        role: values.role,
      },
      {
        quantity: subscriptionInfo?.numberOfLicences,
        plan: subscriptionInfo?.planSetup.plan,
        role: subscriptionInfo?.planSetup.role,
      },
    );

  const allowRoleChange = buyingNewSubscription && !isPromotion;
  const allowPlanChange = isPromotion
    ? promotionAllowsPlanSelection(promotion)
    : buyingNewSubscription
    ? hasRoleNormalAndProVariants(values.role)
    : subscriptionInfo?.canUserUpgradeToPro.now && subscriptionInfo?.planSetup.plan === Plan.NORMAL;

  const displayBillingCycleSelector =
    (isPromotion ? doesProPromotionAllowDifferentBillingPeriods(promotion) : allowPlanChange) &&
    values.plan === Plan.PRO;

  const displayUsersSlider = !isStudent && !isPromotion;
  const displayDiscountInput = !isPromotion && !isStudent;
  const displaySubmitButton = !sameAsCurrentPlan && !overMaxQuantity;
  const isSubmitButtonDisabled =
    !termsAccepted ||
    (isStudent && !isStudentVerified) ||
    (!!errors && !isEmpty(errors)) ||
    isPurchasePreviewLoading ||
    editingPaymentInfo;

  const displayPaymentDetails = !isApplyingPromotionDiscountOnly;
  const displayPurchasePreview = !isStudent || isStudentVerified;

  const promotionHeader =
    !promotion || isSubscriptionInfoFetching
      ? null
      : isApplyingPromotionDiscountOnly && promotion.calculationBoxAlternativeIntro
      ? promotion.calculationBoxAlternativeIntro
      : promotion.calculationBoxIntro;

  const isLoading = isSubscriptionInfoFetching || isCurrentStudentStatusLoading;

  if (isLoading) {
    return <LoadingIndicator style={styles.loadingIndicator} displayStandbyText />;
  }

  return (
    <div ref={wrapperRef} style={StyleSheet.flatten(styles.wrapper)}>
      <>
        <View
          style={[styles.formWrapper, isSmallMobile && styles.formWrapperMobile]}
          testID="form-purchase-subscription"
        >
          <View style={styles.formContent}>
            {!!promotionHeader && (
              <View>
                <StyledText
                  style={[typography.body4Bold, styles.promotionHeader]}
                  testID="purchase-form-header"
                >
                  {promotionHeader}
                </StyledText>
              </View>
            )}
            {allowRoleChange && (
              <PlanDetailSection
                title={i18n.t('subscriptionDetails:yourRole')}
                style={styles.planSelectorWrapper}
              >
                <SelectorsSection
                  options={roleSelectorOptions}
                  selected={values.role}
                  onPress={handleRoleChange}
                />
              </PlanDetailSection>
            )}
            {allowPlanChange && <PlansComparison />}
            <View style={styles.detailsWrapper}>
              {allowPlanChange && (
                <PlanDetailSection title={i18n.t('subscriptionDetails:planType')}>
                  <SelectorsSection
                    options={planSelectorOptions}
                    selected={values.plan}
                    onPress={handlePlanChange}
                    testID="plan-selector"
                  />
                </PlanDetailSection>
              )}
              {displayBillingCycleSelector && (
                <PlanDetailSection title={i18n.t('subscriptionDetails:billingCycle')}>
                  <SelectorsSection
                    options={billingPlanSelectorOptions}
                    selected={values.billingPeriod}
                    onPress={handleBillingPeriodChange}
                    testID="billing-period-selector"
                  />
                </PlanDetailSection>
              )}
              {displayUsersSlider && (
                <PlanDetailSection title={i18n.t('subscriptionDetails:users')}>
                  <UsersQuantitySlider
                    billingPeriod={values.billingPeriod}
                    defaultQuantity={values.quantity}
                    plan={values.plan}
                    maxQuantity={maxQuantity}
                    minQuantity={minQuantity}
                    onQuantityChange={setValue('quantity')}
                    purchasePreview={purchasePreview}
                    loading={isPurchasePreviewLoading}
                    error={errors.quantity}
                    needFewerLicensesMessage={
                      <StyledText style={styles.usersReductionInfo}>
                        <Trans
                          i18nKey="subscriptionDetails:contactToReduceUsers"
                          components={{
                            support: <Link wrapper="text" to={SUPPORT_LINK} external style={styles.link} />,
                          }}
                        />
                      </StyledText>
                    }
                  />
                </PlanDetailSection>
              )}
              {isStudent && (
                <View style={styles.studentFormWrapper}>
                  <StudentVerification email={email} />
                </View>
              )}
            </View>
            {sameAsCurrentPlan ? (
              <StyledText style={styles.warningMessage}>
                {i18n.t('subscriptionDetails:thisIsYourCurrentPlan')}
              </StyledText>
            ) : overMaxQuantity ? (
              <StyledText style={styles.warningMessage}>
                <Trans
                  i18nKey="subscriptionDetails:needPlumbsForMoreUsers"
                  components={{
                    support: <Link wrapper="text" to={SUPPORT_LINK} style={styles.link} external />,
                  }}
                />
              </StyledText>
            ) : (
              <>
                {displayDiscountInput && (
                  <View style={styles.discountCodeWrapper}>
                    <DiscountCodeInput
                      setDiscountCode={setValue('discountCode')}
                      savedValue={values.discountCode}
                      error={errors.discountCode}
                      loading={isDiscountLoading}
                      alwaysVisible={isStudent}
                    />
                  </View>
                )}
                {displayPurchasePreview && (
                  <PurchasePreview
                    plan={values.plan}
                    billingPeriod={values.billingPeriod}
                    discountCode={values.discountCode}
                    purchasePreview={purchasePreview}
                    loading={isPurchasePreviewLoading}
                    containerStyle={styles.purchasePreviewContainer}
                    finalPriceWrapperStyle={{
                      marginHorizontal: isSmallMobile ? -16 : isTablet ? -24 : -18,
                    }}
                    isApplyingPromotionDiscountOnly={isApplyingPromotionDiscountOnly}
                    role={values.role}
                    isError={!!errors.axios}
                  />
                )}
                {displayPaymentDetails && (
                  <>
                    <Divider />
                    <PaymentDetailsSection
                      isFormOpen={editingPaymentInfo}
                      setSubmitting={setIsSubmittingPaymentDetails}
                      toggleForm={setEditingPaymentInfo}
                      isPromotion={isPromotion}
                      isSubmitting={isSubmitting}
                      needToFillPaymentInformation={needToFillPaymentInformation}
                    />
                  </>
                )}
              </>
            )}
            <Divider />
            <View style={styles.footer}>
              <View style={styles.swtichWrapper}>
                {displaySubmitButton && (
                  <>
                    <Switch
                      onChange={toggleTerms}
                      checked={termsAccepted}
                      testID="terms-conditions-switch"
                    />
                    <StyledText style={styles.termsText}>
                      <Trans
                        i18nKey="subscriptionDetails:iAgreeToTerms"
                        components={{
                          terms: (
                            <Link
                              to={TERMS_AND_CONDITIONS_LINK}
                              external
                              wrapper="text"
                              style={styles.link}
                            />
                          ),
                          privacy: (
                            <Link to={PRIVACY_POLICY_LINK} wrapper="text" external style={styles.link} />
                          ),
                        }}
                      />
                    </StyledText>
                  </>
                )}
              </View>
              <View style={styles.buttonsWrapper}>
                <SecondaryButton
                  title={i18n.t('cancel')}
                  onPress={close}
                  containerStyle={styles.button}
                  disabled={isSubmitting}
                />
                {displaySubmitButton && (
                  <BaseButton
                    title={
                      isApplyingPromotionDiscountOnly
                        ? i18n.t('subscriptionDetails:applyChanges')
                        : i18n.t('subscriptionDetails:confirmPurchase')
                    }
                    containerStyle={styles.button}
                    onPress={handleSubmit}
                    disabled={isSubmitButtonDisabled}
                    loading={isSubmitting}
                    testID="button-confirm-purchase"
                    variant="gradient"
                  />
                )}
                {overMaxQuantity && (
                  <Link to={SUPPORT_LINK} external>
                    <PrimaryButton
                      title={i18n.t('subscriptionDetails:contactSupport')}
                      containerStyle={styles.button}
                    />
                  </Link>
                )}
              </View>
            </View>
          </View>
        </View>
        {displaySubmitButton && (
          <StyledText style={styles.disclaimer}>
            {i18n.t('subscriptionDetails:subscriptionPurchaseDisclaimer')}
          </StyledText>
        )}
      </>
    </div>
  );
};

export const SubscriptionPurchaseForm: React.FC<Props> = (props) => (
  <StudentVerificationProvider>
    <Form {...props} />
  </StudentVerificationProvider>
);

const styles = StyleSheet.create({
  loadingIndicator: {
    marginVertical: 30,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formWrapper: {
    paddingTop: 40,
    paddingBottom: 25,
    backgroundColor: palette.grey1,
    alignSelf: 'stretch',
  },
  formWrapperMobile: {
    marginHorizontal: -24,
  },
  formContent: {
    width: '90%',
    maxWidth: 718,
    alignSelf: 'center',
  },
  studentFormWrapper: {
    zIndex: 'auto',
    width: '100%',
  },
  detailsWrapper: {
    alignItems: 'center',
    zIndex: 'auto',
  },
  link: {
    color: palette.blue,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  swtichWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
    marginBottom: 10,
  },
  termsText: {
    marginLeft: 8,
  },
  buttonsWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexShrink: 1,
    margin: -4,
  },
  button: {
    flexGrow: 1,
    margin: 4,
  },
  disclaimer: {
    marginTop: 32,
    marginBottom: 12,
    maxWidth: 820,
    width: '90%',
    textAlign: 'center',
    alignSelf: 'center',
    ...typography.body1,
  },
  warningMessage: {
    textAlign: 'center',
    ...typography.body2,
  },
  usersReductionInfo: {
    textAlign: 'center',
    maxWidth: 350,
    marginTop: 20,
    alignSelf: 'center',
    ...typography.body2,
  },
  discountCodeWrapper: {
    marginBottom: 30,
  },
  planSelectorWrapper: {
    maxWidth: '100%',
  },
  promotionHeader: {
    color: palette.blue,
    textAlign: 'center',
    marginBottom: 30,
  },
  purchasePreviewContainer: {
    paddingHorizontal: 0,
  },
});
