import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Icon, Popover, Divider, LoadingIndicator, IconButton, StyledText } from 'src/components';
import { SUPPORT_LINK } from 'src/constants/externalLinks';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

import {
  ANNUAL_PAYMENT_DISCOUNT_PERCENTAGE,
  NORMAL_SUBSCRIPTION_QUANTITY_THRESHOLDS,
  PRO_SUBSCRIPTION_QUANTITY_THRESHOLDS,
} from '../constants';
import { isStudentRole } from '../helpers';
import { usePromotion } from '../hooks';
import { BillingPeriod, Plan, PurchasePreviewProps } from '../types';

interface Props extends PurchasePreviewProps {
  containerStyle?: StyleProp<ViewStyle>;
  finalPriceWrapperStyle?: StyleProp<ViewStyle>;
  isApplyingPromotionDiscountOnly?: boolean;
}

export const PurchasePreview: React.FC<Props> = ({
  loading,
  billingPeriod,
  discountCode,
  containerStyle,
  finalPriceWrapperStyle,
  purchasePreview,
  plan,
  isTrial,
  overMaxQuantity,
  taxNotCalculated,
  isError,
  isApplyingPromotionDiscountOnly,
  role,
}) => {
  const { data: promotion } = usePromotion();
  const { t, i18n } = useTranslation('subscriptionProcess');
  const { isSmallestMobile } = useDeviceInfo();

  const Wrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
    <View style={[styles.wrapper, containerStyle]}>{children}</View>
  );

  if (isError && !purchasePreview) {
    return <Wrapper />;
  }

  if (loading || !purchasePreview) {
    return (
      <Wrapper>
        <LoadingIndicator testID="purchase-preview-loader" displayStandbyText />
      </Wrapper>
    );
  }

  if (overMaxQuantity) {
    return (
      <Wrapper>
        <Trans
          i18nKey="subscriptionProcess:needMoreLicenses"
          components={{
            p: <StyledText style={typography.body3} />,
            support: <Link to={SUPPORT_LINK} external style={styles.link} />,
          }}
        />
      </Wrapper>
    );
  }

  const {
    quantity,
    discount,
    baseUnitAmount,
    notDiscountedUnitAmount,
    dueToday,
    dueAtRenewal,
    annualDiscount,
    credit,
    balance,
    dueTodaySubtotal,
    dueTodayTax,
    dueAtRenewalTax,
  } = purchasePreview;
  const perUserDiscount = baseUnitAmount - notDiscountedUnitAmount;
  const discountThreshold = (() => {
    let result = 0;
    (plan === Plan.NORMAL
      ? NORMAL_SUBSCRIPTION_QUANTITY_THRESHOLDS
      : PRO_SUBSCRIPTION_QUANTITY_THRESHOLDS
    ).forEach((threshold) => {
      if (threshold <= quantity) {
        result = threshold;
      }
    });
    return result;
  })();

  const activeDiscounts = [perUserDiscount, perUserDiscount, annualDiscount, discount].filter(Boolean);
  const isStudent = isStudentRole(role);

  return (
    <View style={styles.container} testID="plan-details-purchase-preview">
      <Wrapper>
        <SectionHeader>{t('planDetails')}</SectionHeader>
        {promotion?.subhead ? (
          <View style={styles.section}>
            <View style={styles.sectionText}>
              <StyledText style={typography.body3SemiBold} testID="plan-label-text">
                {t('promotionCalculationBoxIntro', {
                  plan: t(plan, { ns: 'planVariants' }),
                  intro: promotion.subhead,
                })}
              </StyledText>
            </View>
          </View>
        ) : (
          <StyledText style={typography.body3SemiBold}>{t(plan, { ns: 'planVariants' })}</StyledText>
        )}
        <View style={styles.section}>
          <View style={styles.sectionText}>
            <StyledText style={typography.body3}>
              {t('pricePerUsers', {
                count: quantity,
                value: baseUnitAmount,
                period: billingPeriod === BillingPeriod.ANNUAL ? t('year') : t('month'),
              })}
            </StyledText>
          </View>
          <StyledText style={[typography.body3SemiBold, styles.rightColumn]}>
            {i18n.format(baseUnitAmount * quantity, 'price')}
          </StyledText>
        </View>
        {(!!activeDiscounts.length || !!credit) && (
          <>
            <Divider style={styles.divider} />
            <SectionHeader>
              {t(
                !!activeDiscounts.length && !!credit
                  ? 'discountAndCredits'
                  : credit
                  ? 'credits'
                  : 'discounts',
              )}
            </SectionHeader>
          </>
        )}
        {!!perUserDiscount && (
          <View style={styles.section}>
            <View style={styles.sectionText}>
              <StyledText style={typography.body3}>
                {t('userDiscount', { count: discountThreshold })}{' '}
              </StyledText>
              <StyledText style={[typography.body3, styles.highlighted]}>
                (
                {t('savePricePerUser', {
                  value: perUserDiscount,
                  users: quantity,
                })}
                )
              </StyledText>
            </View>
            <StyledText style={[typography.body3SemiBold, styles.rightColumn]}>
              {i18n.format(-perUserDiscount * quantity, 'price')}
            </StyledText>
          </View>
        )}
        {!!annualDiscount && (
          <View style={styles.section}>
            <View style={styles.sectionText}>
              <StyledText style={typography.body3}>
                {t('annualDiscount', { value: ANNUAL_PAYMENT_DISCOUNT_PERCENTAGE })}
              </StyledText>
            </View>
            <StyledText style={[typography.body3SemiBold, styles.rightColumn]}>
              {i18n.format(-annualDiscount * quantity, 'price')}
            </StyledText>
          </View>
        )}
        {!!discount && (
          <View style={styles.section}>
            <View style={styles.sectionText}>
              <StyledText style={typography.body3}>
                <Trans
                  ns="subscriptionProcess"
                  i18nKey={
                    promotion
                      ? 'promotionDiscountValueOff'
                      : isStudent
                      ? 'studentDiscountOff'
                      : 'discountValueOff'
                  }
                  values={{ code: discountCode, amount: discount, campaign: promotion?.campaignName }}
                  components={{
                    color: <StyledText style={styles.highlighted} />,
                    b: <StyledText style={styles.bold} />,
                  }}
                />
              </StyledText>
            </View>
            <StyledText style={[typography.body3SemiBold, styles.rightColumn]}>
              {i18n.format(-discount, 'price')}
            </StyledText>
          </View>
        )}
        {!!credit && (
          <View style={styles.section}>
            <View style={styles.sectionText}>
              <StyledText style={typography.body3}>{t('accountCredit')}</StyledText>
              <IconButton
                name="question-mark-circle"
                width={14}
                tooltip={
                  balance
                    ? t('accountCreditExplainerWithBalance', { balance: i18n.format(balance, 'price') })
                    : t('accountCreditExplainer')
                }
                containerStyle={styles.tooltipIcon}
              />
            </View>
            <StyledText style={[typography.body3SemiBold, styles.rightColumn]}>
              {i18n.format(-credit, 'price')}
            </StyledText>
          </View>
        )}
        <Divider style={styles.divider} />

        {!isTrial ? (
          <View style={styles.section}>
            <StyledText style={[typography.body3]}>{t('subtotal')}</StyledText>
            <StyledText style={[typography.body3SemiBold, styles.rightColumn]}>
              {i18n.format(dueTodaySubtotal, 'price')}
            </StyledText>
          </View>
        ) : (
          <>
            <View style={styles.section}>
              <StyledText style={[typography.body3]}>{t('dueAfterTrial')}</StyledText>
              <StyledText style={[typography.body3SemiBold, styles.rightColumn]}>
                {t('pricePerPeriod', {
                  value: dueAtRenewal,
                  period: `$t(subscriptionProcess:${
                    billingPeriod === BillingPeriod.ANNUAL ? 'year' : 'month'
                  })`,
                })}
              </StyledText>
            </View>
            <View style={styles.section}>
              <StyledText style={[typography.body3]}>{t('subtotal')}</StyledText>
              <StyledText style={[typography.body3SemiBold, styles.rightColumn]}>
                {i18n.format(dueTodaySubtotal, 'price')}
              </StyledText>
            </View>
          </>
        )}
        <View style={[styles.section, styles.sectionLast]}>
          <StyledText style={[typography.body3]}>
            {t(isApplyingPromotionDiscountOnly ? 'estimatedSalesTax' : 'salesTax')}
          </StyledText>
          <StyledText
            style={[taxNotCalculated ? typography.body3 : typography.body3SemiBold, styles.rightColumn]}
          >
            {taxNotCalculated
              ? t('calculatedAtCheckout')
              : i18n.format(isApplyingPromotionDiscountOnly ? dueAtRenewalTax : dueTodayTax, 'price')}
          </StyledText>
        </View>
      </Wrapper>
      <View
        style={[
          styles.finalPriceWrapper,
          isSmallestMobile && styles.finalPriveWrapperSmallestMobile,
          finalPriceWrapperStyle,
        ]}
      >
        <View style={styles.dueAtRenewalWrapper}>
          <StyledText style={[typography.body3Bold, styles.finalPriceText]}>
            {t(isApplyingPromotionDiscountOnly ? 'estimatedDueAtRenewal' : 'amountDueToday')}
          </StyledText>
          {isApplyingPromotionDiscountOnly && (
            <View style={styles.renewalTooltipIcon}>
              <Popover
                backgroundColor={palette.white}
                content={<StyledText>{t('estimatedDueAtRenewalInfo')}</StyledText>}
                contentWrapperStyles={styles.renewalTooltipContent}
              >
                <Icon name="info-circle" color={palette.whiteTransparent} />
              </Popover>
            </View>
          )}
        </View>
        <StyledText
          style={[typography.body3Bold, styles.finalPriceText, styles.rightColumn]}
          testID="due-today-price"
        >
          {i18n.format(isApplyingPromotionDiscountOnly ? dueAtRenewal : dueToday, 'price')}
        </StyledText>
      </View>
    </View>
  );
};

const SectionHeader: React.FC<React.PropsWithChildren> = (props) => (
  <StyledText style={[typography.body3Bold, styles.sectionHeader]}>{props.children}</StyledText>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
    flexShrink: 1,
  },
  wrapper: {
    backgroundColor: palette.grey1,
    width: '100%',
    flexShrink: 1,
    padding: 20,
  },
  section: {
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'row',
    marginBottom: 8,
  },
  sectionLast: {
    marginBottom: 0,
  },
  sectionHeader: {
    color: palette.blue,
    marginBottom: 4,
  },
  sectionText: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  highlighted: {
    color: palette.blue,
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
  },
  rightColumn: {
    paddingLeft: 10,
  },
  divider: {
    marginTop: 12,
    marginBottom: 20,
  },
  link: {
    color: palette.blue,
  },
  tooltipIcon: {
    marginLeft: 5,
  },
  finalPriceWrapper: {
    paddingVertical: 20,
    paddingHorizontal: 24,
    backgroundColor: palette.navy,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  finalPriveWrapperSmallestMobile: {
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  finalPriceText: {
    color: palette.white,
  },
  dueAtRenewalWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  renewalTooltipIcon: {
    marginLeft: 11,
  },
  renewalTooltipContent: {
    paddingHorizontal: 24,
    paddingVertical: 16,
    maxWidth: 280,
  },
});
