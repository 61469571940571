import { useSuspenseQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import { useMemo } from 'react';

import { fetchFirstLetters } from 'src/api/content';
import { ContentType, ContentListType, LettersResponse } from 'src/constants/types';
import { alphabet, clinicalBriefsContentTypes, clinicalHandoutsContentTypes } from 'src/helpers';

const contentTypesMap: {
  [contentType in ContentListType]: ContentType[];
} = {
  [ContentListType.Algorithms]: [ContentType.Algorithm],
  [ContentListType.Appendix]: [ContentType.Appendix],
  [ContentListType.DxTx]: clinicalBriefsContentTypes,
  [ContentListType.Drugs]: [ContentType.Drug],
  [ContentListType.DrugHandouts]: [ContentType.DrugHandout, ContentType.VeterinaryMedicationGuide],
  [ContentListType.ClinicalHandouts]: clinicalHandoutsContentTypes,
};

const prepareDataForContentType = (data: LettersResponse, contentListType: ContentListType) => {
  const contentTypes = contentTypesMap[contentListType];
  const allLetters = R.flatten(R.props(contentTypes, data));
  return alphabet.map((letter: string) => ({
    letter: letter.toUpperCase(),
    hasItems: allLetters.includes(letter.toUpperCase()),
  }));
};

export const useContentLetters = (contentListType: ContentListType) => {
  const { data, ...query } = useSuspenseQuery({
    queryKey: ['letters'],
    queryFn: fetchFirstLetters,
  });

  const dataForThisContentType = useMemo(() => {
    if (data === undefined) {
      return undefined;
    }
    return prepareDataForContentType(data, contentListType);
  }, [data, contentListType]);

  return { ...query, data: dataForThisContentType };
};
