import React, { useState } from 'react';
import { LayoutChangeEvent, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import Svg, { Rect } from 'react-native-svg';

import { definitions } from 'src/assets/icons/definitions';
import { isWeb } from 'src/helpers';
import { ifWeb } from 'src/styles';

interface Props extends React.PropsWithChildren {
  style?: StyleProp<ViewStyle>;
  gradientType: keyof typeof definitions;
  hideGradient?: boolean;
}

export const GradientBar: React.FC<Props> = ({ children, style, gradientType, hideGradient }) => {
  const [width, setWidth] = useState<string | number>('100%');
  const handleOnLayout = (e: LayoutChangeEvent) => {
    !isWeb && setWidth(e.nativeEvent.layout.width);
  };

  const gradient = definitions[gradientType];

  return (
    <View onLayout={!isWeb ? handleOnLayout : undefined} style={style}>
      {!hideGradient && (
        <Svg
          height={isWeb ? '100%' : undefined}
          width={width}
          style={[StyleSheet.absoluteFill, styles.wrapper]}
          key={!isWeb ? `${width}${gradientType}` : `${width}+gradientBar${gradientType}`}
        >
          {gradient}
          <Rect x="0" y="0" width="100%" height="100%" fill={`url(#${gradientType})`} />
        </Svg>
      )}
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    ...ifWeb({
      width: '100%',
    }),
  },
});
