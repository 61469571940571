import React from 'react';
import { StyleSheet, View } from 'react-native';

import { TopPanelLeftActionPortal, TopPanelTitlePortal } from 'src/contexts/portals';
import { useWebAppLayout } from 'src/hooks/useWebAppLayout';
import { useNavigation } from 'src/navigation/hooks/useNavigation';
import { fonts, palette, zindex } from 'src/styles';

import { FullScreenTemplate as ComponentBase } from './FullScreenTemplate';
import { GradientShadow } from './GradientShadow.web';
import { IconButton } from './IconButton';
import { LoadingIndicator } from './LoadingIndicator';
import { MarkdownContent } from './MarkdownContent';

interface Props extends React.ComponentProps<typeof ComponentBase> {}

const BACK_BUTTON_SIZE = 16;

export const FullScreenTemplate: React.FC<Props> = ({
  children,
  contentContainerStyle,
  isLoading,
  displayStandbyText,
  title,
  testID,
  titleInUppercase = true,
  stickyContent,
  showBackButton,
  titleStyle,
}) => {
  const { topPanelHeight } = useWebAppLayout();
  const navigation = useNavigation();

  const actionLeft = showBackButton && (
    <IconButton
      onPress={() => navigation.goBack()}
      name="back"
      color={palette.navy}
      width={BACK_BUTTON_SIZE}
      containerStyle={styles.titleBarBackButtonContainer}
      testID="top-panel-back-button"
    />
  );

  const titleElement = !!title && (
    <MarkdownContent
      inline
      style={[styles.title, titleInUppercase && styles.titleUppercase, titleStyle]}
      limited
    >
      {title}
    </MarkdownContent>
  );

  return (
    <View style={styles.container} testID={testID}>
      <TopPanelTitlePortal>{titleElement}</TopPanelTitlePortal>
      <TopPanelLeftActionPortal>{actionLeft}</TopPanelLeftActionPortal>
      <View style={[styles.stickyContainer, { top: topPanelHeight }]}>
        {stickyContent}
        {/* We can't apply shadow as box-shadow in headerContainer as it may have 0 height in some cases */}
        <GradientShadow />
      </View>
      {isLoading ? (
        <View style={styles.loadingContainer}>
          <LoadingIndicator displayStandbyText={displayStandbyText} />
        </View>
      ) : (
        <View style={[contentContainerStyle]}>{children}</View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: palette.white,
    // Removes flickering when scrolling really fast in Chrome
    // (Enables hardware compositing in modern browsers, borrowed from RN Web)
    transform: [{ translateZ: 0 }],
  },
  loadingContainer: {
    paddingVertical: 150,
  },
  stickyContainer: {
    zIndex: zindex.pageHeader,
    backgroundColor: palette.white,
    position: 'sticky',
  },
  titleBarBackButtonContainer: {
    width: BACK_BUTTON_SIZE,
  },
  title: {
    fontFamily: fonts.din,
    fontSize: 24,
    color: palette.navy,
  },
  titleUppercase: {
    textTransform: 'uppercase',
  },
});
