import { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react';

import { RecaptchaError } from 'src/constants/errors';
import { EnvService } from 'src/services';

declare global {
  interface Window {
    captchaOnLoad: () => void;
    grecaptcha?: {
      execute(): void;
      render(element: string | HTMLElement, options: Record<string, any>): void;
      reset(): void;
      getResponse(): void;
    };
  }
}

export interface RecaptchaRef {
  execute(): Promise<string>;
}

type Callbacks = {
  resolve(token: string): void;
  reject(reason?: any): void;
};

const scriptExists = (src: string) =>
  [...document.querySelectorAll('script')].some((script) => script.src === src);

const SITE_KEY = EnvService.getEnv('RECAPTCHA_SITE_KEY');
const SCRIPT_CALLBACK_NAME = 'captchaOnLoad';
const SCRIPT_URL = `https://www.google.com/recaptcha/api.js?render=explicit&onload=${SCRIPT_CALLBACK_NAME}`;

export const Recaptcha = forwardRef<RecaptchaRef>((_props, ref) => {
  const [verifiedToken, setVerifiedToken] = useState<string | undefined>();
  const wrapper = useRef<HTMLDivElement>(null);
  const captchaCallbacks = useRef<Callbacks>({
    resolve: () => null,
    reject: () => null,
  });

  const execute = () =>
    new Promise<string>((resolve, reject) => {
      if (verifiedToken) {
        resolve(verifiedToken);
        return;
      }
      captchaCallbacks.current.resolve = resolve;
      captchaCallbacks.current.reject = reject;
      if (window.grecaptcha) {
        window.grecaptcha.execute();
      } else {
        reject(new RecaptchaError('Recaptcha not loaded'));
      }
    });

  useEffect(() => {
    if (!SITE_KEY) {
      return;
    }

    // We render into this additional element only to have the ability to completely replace it on effect re-run.
    // Otherwise, grecaptcha.render() will throw an error.
    // This can only happen during development (on hot reload).
    const div = document.createElement('div');
    wrapper.current!.appendChild(div);

    const render = () => {
      window.grecaptcha!.render(div, {
        sitekey: SITE_KEY,
        badge: 'bottomleft',
        size: 'invisible',
        callback: (token: string) => {
          setVerifiedToken(token);
          captchaCallbacks.current.resolve(token);
        },
        'error-callback': () => {
          setVerifiedToken(undefined);
          captchaCallbacks.current.reject(new RecaptchaError());
        },
        'expired-callback': () => {
          setVerifiedToken(undefined);
        },
      });
    };

    if (scriptExists(SCRIPT_URL)) {
      render();
    } else {
      window[SCRIPT_CALLBACK_NAME] = render;

      const script = document.createElement('script');
      script.src = SCRIPT_URL;
      document.body.appendChild(script);
    }

    return () => {
      div.remove();
    };
  }, [setVerifiedToken]);

  useImperativeHandle(ref, () => ({
    execute: SITE_KEY ? execute : () => Promise.resolve(''),
  }));

  return <div id="recaptcha" ref={wrapper} />;
});
