import React, { useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { LoadingIndicatorBox } from 'src/components';
import { BoxWithHeader } from 'src/components/BoxWithHeader';
import { Pagination } from 'src/components/Pagination';
import { StyledText } from 'src/components/StyledText';
import { Table } from 'src/components/Table/Table';
import { SUPPORT_LINK } from 'src/constants/externalLinks';
import { SortParams, TableColumn } from 'src/constants/types';
import { QueryErrorBanner } from 'src/errorHandling/components';
import { useUserInfo } from 'src/features/auth/hooks';
import { formatDateWithMonths } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

import { InvoiceCell } from './InvoiceCell';
import { useInvoicesList } from '../../hooks';
import { Invoice, InvoicesOrdering, InvoicesSortableColumns } from '../../types';

// to avoid displaying junk invoices that were created because of some manual
// migrations/account updates in Recurly we have a cutoff at this date
const thresholdDate = '2021-12-10';

export const PaymentHistory: React.FC = () => {
  const { isDesktop } = useDeviceInfo();
  const { hasRecurlyAccount } = useUserInfo();

  const [page, setPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<InvoicesOrdering>('-createdAt');

  const list = useInvoicesList({
    params: {
      page,
      pageSize: 8,
      ordering: sortBy,
    },
    enabled: hasRecurlyAccount,
  });

  const handleSortParamsChange = (params: SortParams<InvoicesSortableColumns>) => {
    setSortBy(`${params.order === 'desc' ? '-' : ''}${params.sortBy}`);
    setPage(1);
  };

  const renderDateCell = useCallback(
    (paymentData: Invoice) => formatDateWithMonths(paymentData.createdAt),
    [],
  );

  const columns = useMemo<TableColumn<Invoice>[]>(
    () => [
      {
        header: i18n.t('date'),
        key: 'createdAt',
        sort: true,
        renderCell: renderDateCell,
      },
      {
        header: i18n.t('subscriptionDetails:totalPaid'),
        key: 'total',
        sort: true,
        align: 'right',
        renderCell: (data) => i18n.format(data.total, 'price'),
      },
      {
        header: i18n.t('subscriptionDetails:invoiceNumber'),
        key: 'number',
        sort: true,
      },
      {
        header: i18n.t('subscriptionDetails:actions'),
        key: 'actions',
        renderCell: (invoice: Invoice) => <InvoiceCell invoice={invoice} />,
      },
    ],
    [renderDateCell],
  );

  const hasResults = list.isSuccess && list.data.count > 0;

  return (
    <BoxWithHeader
      title={i18n.t('subscriptionDetails:paymentHistory')}
      contentHeaderStyle={[styles.boxHeader, hasResults && styles.boxHeaderNoDivider]}
      style={styles.box}
      testID="box-payment-history"
    >
      <QueryErrorBanner
        error={list.error}
        fetchStatus={list.fetchStatus}
        isDataAvailable={!!list.data}
        errorComponentType="message"
        style={styles.errorMessage}
      />
      {!list.error && list.fetchStatus !== 'paused' && (
        <LoadingIndicatorBox style={isDesktop && styles.tableWrapperDesktop} isLoading={list.isFetching}>
          {hasResults ? (
            <View>
              <Table
                columns={columns}
                data={list.data.results}
                minWidth={500}
                initialSortBy="createdAt"
                initialSortOrder="desc"
                simplified
                disableLocalSorting
                onSortChange={handleSortParamsChange}
              />
            </View>
          ) : null}
          {((list.isSuccess && list.data.isLastPage) ||
            (list.status === 'pending' && list.fetchStatus === 'idle')) && (
            <Trans
              i18nKey="subscriptionDetails:lookingForOlderInvoices"
              components={{
                p: <StyledText style={[typography.body2, styles.infoText]} />,
                support: <Link to={SUPPORT_LINK} style={styles.link} wrapper="text" external />,
              }}
              values={{
                date: formatDateWithMonths(thresholdDate),
              }}
            />
          )}
        </LoadingIndicatorBox>
      )}
      {hasResults && (
        <Pagination
          currentPage={page}
          numberOfPages={list.data.numberOfPages}
          onPageChange={setPage}
          style={styles.pagination}
        />
      )}
    </BoxWithHeader>
  );
};

const styles = StyleSheet.create({
  box: {
    flex: 1,
  },
  boxHeader: {
    marginBottom: 0,
  },
  boxHeaderNoDivider: {
    borderBottomWidth: 0,
  },
  link: {
    color: palette.blue,
  },
  infoText: {
    color: palette.grey5,
    textAlign: 'center',
    marginTop: 30,
  },
  tableWrapperDesktop: {
    minHeight: 520,
  },
  pagination: {
    marginTop: 32,
  },
  errorMessage: {
    marginTop: 30,
  },
});
