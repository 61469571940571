import * as R from 'ramda';
import { useState, Suspense, ReactNode } from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';

import { BottomStickyElementsPortal } from 'src/contexts/portals';
import { Calculator } from 'src/features/calculator';
import { isWeb } from 'src/helpers';
import { i18n } from 'src/locale';
import { BOTTOM_BAR_HEIGHT, CONTAINER_PADDING_H_MOBILE, getShadow, ifWeb, palette } from 'src/styles';

import { Container } from './Container';
import { IconTextButton } from './IconTextButton';
import { LoadingIndicator } from './LoadingIndicator';

interface ButtonWithCondition {
  condition: boolean;
  content: ReactNode;
}

interface Props {
  scrollToTop?: () => void;
  showScrollToTop?: boolean;
  toggleItemCollapse?: () => void;
  isAnyItemExpanded?: boolean;
  showCalculator?: boolean;
  /** Called after scrolling to top; not the scrolling logic itself */
  afterScrollToTop?(): void;
}

export const BottomBar: React.FC<Props> = ({
  toggleItemCollapse,
  isAnyItemExpanded,
  showScrollToTop,
  showCalculator,
  scrollToTop: alternativeScrollToTop,
  afterScrollToTop,
}) => {
  const scrollToTop = () => {
    if (alternativeScrollToTop) {
      alternativeScrollToTop();
    } else if (isWeb) {
      window.scrollTo({ top: 0 });
    }
    afterScrollToTop?.();
  };

  const [isCalculatorOpen, setCalculatorOpen] = useState(false);
  const isCalculatorAvailable = isCalculatorOpen || showCalculator;

  const { height: screenHeight } = useWindowDimensions();
  const expandedFooterHeight = R.clamp(450, 480, screenHeight * 0.5);

  const possibleRightButtons: ButtonWithCondition[] = [
    {
      condition: !!isCalculatorAvailable,
      content: (
        <IconTextButton
          text={i18n.t(`calculator:calculator`)}
          onPress={() => setCalculatorOpen(!isCalculatorOpen)}
          icon={isCalculatorOpen ? 'chevron-down' : 'chevron-up'}
          iconPosition="right"
          circleIcon
          testID="button-calculator"
        />
      ),
    },
    {
      condition: !!toggleItemCollapse,
      content: isAnyItemExpanded ? (
        <IconTextButton
          text={i18n.t('closeAll')}
          onPress={toggleItemCollapse}
          icon="close"
          iconPosition="right"
          circleIcon
          testID="button-closeAll"
        />
      ) : (
        <IconTextButton
          text={i18n.t('openAll')}
          onPress={toggleItemCollapse}
          icon="plus"
          iconPosition="right"
          circleIcon
          iconWidth={12}
          testID="button-openAll"
        />
      ),
    },
  ];

  const rightButton = possibleRightButtons.find((item) => item.condition)?.content;

  const content = (
    <View style={[styles.footer, isCalculatorOpen && { height: expandedFooterHeight }]}>
      <View style={styles.footerButtons}>
        {showScrollToTop ? (
          <IconTextButton
            text={i18n.t(`common:backToTop`)}
            onPress={scrollToTop}
            icon="chevron-up"
            iconPosition="right"
            circleIcon
            iconWidth={12}
          />
        ) : (
          <View />
        )}
        {rightButton}
      </View>
      {isCalculatorAvailable && (
        <Container style={[styles.calculatorWrapper, isCalculatorOpen && styles.calculatorWrapperOpen]}>
          <Suspense fallback={<LoadingIndicator />}>
            <Calculator inPageView />
          </Suspense>
        </Container>
      )}
    </View>
  );

  if (isWeb) {
    return <BottomStickyElementsPortal>{content}</BottomStickyElementsPortal>;
  }
  return content;
};

const styles = StyleSheet.create({
  footer: {
    height: BOTTOM_BAR_HEIGHT,
    justifyContent: 'center',
    backgroundColor: palette.white,
    ...getShadow(4, 0.48, 20),
    ...ifWeb(
      {},
      {
        borderTopWidth: 1,
        borderTopColor: palette.grey2,
      },
    ),
  },
  footerButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: CONTAINER_PADDING_H_MOBILE,
    paddingVertical: 11,
  },
  calculatorWrapper: {
    paddingBottom: 16,
    maxWidth: 400,
    flex: 1,
    display: 'none',
  },
  calculatorWrapperOpen: {
    display: 'flex',
  },
});
