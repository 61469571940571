import React from 'react';
import { StyleSheet, View } from 'react-native';

import { StyledText } from 'src/components';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { palette, typography } from 'src/styles';

interface Props {
  isPro?: boolean;
}

export const TrialInfoBox: React.FC<Props> = ({ isPro }) => {
  const { isTablet } = useDeviceInfo();

  return (
    <View style={[styles.wrapper, isTablet && styles.wrapperTablet]} testID="subscription-trial-infobox">
      <StyledText style={typography.body2}>
        {i18n.t(isPro ? 'subscriptionProcess:proTrialInfo' : 'subscriptionProcess:trialInfo')}
      </StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: 20,
    paddingHorizontal: 24,
    backgroundColor: palette.white,
    borderWidth: 1,
    borderColor: palette.grey2,
    marginBottom: 40,
  },
  wrapperTablet: {
    paddingVertical: 24,
    paddingHorizontal: 32,
  },
});
