import * as R from 'ramda';
import React, { useEffect, useState } from 'react';

import { renderFormField } from 'src/hooks/forms/common';
import { i18n } from 'src/locale';

import { occupationDetailsForm } from '../forms/forms';
import type { OccupationDetails } from '../types';

type OccupationDetailsKeys = keyof OccupationDetails;

// Those fields should be in the order we want to display them to users
const fields: OccupationDetailsKeys[] = [
  'activity',
  'activityOther',
  'pharmacy',
  'pharmacyOther',
  'graduationYear',
  'schoolAttending',
  'schoolAttendingOther',
  'role',
  'roleOther',
  'schoolGraduated',
  'schoolGraduatedOther',
  'yearsOfPractice',
  'numberOfVeterinarians',
  'practiceType',
  'practiceTypeOther',
  'practiceOwnership',
];

const occupationDetailsEmptyState = fields.reduce(
  (obj, key) => ({ ...obj, [key]: null }),
  {},
) as OccupationDetails;

const requiredTextError = i18n.t('validation:thisFieldIsRequired');
const requiredSelectError = i18n.t('validation:pleaseSelectOption');

export function useOccupationDetailsForm(
  initialValues: OccupationDetails | undefined,
  onSubmit: (values: OccupationDetails) => void,
) {
  const form = occupationDetailsForm;
  const [values, setValues] = useState<OccupationDetails>(initialValues || occupationDetailsEmptyState);
  const [submittedValues, setSubmittedValues] = useState<OccupationDetails>(values);
  const [dirty, setDirty] = useState(false);

  // Initially empty string for every form field
  const [errors, setErrors] = useState(
    Object.keys(initialValues || occupationDetailsEmptyState).reduce(
      (obj, key) => ({ ...obj, [key]: '' }),
      {},
    ),
  );

  const isFieldRequired = (key: OccupationDetailsKeys): boolean => {
    const field = form[key];
    return !!(field && !field.isOptional);
  };

  const getFieldMaxLength = (key: OccupationDetailsKeys): number | undefined => {
    const field = form[key];
    return field.maxLength;
  };

  const shouldFieldBeDisplayed = (
    key: OccupationDetailsKeys,
    _values: OccupationDetails = values,
  ): boolean => {
    const field = form[key];
    if (!field) return false;
    return typeof field.shouldBeDisplayed === 'boolean' || typeof field.shouldBeDisplayed === 'undefined'
      ? !!field.shouldBeDisplayed
      : field.shouldBeDisplayed(_values);
  };

  /** Checks if field is valid and sets error messages if needed */
  const validateField = (key: OccupationDetailsKeys, value: string | null = values[key]) => {
    const shouldDisplay = shouldFieldBeDisplayed(key);
    if (shouldDisplay) {
      const isRequired = isFieldRequired(key);
      if (isRequired && !value) {
        setErrors((state) => ({ ...state, [key]: getRequiredErrorMessage(key as OccupationDetailsKeys) }));
        return false;
      }

      const fieldMaxLength = getFieldMaxLength(key);
      if (value && fieldMaxLength && value.length > fieldMaxLength) {
        setErrors((state) => ({ ...state, [key]: getTooLongErrorMessage(fieldMaxLength) }));
        return false;
      }
    }
    setErrors((state) => ({ ...state, [key]: undefined }));
    return true;
  };

  const validateForm = (): boolean => {
    return (
      Object.entries(values).filter(([key, value]) => !validateField(key as OccupationDetailsKeys, value))
        .length === 0
    );
  };

  const getRequiredErrorMessage = (key: OccupationDetailsKeys) => {
    const isSelect = form[key].type === 'select';
    const defaultRequiredError = isSelect ? requiredSelectError : requiredTextError;
    const requiredErrorKey = `user:${key}RequiredError`;
    return i18n.exists(requiredErrorKey) ? i18n.t(requiredErrorKey) : defaultRequiredError;
  };

  const getTooLongErrorMessage = (maxLength: number) => {
    return i18n.t('validation:maxTextLength', { max: maxLength });
  };

  const handleSubmit = () => {
    const isFormValid = validateForm();
    if (!isFormValid) return;
    setSubmittedValues(values);
    onSubmit(values);
  };

  const markNotVisibleFieldsAsNull = (_values: OccupationDetails): OccupationDetails => {
    const updatedValues = { ..._values };

    fields.forEach((key) => {
      if (!shouldFieldBeDisplayed(key, updatedValues)) {
        updatedValues[key] = null;
      }
    });

    return updatedValues;
  };
  const renderField = (key: OccupationDetailsKeys): React.ReactNode => {
    const field = form[key];
    if (!shouldFieldBeDisplayed(key)) return null;

    const handleBlur = () => {
      if (field.type !== 'select') validateField(key);
    };

    const handleChange = (value: string) => {
      let updatedValues = { ...values, [key]: value };
      if (value === 'student-pharmacy' && key === 'activity') {
        updatedValues = { ...updatedValues, schoolAttending: 'other' };
      }
      updatedValues = markNotVisibleFieldsAsNull(updatedValues);

      setValues(updatedValues);
      validateField(key, value);
    };

    return renderFormField({
      ...field,
      handleBlur,
      handleChange,
      handleSubmit,
      touched: true,
      error: errors[key],
      value: values[key] || '',
      key,
    });
  };

  useEffect(() => {
    setDirty(!R.equals(values, submittedValues));
  }, [values, submittedValues]);

  return {
    renderField,
    handleSubmit,
    setErrors,
    setValues,
    errors,
    dirty,
  };
}
