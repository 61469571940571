import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { StyledText } from 'src/components/StyledText';
import { ifWeb, typography } from 'src/styles';

interface Props {
  title: string;
  style?: StyleProp<ViewStyle>;
}

export const PlanDetailSection: React.FC<React.PropsWithChildren<Props>> = ({ title, children, style }) => {
  return (
    <View style={[styles.wrapper, style]}>
      <StyledText style={[styles.title, typography.body3Bold]}>{title}</StyledText>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    maxWidth: 585,
    marginBottom: 40,
    ...ifWeb({
      zIndex: 'auto',
    }),
  },
  title: {
    marginBottom: 20,
    textAlign: 'center',
  },
});
