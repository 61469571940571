import {
  CardCvvElement,
  CardElement,
  CardMonthElement,
  CardNumberElement,
  CardYearElement,
  CommonElementProps,
} from '@recurly/react-recurly';
import React, { useState } from 'react';
import { StyleSheet, TextInput as BaseTextInput } from 'react-native';

import { InputBase } from 'src/components/InputBase';
import { fonts, palette, typography } from 'src/styles';

import { CardsSection } from './CardsSection';

type Props = {
  label: string;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
  placeholder?: string;
};

export const wrappedRecurlyElement = function <T extends CommonElementProps<any>>(
  Component: React.ComponentType<T>,
  AdditionalLabelComponent?: React.ComponentType<{ error: boolean }>,
) {
  return ({ label, disabled, placeholder, error, touched, onFocus, onBlur, ...props }: T & Props) => {
    const [focused, setFocused] = useState(false);
    const shouldShowError = !!error && !!touched;

    return (
      <InputBase
        label={label}
        error={shouldShowError ? error : undefined}
        AdditionalLabelComponent={AdditionalLabelComponent}
        focused={focused}
        readOnly={disabled}
        inputContainerStyle={styles.componentContainer}
      >
        {disabled ? (
          <BaseTextInput
            readOnly
            style={[typography.body3, styles.disabledInput]}
            placeholderTextColor={palette.grey4}
            placeholder={placeholder}
          />
        ) : (
          <Component
            {...(props as unknown as T)}
            onFocus={() => {
              onFocus?.();
              setFocused(true);
            }}
            onBlur={() => {
              onBlur?.();
              setFocused(false);
            }}
            style={{
              fontColor: palette.grey8,
              fontSize: `${typography.body2.fontSize}px`,
              fontFamily: fonts.sourceSans,
            }}
          />
        )}
      </InputBase>
    );
  };
};

const styles = StyleSheet.create({
  disabledInput: {
    minWidth: 0,
    flex: 1,
    paddingHorizontal: 16,
    color: palette.grey8,
  },
  componentContainer: {
    flexDirection: 'column',
  },
});

const WrappedCardCvvElement = wrappedRecurlyElement(CardCvvElement);
const WrappedCardElement = wrappedRecurlyElement(CardElement);
const WrappedCardMonthElement = wrappedRecurlyElement(CardMonthElement);
const WrappedCardNumberElement = wrappedRecurlyElement(CardNumberElement, CardsSection);
const WrappedCardYearElement = wrappedRecurlyElement(CardYearElement);

export {
  WrappedCardCvvElement as CardCvvElement,
  WrappedCardElement as CardElement,
  WrappedCardMonthElement as CardMonthElement,
  WrappedCardNumberElement as CardNumberElement,
  WrappedCardYearElement as CardYearElement,
};
