import React, { useCallback, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';

import { BaseButtonProps, ButtonColors, PrimaryButton } from 'src/components';
import { BoxWithHeader } from 'src/components/BoxWithHeader';
import { LoadingIndicator } from 'src/components/LoadingIndicator';
import { StyledText } from 'src/components/StyledText';
import { QueryErrorBanner } from 'src/errorHandling/components';
import { getErrorMessageForApiError } from 'src/errorHandling/utils';
import { showNotification } from 'src/helpers';
import { i18n } from 'src/locale';
import { palette } from 'src/styles';

import { PaymentDetailsFooter } from './PaymentDetailsFooter';
import { PaymentDetailsForm, PaymentDetailsRef } from './PaymentDetailsForm';
import {
  useSubscriptionRenewalModal,
  useBillingInformation,
  useUpdateBillingInformation,
  useCancelSubscriptionRenewal,
  useSubscriptionInfo,
} from '../../hooks';
import { CancelRenewalModal } from '../modals/CancelRenewalModal';
import { ReactivateRenewalModal } from '../modals/ReactivateRenewalModal';

export const StandalonePaymentDetailsForm: React.FC = () => {
  const { data: subscriptionInfo } = useSubscriptionInfo();
  const { data: billingInformation, isLoading, fetchStatus, error } = useBillingInformation();
  const { mutateAsync: updateBillingInformation } = useUpdateBillingInformation();
  const { mutate: cancelSubscriptionRenewal, isPending: isCancelling } = useCancelSubscriptionRenewal();

  const paymentDetailsRef = useRef<PaymentDetailsRef>(null);
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [cancelConfirmationModal, setCancelConfirmationModal] = useState(false);

  const showWarningModal = useCallback(() => {
    setCancelConfirmationModal(true);
  }, []);

  const closeWarningModal = useCallback(() => {
    setCancelConfirmationModal(false);
  }, []);

  const handleBillingInfoUpdate = useCallback(async () => {
    try {
      const { token } = await paymentDetailsRef.current!.submit();
      await updateBillingInformation(token.id);
      showNotification({ type: 'success' });
    } catch (error: any) {
      const detailError = error?.response?.data?.detail;
      if (detailError) {
        showNotification({
          type: 'error',
          title: getErrorMessageForApiError(detailError),
          autoHide: false,
        });
      } else {
        showNotification({ type: 'error' });
      }
      throw error;
    }
  }, [updateBillingInformation]);

  const toggleEditMode = useCallback(async () => {
    if (editMode) {
      try {
        setSaving(true);
        await handleBillingInfoUpdate();
        setEditMode(false);
      } catch (e) {
      } finally {
        setSaving(false);
      }
    } else {
      setEditMode(true);
    }
  }, [editMode, handleBillingInfoUpdate]);

  const handleSubscriptionRenewalCancellation = useCallback(async () => {
    cancelSubscriptionRenewal(undefined, {
      onSuccess: () => {
        closeWarningModal();
      },
    });
  }, [closeWarningModal, cancelSubscriptionRenewal]);

  const { showReactivateConfirmation, reactivateModal } =
    useSubscriptionRenewalModal(ReactivateRenewalModal);

  if (isLoading) {
    return (
      <BoxWithHeader title={i18n.t('subscriptionDetails:paymentDetails')}>
        <LoadingIndicator style={styles.spinner} displayStandbyText />
      </BoxWithHeader>
    );
  }

  const getFooterButton = (): BaseButtonProps | undefined => {
    if (!subscriptionInfo?.autoRenew) return undefined;

    if (subscriptionInfo?.isCancelled && !!billingInformation?.creditCardInformation)
      return {
        title: i18n.t('subscriptionDetails:reactivateRenewal'),
        variant: 'secondary',
        size: 'medium',
        onPress: showReactivateConfirmation,
        testID: 'button-reactivate-renewal',
      };

    if (!subscriptionInfo.isCancelled)
      return {
        title: i18n.t(`cancelRenewal:${subscriptionInfo.inTrial ? 'cancelTrial' : 'cancelRenewal'}`),
        variant: 'secondary',
        size: 'medium',
        colors: cancelButtonColors,
        onPress: showWarningModal,
        testID: 'button-cancel-renewal',
      };
  };

  return (
    <BoxWithHeader
      title={i18n.t('subscriptionDetails:paymentDetails')}
      subtitleContent={<StyledText>{i18n.t('subscriptionDetails:paymentDetailsSubtitle')}</StyledText>}
      rightColumnHeaderContent={
        subscriptionInfo && (
          <PrimaryButton
            title={i18n.t(editMode ? 'saveChanges' : 'editDetails')}
            size="medium"
            onPress={toggleEditMode}
            loading={saving}
            testID="edit-details-button"
          />
        )
      }
      contentHeaderStyle={styles.boxHeader}
      testID="box-payment-details"
    >
      <QueryErrorBanner
        error={error}
        fetchStatus={fetchStatus}
        isDataAvailable={!!billingInformation}
        errorComponentType="message"
        style={styles.error}
      />
      {subscriptionInfo && (
        <>
          <PaymentDetailsForm
            detailsData={billingInformation}
            ref={paymentDetailsRef}
            disabled={!editMode}
            billingAddressInfo={i18n.t('subscriptionDetails:billingAddressTaxInfoRenewal')}
          />
          <PaymentDetailsFooter buttonProps={getFooterButton()} />
          {cancelConfirmationModal && (
            <CancelRenewalModal
              planSetup={subscriptionInfo.planSetup}
              subscriptionStatus={subscriptionInfo}
              cancel={closeWarningModal}
              confirm={handleSubscriptionRenewalCancellation}
              isLoading={isCancelling}
            />
          )}
          {reactivateModal}
        </>
      )}
    </BoxWithHeader>
  );
};

const cancelButtonColors: ButtonColors = {
  default: { primary: palette.red, content: palette.red },
  active: { primary: palette.darkRed, content: palette.darkRed },
  disabled: { primary: palette.grey2, secondary: palette.grey3, content: palette.white },
};

const styles = StyleSheet.create({
  boxHeader: {
    alignItems: 'flex-start',
  },
  spinner: {
    height: 100,
    justifyContent: 'center',
  },
  error: {
    marginTop: 30,
  },
});
