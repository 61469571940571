import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { PlanDetailsBox } from './PlanDetailsBox';
import { useSubscriptionInfo } from '../../hooks';
import { BillingPeriod } from '../../types';

interface Props {
  boxProps?: Partial<ComponentProps<typeof PlanDetailsBox>>;
}

export const BillingCycleBox: React.FC<Props> = ({ boxProps }) => {
  const { t } = useTranslation('subscriptionDetails');
  const { data } = useSubscriptionInfo();

  const isAnnual = data?.planSetup.billingPeriod === BillingPeriod.ANNUAL;

  return (
    <PlanDetailsBox
      title={t('billingCycle')}
      subtitle={t(isAnnual ? 'annual' : 'monthly')}
      {...boxProps}
      testID="mini-box-billing-cycle"
    />
  );
};
