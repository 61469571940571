import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { signOutSuccess } from 'src/features/auth/state';
import { PromotionLandingScreenParams, PromotionSetup } from 'src/features/subscription';

type PromotionType = PromotionSetup['campaignUserType'] | null;

type PromotionState = {
  params: PromotionLandingScreenParams | null;
  promotionType: PromotionType;
};

const initialState: PromotionState = {
  params: null,
  promotionType: null,
};

const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    setPromotionParams(state, action) {
      state.params = action.payload;
    },
    clearPromotionParams(state) {
      state.params = null;
      state.promotionType = null;
    },
    setPromotionType(state, action: PayloadAction<PromotionType>) {
      state.promotionType = action.payload;
    },
    clearPromotionType(state) {
      state.promotionType = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOutSuccess, (state) => {
      if (state.promotionType !== 'new') {
        state.promotionType = null;
        state.params = null;
      }
    });
  },
});

export const { setPromotionParams, clearPromotionParams, setPromotionType, clearPromotionType } =
  promotionSlice.actions;

const persistConfig: PersistConfig<PromotionState> = {
  key: 'promotion',
  storage: AsyncStorage,
};

export const promotionReducer = persistReducer(persistConfig, promotionSlice.reducer);
