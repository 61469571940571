import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

import { useIsSignedIn, useUserInfo } from 'src/features/auth/hooks';
import { getBasePlanSetup, getRecurlyStudentPlan, isStudentRole } from 'src/features/subscription/helpers';
import { RecurlyPlan, RecurlyStudentPlan, Role, StudentRole } from 'src/features/subscription/types';
import {
  MixpanelEvent,
  MixpanelService,
  MixpanelStudentVerificationParams,
  SentryService,
  getMixpanelStudentVerificationStatus,
} from 'src/features/tracking';
import { useRoute } from 'src/navigation/hooks';

import { useCurrentUserStudentVerification, useStudentVerificationDetails } from '../hooks/queries';
import type {
  StudentVerficationStatus,
  StudentVerificationParams,
  StudentVerificationStep,
} from '../types';

type StateByRole<T> = {
  [Role.VETERINARY_STUDENT]: T;
  [Role.PHARMACY_STUDENT]: T;
};

export interface StudentDataSubmitParams {
  data: StudentVerificationParams;
  verificationId: string;
  status: StudentVerficationStatus;
  ssoLoginUrl: string | null;
}

type ProcessType = 'new-subscription' | 'reverification';

interface ContetextValue {
  setRole(role: Role): void;
  currentStep?: StudentVerificationStep;
  verificationId?: string;
  plan: RecurlyStudentPlan | null;
  studentData?: StudentVerificationParams | null;
  ssoLoginUrl?: string | null;
  isStudentVerified: boolean;
  isUserRedirectedFromEmailNotification: boolean;
  isFetching: boolean;
  processType: ProcessType;

  onSSOExternalLoginSitePress(): void;
  onSSOProcessSuccess(): void;
  onSSOProcessSkip(): void;
  onDocumentsUpload(): void;
  onDocumentsUploadSuccess(): void;
  onDocumentsUploadFailure(): void;
  onDocumentsLimitReached(): void;
  onStudentDataSubmit(params: StudentDataSubmitParams): void;
  onEmailTokenVerificationSuccess(): void;
  onEmailTokenVerificationSkip(): void;
}

type RouteParams = { verificationId?: string };

const StudentVerificationContext = createContext<ContetextValue>({} as ContetextValue);

interface Props extends PropsWithChildren {
  processType?: ProcessType;
}

export const StudentVerificationProvider: React.FC<Props> = ({
  children,
  processType = 'new-subscription',
}) => {
  const isSignedIn = useIsSignedIn();
  const { planCode } = useUserInfo();
  const routeParams = (useRoute().params || {}) as RouteParams;
  const { data: verificationDetails, isFetching: isVerificationDetailsFetching } =
    useStudentVerificationDetails(routeParams.verificationId);
  const { data: currentUserVerificationDetails, isFetching: isCurrentUserVerificationDetailsFetching } =
    useCurrentUserStudentVerification();

  const [studentRole, setStudentRole] = useState<StudentRole | undefined>(getInitialRole(planCode));

  const [studentData, setStudentData] = useState<StateByRole<StudentVerificationParams | null>>({
    [Role.PHARMACY_STUDENT]: null,
    [Role.VETERINARY_STUDENT]: null,
  });
  const [verificationId, setVerificationId] = useState<StateByRole<string | undefined>>({
    [Role.PHARMACY_STUDENT]: undefined,
    [Role.VETERINARY_STUDENT]: undefined,
  });
  const [currentStep, setCurrentStep] = useState<StateByRole<StudentVerificationStep>>({
    [Role.VETERINARY_STUDENT]: 'student-data',
    [Role.PHARMACY_STUDENT]: 'student-data',
  });
  const [ssoLoginUrl, setSsoLoginUrl] = useState<StateByRole<string | null>>({
    [Role.VETERINARY_STUDENT]: null,
    [Role.PHARMACY_STUDENT]: null,
  });

  const getMixpanelProperties = (
    data: StudentVerificationParams | null = studentRole ? studentData[studentRole] : null,
    role: StudentRole | undefined = studentRole,
  ): MixpanelStudentVerificationParams | undefined => {
    if (data && role) {
      return {
        'Birth Date': data.birthDate,
        'Email Address': data.email,
        'Student Email Address': data.studentEmail,
        'First Name': data.firstName,
        'Last Name': data.lastName,
        University: data.organizationName,
        'Net New Subscriber': !isSignedIn,
        Country: data.country,
        Role: role === Role.PHARMACY_STUDENT ? 'Pharmacy Student' : 'Veterinary Student',
      };
    }
  };

  useEffect(() => {
    if (currentUserVerificationDetails) {
      const { pharmacyBasicStudent, veterinaryProStudentAnnual } = currentUserVerificationDetails;
      setVerificationId({
        [Role.PHARMACY_STUDENT]: pharmacyBasicStudent.verificationId || '',
        [Role.VETERINARY_STUDENT]: veterinaryProStudentAnnual.verificationId || '',
      });
      setSsoLoginUrl({
        [Role.PHARMACY_STUDENT]: pharmacyBasicStudent.data.loginUrl,
        [Role.VETERINARY_STUDENT]: veterinaryProStudentAnnual.data.loginUrl,
      });
      setCurrentStep({
        [Role.PHARMACY_STUDENT]: getStepByVerificationStatus(pharmacyBasicStudent.data.status),
        [Role.VETERINARY_STUDENT]: getStepByVerificationStatus(veterinaryProStudentAnnual.data.status),
      });
    }
  }, [currentUserVerificationDetails]);

  useEffect(() => {
    if (verificationDetails) {
      const { status, loginUrl, ...studentData } = verificationDetails;
      const role = getBasePlanSetup(studentData.planCode).role as StudentRole;
      setVerificationId((state) => ({
        ...state,
        [role]: routeParams.verificationId,
      }));

      setSsoLoginUrl((state) => ({
        ...state,
        [role]: loginUrl,
      }));

      setCurrentStep((state) => ({
        ...state,
        [role]: getStepByVerificationStatus(status),
      }));

      setStudentData((state) => ({
        ...state,
        [role]: studentData,
      }));

      MixpanelService.track(MixpanelEvent.StudentEmailNotificationRedirect, {
        ...getMixpanelProperties(studentData, role)!,
        'Verification Status': getMixpanelStudentVerificationStatus(status),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationDetails]);

  const onDocumentsUpload = () => {
    if (studentRole) {
      setCurrentStep((state) => ({
        ...state,
        [studentRole]: 'pending',
      }));
      MixpanelService.track(MixpanelEvent.StudentDocumentUpload, getMixpanelProperties());
    }
  };

  const onDocumentsUploadFailure = () => {
    if (studentRole) {
      setCurrentStep((state) => ({
        ...state,
        [studentRole]: 'documents',
      }));
      MixpanelService.track(MixpanelEvent.StudentDocumentsVerificationFailure, getMixpanelProperties());
    }
  };

  const onDocumentsUploadSuccess = () => {
    if (studentRole) {
      setCurrentStep((state) => ({
        ...state,
        [studentRole]: 'verified',
      }));
      MixpanelService.track(MixpanelEvent.StudentDocumentsVerificationSuccess, getMixpanelProperties());
    }
  };

  const onDocumentsLimitReached = () => {
    if (studentRole) {
      setCurrentStep((state) => ({
        ...state,
        [studentRole]: 'student-data',
      }));
      setStudentData((state) => ({
        ...state,
        [studentRole]: null,
      }));
      setVerificationId((state) => ({
        ...state,
        [studentRole]: '',
      }));
      MixpanelService.track(MixpanelEvent.StudentDocumentsUploadLimitReached, getMixpanelProperties());
    }
  };

  const onStudentDataSubmit = (params: StudentDataSubmitParams) => {
    const { status, data, ssoLoginUrl, verificationId } = params;

    if (studentRole) {
      setStudentData((state) => ({
        ...state,
        [studentRole]: data,
      }));
      setVerificationId((state) => ({
        ...state,
        [studentRole]: verificationId,
      }));
      setCurrentStep((state) => ({
        ...state,
        [studentRole]: getStepByVerificationStatus(status),
      }));
      setSsoLoginUrl((state) => ({
        ...state,
        [studentRole]: ssoLoginUrl,
      }));

      if (status === 'success') {
        MixpanelService.track(MixpanelEvent.StudentDataFormSuccess, getMixpanelProperties(data)!);
      } else {
        MixpanelService.track(MixpanelEvent.StudentDataFormFailure, {
          ...getMixpanelProperties(data)!,
          'Next Student Verification Step':
            status === 'docUpload' ? 'Documents Upload' : status === 'sso' ? 'SSO' : undefined,
        });
      }
    }
  };

  const onSSOProcessSuccess = () => {
    if (studentRole) {
      setCurrentStep((state) => ({
        ...state,
        [studentRole]: 'verified',
      }));
      setSsoLoginUrl((state) => ({
        ...state,
        [studentRole]: null,
      }));
      MixpanelService.track(MixpanelEvent.StudentSSOProcessSuccess, getMixpanelProperties());
    }
  };

  const onSSOExternalLoginSitePress = () => {
    if (studentRole) {
      setCurrentStep((state) => ({
        ...state,
        [studentRole]: 'sso-pending',
      }));
      MixpanelService.track(MixpanelEvent.StudentSSOProcessStarted, getMixpanelProperties());
    }
  };

  const onSSOProcessSkip = () => {
    if (studentRole) {
      setCurrentStep((state) => ({
        ...state,
        [studentRole]: 'documents',
      }));
      MixpanelService.track(MixpanelEvent.StudentSSOProcessSkipped, getMixpanelProperties());
    }
  };

  const onEmailTokenVerificationSuccess = () => {
    if (studentRole) {
      setCurrentStep((state) => ({
        ...state,
        [studentRole]: 'verified',
      }));
      MixpanelService.track(MixpanelEvent.StudentEmailLoopProcessSuccess, getMixpanelProperties());
    }
  };

  const onEmailTokenVerificationSkip = () => {
    if (studentRole) {
      setCurrentStep((state) => ({
        ...state,
        [studentRole]: 'documents',
      }));
      MixpanelService.track(MixpanelEvent.StudentEmailLoopProcessSkipped, getMixpanelProperties());
    }
  };

  const setRole = (role: Role) => {
    setStudentRole(isStudentRole(role) ? role : undefined);
  };

  return (
    <StudentVerificationContext.Provider
      value={{
        currentStep: studentRole && currentStep[studentRole],
        isStudentVerified: !!studentRole && currentStep[studentRole] === 'verified',
        ssoLoginUrl: studentRole && ssoLoginUrl[studentRole],
        isUserRedirectedFromEmailNotification: !!routeParams.verificationId,
        isFetching: isVerificationDetailsFetching || isCurrentUserVerificationDetailsFetching,
        verificationId: studentRole && verificationId[studentRole],
        plan: studentRole ? getRecurlyStudentPlan(studentRole) : null,
        studentData: studentRole && studentData[studentRole],
        onDocumentsLimitReached,
        onDocumentsUpload,
        onDocumentsUploadFailure,
        onDocumentsUploadSuccess,
        onSSOProcessSuccess,
        onSSOExternalLoginSitePress,
        onSSOProcessSkip,
        onStudentDataSubmit,
        onEmailTokenVerificationSuccess,
        onEmailTokenVerificationSkip,
        setRole,
        processType,
      }}
    >
      {children}
    </StudentVerificationContext.Provider>
  );
};

export const useStudentVerificationContext = () => useContext(StudentVerificationContext);

const getStepByVerificationStatus = (status?: StudentVerficationStatus): StudentVerificationStep => {
  switch (status) {
    case 'docUpload':
      return 'documents';
    case 'success':
      return 'verified';
    case 'sso':
      return 'sso';
    case 'pending':
      return 'pending';
    case 'ssoPending':
      return 'sso-pending';
    case 'emailLoop':
      return 'email-loop';
    case 'started':
    case 'failed':
    case 'expired':
      return 'student-data';
    default: {
      if (status) {
        SentryService.captureException("Couldn't recognize student verification status", {
          status,
        });
      }
      return 'student-data';
    }
  }
};

const getInitialRole = (planCode: RecurlyPlan | null): StudentRole => {
  if (planCode) {
    const { role } = getBasePlanSetup(planCode);
    if (isStudentRole(role)) {
      return role;
    }
  }
  return Role.VETERINARY_STUDENT;
};
