import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { Icon } from 'src/components/Icon/Icon';
import { StyledText } from 'src/components/StyledText';
import { IconName } from 'src/constants/types';
import { useCountriesList } from 'src/hooks/queries/countries';
import { getShadow, palette, typography } from 'src/styles';

import { getCreditCardNumberPlaceholder } from '../../helpers';
import { BillingInformation, CardType } from '../../types';

interface Props {
  billingInformation: BillingInformation;
}

const getCardIconName = (type: CardType): IconName | null => {
  switch (type) {
    case CardType.AmericanExpress:
      return 'american';
    case CardType.MasterCard:
      return 'masterCard';
    case CardType.Discover:
      return 'discover';
    case CardType.Visa:
      return 'visa';
    default:
      return null;
  }
};

export const PaymentDetails: React.FC<Props> = ({ billingInformation }) => {
  const { t } = useTranslation('subscriptionDetails');
  const { getCountryFullName, getStateFullName } = useCountriesList();

  const { creditCardInformation, billingAddress } = billingInformation;
  const cardIconName = getCardIconName(creditCardInformation.cardType);

  return (
    <View style={styles.wrapper} testID="payment-details-info-box">
      <View style={styles.column} testID="cc-info-box">
        <View style={styles.headerWrapper}>
          <StyledText style={[styles.header, typography.body3Bold]}>
            {t('creditCardInformation')}
          </StyledText>
          <Icon name="padlock" width={16} />
        </View>
        <View style={styles.box}>
          <View style={styles.ccNumberRow}>
            <StyledText style={[typography.body2Bold]}>
              {getCreditCardNumberPlaceholder(
                creditCardInformation.lastFour,
                creditCardInformation.cardType,
              )}
            </StyledText>
            {cardIconName && <Icon name={cardIconName} style={styles.cardIcon} />}
          </View>
          <StyledText style={[typography.body2Bold]}>
            {creditCardInformation.expMonth}/{creditCardInformation.expYear}
          </StyledText>
          <StyledText style={[typography.body2Bold]}>
            {creditCardInformation.firstName.toUpperCase()} {creditCardInformation.lastName.toUpperCase()}
          </StyledText>
        </View>
      </View>
      <View testID="billing-address-box">
        <View style={styles.headerWrapper}>
          <StyledText style={[styles.header, typography.body3Bold]}>{t('billingAddress')}</StyledText>
        </View>
        {[
          [t('address'), [billingAddress.address1, billingAddress.address2].filter((v) => !!v).join(', ')],
          [t('city'), billingAddress.city],
          [t('zipCode'), billingAddress.postalCode],
          [t('country'), getCountryFullName(billingAddress.country)],
          !!billingAddress.state && [
            t('state'),
            getStateFullName(billingAddress.country, billingAddress.state),
          ],
        ]
          .filter((value): value is string[] => !!value)
          .map(([label, value]) => (
            <StyledText style={[typography.body2]} key={label}>
              <StyledText style={typography.weightBold}>{label}:</StyledText>
              {' ' + value}
            </StyledText>
          ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  column: {
    marginRight: 32,
    maxWidth: '100%',
    minWidth: 200,
    marginBottom: 10,
  },
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  header: {
    color: palette.blue,
    marginRight: 10,
  },
  box: {
    backgroundColor: palette.white,
    borderRadius: 8,
    ...getShadow(),
    padding: 20,
    paddingBottom: 24,
    alignSelf: 'flex-start',
  },
  ccNumberRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  cardIcon: {
    marginLeft: 20,
  },
});
