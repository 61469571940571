import { ComponentProps } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { StyledText } from 'src/components/StyledText';
import { palette } from 'src/styles';

import { PlanDetailsBox } from './PlanDetailsBox';
import { useSubscriptionInfo } from '../../hooks';
import { BillingPeriod } from '../../types';

interface Props {
  boxProps?: Partial<ComponentProps<typeof PlanDetailsBox>>;
}

export const SubscriptionTermBox: React.FC<Props> = ({ boxProps }) => {
  const { t } = useTranslation('subscriptionDetails');
  const { data } = useSubscriptionInfo();

  const { remainingBillingCycles: remaining, totalBillingCycles: total } = data!;
  const isMonthly = data?.planSetup.billingPeriod === BillingPeriod.MONTHLY;

  return (
    <PlanDetailsBox
      title={t('subscriptionTerm')}
      subtitle={t('numMonths', { count: 12 })}
      {...boxProps}
      testID="mini-box-subscription-term"
    >
      {isMonthly && (
        <StyledText style={styles.details} testID="payments-remaining">
          <Trans
            i18nKey="subscriptionDetails:paymentsRemaining"
            values={{ remaining, total }}
            components={{ b: <StyledText style={[styles.details, styles.boldText]} /> }}
          />
        </StyledText>
      )}
    </PlanDetailsBox>
  );
};

const styles = StyleSheet.create({
  details: {
    color: palette.grey5,
    lineHeight: 20,
  },
  boldText: {
    fontWeight: 'bold',
  },
});
