import { CSSProperties } from 'react';
import { Carousel as RCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { palette } from 'src/styles';

import { Carousel as BaseComponent } from './Carousel';

type Props = React.ComponentProps<typeof BaseComponent>;

export const Carousel: React.FC<Props> = ({ items }) => {
  return (
    <RCarousel
      swipeable
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      infiniteLoop
      emulateTouch
      autoPlay
      interval={8000}
      renderIndicator={(onClick, isActive) =>
        items.length > 1 && (
          <li
            onClick={onClick}
            style={Object.assign({}, style.indicator, isActive && style.indicatorActive)}
          />
        )
      }
    >
      {items}
    </RCarousel>
  );
};

const style: Record<string, CSSProperties> = {
  indicator: {
    width: 10,
    height: 10,
    backgroundColor: palette.white,
    opacity: 0.4,
    borderRadius: 10,
    marginLeft: 5,
    marginRight: 5,
    display: 'inline-block',
    cursor: 'pointer',
    marginBottom: 17,
  },
  indicatorActive: {
    opacity: 1,
    width: 10,
    height: 10,
  },
};
