import { ReactElement } from 'react';
import { StyleSheet, View } from 'react-native';

import { Select } from 'src/components/Select';
import { TilesSelector } from 'src/components/TilesSelector/TilesSelector';
import { TilesSelectorOption } from 'src/constants/types';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { ifWeb } from 'src/styles';

export type SelectorOption<T> = {
  label: string;
  value: T;
  description?: string;
  testID?: string;
};

type SelectorProps<T> = {
  onPress?: (value: T) => void;
  selected: T;
  options: TilesSelectorOption<T>[];
  label?: string;
  disabled?: boolean;
  testID?: string;
};

export const SelectorsSection = <T extends string>({
  onPress,
  selected,
  options,
  label,
  disabled,
  testID,
}: SelectorProps<T>): ReactElement<any, any> | null => {
  const { isTablet } = useDeviceInfo();

  return (
    <View style={styles.wrapper} testID={testID}>
      {isTablet ? (
        <TilesSelector
          onPress={onPress}
          selected={selected}
          options={options}
          disabled={disabled}
          fullWidth
        />
      ) : (
        <Select
          touched
          label={label}
          options={options}
          value={selected}
          onChange={({ value }) => {
            value && onPress?.(value as T);
          }}
          readOnly={disabled}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: 24,
    ...ifWeb({
      zIndex: 'auto',
    }),
  },
});
