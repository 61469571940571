import { MAIN_NOTE_SECTION_ID, NEW_GENERAL_NOTE_PLACEHOLDER_ID } from './constants';
import {
  ContentNote,
  Note,
  NotesUrlConfig,
  NoteEditorProps,
  ContentNoteEditorProps,
  PartialContentNote,
  PartialNote,
  ExisitingNoteCardProps,
  NewNoteCardProps,
} from './types';
import { MixpanelNoteParams } from '../tracking';

export function isSavedNote(
  note: PartialContentNote | ContentNote | PartialNote | Note,
): note is ContentNote | Note {
  return 'id' in note;
}

export function isContentNote(note: Note | ContentNote): note is ContentNote {
  return (note as ContentNote).contentId !== undefined;
}

export const isContentNoteEditorProps = (props: NoteEditorProps): props is ContentNoteEditorProps =>
  'contentId' in props;

export const getNotesApiUrl = ({ contentId, noteId }: NotesUrlConfig = {}) =>
  ['/notes', contentId && `/contents/${contentId}`, noteId && `/${noteId}`, '/'].filter(Boolean).join('');

export const isNewGeneralNotePlaceholder = (note: Note | ContentNote) =>
  note.id === NEW_GENERAL_NOTE_PLACEHOLDER_ID;

export const isExistingNoteProps = (
  props: ExisitingNoteCardProps | NewNoteCardProps,
): props is ExisitingNoteCardProps => props.type === 'existing';

export const getMixpanelNoteParams = (
  contentNote?: Pick<ContentNote, 'contentId' | 'contentTitle' | 'title' | 'section'>,
): MixpanelNoteParams => {
  return {
    'Drug ID': contentNote?.contentId,
    'Drug Title': contentNote?.contentTitle,
    'Note Location':
      contentNote && contentNote.section !== MAIN_NOTE_SECTION_ID ? contentNote.title : 'General',
  };
};
