import { useEffect, useLayoutEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { SubscriptionFormSource } from 'src/features/adminDashboard';
import { useUserInfo } from 'src/features/auth/hooks';
import { closeNotification } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { Route } from 'src/navigation';
import { useNavigation } from 'src/navigation/hooks';
import { NavigationProp } from 'src/navigation/types';

import { StandalonePaymentDetailsForm } from './PaymentDetails/StandalonePaymentDetailsForm';
import { PaymentHistory } from './PaymentHistory/PaymentHistory';
import { PlanBenefits } from './PlanBenefits/PlanBenefits';
import { PlanDetails } from './PlanDetails/PlanDetails';
import { SubscriptionPurchaseForm } from './SubscriptionPurchaseForm/SubscriptionPurchaseForm';
import { getBasePlanSetup, getPlanDetailsFromPromotion } from '../helpers';
import { usePromotion, useSubscriptionInfo } from '../hooks';
import { BillingPeriod, Plan, Role, PlanSetup } from '../types';

export const SubscriptionDetails: React.FC = () => {
  const [formProps, setFormProps] = useState<{
    data: PlanSetup;
    buyingNewSubscription: boolean;
  } | null>(null);

  const { data, isFetching: isSubscriptionInfoFetching } = useSubscriptionInfo({
    refetchOnMount: 'always',
  });
  const userInfo = useUserInfo();
  const { isLargeDesktop } = useDeviceInfo();
  const navigation = useNavigation<NavigationProp<Route.AdminDashboard>>();
  const { data: promotion, isLoading: isPromotionLoading, params: promotionParams } = usePromotion();

  const isDataLoading = isSubscriptionInfoFetching || (promotionParams ? isPromotionLoading : false);

  useEffect(() => closeNotification, []);

  const handleAddMoreUsers = () => {
    setFormProps({
      data: {
        ...data!.planSetup,
        quantity: data!.numberOfLicences + 1,
        discountCode: data!.couponCode,
      },
      buyingNewSubscription: false,
    });
  };

  const handleUpgradeToPro = (source: SubscriptionFormSource) => {
    navigation.setParams({ formSource: source });
    setFormProps({
      data: data?.promotion
        ? getPlanDetailsFromPromotion(data.promotion)
        : {
            ...data!.planSetup,
            quantity: data!.numberOfLicences,
            discountCode: data!.couponCode,
            plan: Plan.PRO,
          },
      buyingNewSubscription: false,
    });
  };

  const handleReactivateSubscription = () => {
    setFormProps({
      data: {
        quantity: data?.numberOfLicences ?? 1,
        billingPeriod: data?.planSetup.billingPeriod ?? BillingPeriod.ANNUAL,
        discountCode: data?.couponCode ?? '',
        plan: data?.planSetup.plan ?? Plan.NORMAL,
        role: data?.planSetup.role ?? Role.VETERINARY,
      },
      buyingNewSubscription: true,
    });
  };

  useLayoutEffect(() => {
    if (promotion && !data?.promotion && !isDataLoading) {
      const predefinedData: Partial<PlanSetup> = {};
      if (data) {
        const { billingPeriod, plan } = getBasePlanSetup(data.planCode);
        if (plan === Plan.PRO) {
          predefinedData.billingPeriod = billingPeriod;
        }
      }
      setFormProps({
        data: getPlanDetailsFromPromotion(promotion, predefinedData),
        buyingNewSubscription: userInfo.expiredSubscription,
      });
    }
  }, [promotion, data, userInfo.expiredSubscription, isDataLoading]);

  const closeForm = () => {
    setFormProps(null);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const isFormOpen = !!formProps;
  const itemStyle = [styles.item, !isLargeDesktop && styles.itemMobile];

  return (
    <View style={[styles.wrapper, !isLargeDesktop && styles.wrapperMobile]}>
      <View style={itemStyle}>
        <PlanDetails
          handleAddMoreUsers={handleAddMoreUsers}
          handleUpgradeToPro={() => handleUpgradeToPro('plan-type')}
          handleReactivateSubscription={handleReactivateSubscription}
          hideActionButtons={isFormOpen}
        >
          {isFormOpen && !isDataLoading && (
            <SubscriptionPurchaseForm
              data={formProps.data}
              buyingNewSubscription={formProps.buyingNewSubscription}
              close={closeForm}
            />
          )}
        </PlanDetails>
      </View>
      <View style={isLargeDesktop && styles.row}>
        <View style={[itemStyle, isLargeDesktop && styles.rowItemDesktop]}>
          <PlanBenefits
            handleUpgradeToPro={() => handleUpgradeToPro('plan-benefits')}
            handleReactivateSubscription={handleReactivateSubscription}
            hideActionButtons={isFormOpen}
          />
        </View>
        <View style={[itemStyle, isLargeDesktop && styles.rowItemDesktop]}>
          <PaymentHistory />
        </View>
      </View>
      {!userInfo.expiredSubscription && (
        <View style={itemStyle}>
          <StandalonePaymentDetailsForm />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    margin: -20,
  },
  wrapperMobile: {
    margin: -14,
  },
  item: {
    margin: 20,
  },
  itemMobile: {
    margin: 10,
  },
  row: {
    flexDirection: 'row',
  },
  rowItemDesktop: {
    flex: 1,
  },
});
