import React from 'react';

import { InputItem, Select } from 'src/components';
import { UserFormField } from 'src/constants/types';
import { isWeb } from 'src/helpers/common';
import { i18n } from 'src/locale';

type RecurlyFields = 'city' | 'address1' | 'address2';
interface RenderFieldParams extends UserFormField {
  handleChange: (value: string) => void;
  value: string | undefined;
  error: string | undefined;
  handleSubmit: () => void;
  handleBlur: () => void;
  touched: boolean;
  key: string;
  dataSet?: Record<'recurly', RecurlyFields>;
}

export const renderFormField = ({
  type,
  key,
  handleChange,
  handleSubmit,
  handleBlur,
  error,
  value,
  touched,
  options,
  dataSet,
  testID,
}: RenderFieldParams): React.ReactNode => {
  const label = i18n.t(`user:${key}Label`);
  const placeholderKey = `user:${key}Placeholder`;
  const placeholder = i18n.exists(placeholderKey) ? i18n.t(placeholderKey) : '';
  const descriptionKey = `user:${key}Description`;
  const description = i18n.exists(descriptionKey) ? i18n.t(descriptionKey) : '';
  switch (type) {
    case 'text': {
      return (
        <InputItem
          label={label}
          onChangeText={handleChange}
          placeholder={placeholder}
          value={value}
          error={error}
          touched={touched}
          onSubmitEditing={isWeb ? () => handleSubmit() : undefined}
          onBlur={handleBlur}
          description={description}
          dataSet={dataSet}
          testID={testID}
          autoCapitalize="words"
        />
      );
    }
    case 'password': {
      return (
        <InputItem
          label={label}
          onChangeText={handleChange}
          placeholder={placeholder}
          value={value}
          error={error}
          touched={touched}
          onSubmitEditing={isWeb ? () => handleSubmit() : undefined}
          onBlur={handleBlur}
          secureTextEntry
          blurOnSubmit
          description={description}
          testID={testID}
        />
      );
    }
    case 'select': {
      return (
        <Select
          placeholder={placeholder}
          label={label}
          options={options}
          value={value}
          error={error}
          touched={touched}
          onBlur={handleBlur}
          onChange={({ value }) => {
            value && handleChange(value);
          }}
          testID={testID}
          searchType="internal"
        />
      );
    }
  }
};
