import * as R from 'ramda';
import React, { ReactNode } from 'react';
import { StyleSheet, View, TextStyle } from 'react-native';
import { ASTNode } from 'react-native-markdown-display';

import { convertNumberToRoman, getLetterListItemMarker, isWeb, isIOS } from 'src/helpers';
import { palette, typography, ifWeb } from 'src/styles';

import { StyledText } from '../StyledText';

interface Props {
  node: ASTNode;
  parent: ASTNode[];
  children: ReactNode[];
  markerStyle?: TextStyle;
  ulMarkerStyle?: TextStyle;
}

type ListType = 'ordered_list' | 'bullet_list';

const getListType = (parents: ASTNode[]): ListType | undefined => {
  for (const item of parents) {
    if (item.type === 'ordered_list') return 'ordered_list';
    if (item.type === 'bullet_list') return 'bullet_list';
  }
};

const getLevelItem = (parents: ASTNode[], listType: ListType) =>
  parents.filter((item) => item.type === listType).length;

const getULMarker = (level: number) => ULMarkerLevels[(level - 1) % ULMarkerLevels.length];

const getOLMarker = (level: number, index: number) =>
  OLMarkerLevels[(level - 1) % OLMarkerLevels.length](index);

export const ListItem: React.FC<Props> = (props) => {
  const { parent, node, children, markerStyle, ulMarkerStyle } = props;

  if (getListType(parent) === 'ordered_list') {
    /**
     * If element is an OL, we calculate the number that should
     * be attached to this item
     */

    const level = getLevelItem(parent, 'ordered_list');
    const orderedList = parent.find(R.propEq('type', 'ordered_list'));

    const listStart = orderedList?.attributes.start || 1;
    const listEndIndex = listStart + orderedList?.children.length - 1;

    const listItemNumber = node.index + listStart;

    const allMarkersList: string = Array.from(Array(listEndIndex))
      .map((_, i) => getOLMarker(level, i + 1) + node.markup)
      .join('\n');

    return (
      <View style={styles.itemWrapper} testID="ol-item">
        <View style={styles.marker}>
          <StyledText style={[markerStyle, styles.olMarker, styles.hiddenWidthFiller]}>
            {allMarkersList /* stretches the column to be as wide as the widest number on the list */}
          </StyledText>

          <StyledText style={[markerStyle, styles.olMarker]} singleLine testID="ol-marker">
            {getOLMarker(level, listItemNumber)}
            {node.markup}
          </StyledText>
        </View>
        <View style={styles.listContent}>{children}</View>
      </View>
    );
  } else {
    const level = getLevelItem(parent, 'bullet_list');

    const marker = getULMarker(level);

    return (
      <View style={styles.itemWrapper} testID="ul-item">
        <StyledText
          style={[markerStyle, ulMarkerStyle, styles.marker, styles.ulMarker, marker.style]}
          accessible={false}
          testID="ul-marker"
        >
          {marker.text}
        </StyledText>
        <View style={styles.listContent}>{children}</View>
      </View>
    );
  }
};

ListItem.displayName = 'ListItem';

export const styles = StyleSheet.create({
  itemWrapper: {
    flexDirection: 'row',
  },
  marker: {
    ...ifWeb(
      {
        marginHorizontal: 8,
      },
      {
        marginRight: 8,
      },
    ),
  },
  olMarker: {
    textAlign: 'right',
    ...ifWeb({
      userSelect: 'none',
    }),
  },
  hiddenWidthFiller: {
    height: 0,
    overflow: 'hidden',
    marginTop: 0,
  },
  ulMarker: {
    ...typography.weightBold,
    ...ifWeb({
      userSelect: 'none',
    }),
    color: palette.blue,
  },
  listContent: {
    flex: 1,
  },
});

const OLMarkerLevels: ((num: number) => string)[] = [
  (num) => num.toString(),
  (num) => getLetterListItemMarker(num - 1),
  (num) => convertNumberToRoman(num),
];

const ULMarkerLevels: { text: string; style?: TextStyle }[] = [
  { text: '\u2022' },
  { text: '\u2010' },
  {
    // as long as we use unicode characters, we use different unicode characters & styles for web and mobile
    // in this particular level to make sure the list markers are consistent across platforms
    text: isWeb ? '\u25E6' : '\u26AC',
    style:
      {
        marginTop: (!isWeb && 9) || undefined,
        ...(isIOS ? typography.text3 : typography.text1),
      } || undefined,
  },
];
