import { GroupRole } from './enums';
import type { GroupInfo, GroupInfoCurrentMember } from './types';
import { GroupMemberAccessLevel } from '../adminDashboard/types';

// note that admins are also group members
export const isUserAGroupMember = (groupInfo: GroupInfo | null): groupInfo is GroupInfoCurrentMember => {
  return !!groupInfo && !groupInfo.removed;
};

export const isUserAGroupAdmin = (groupInfo: GroupInfo | null): boolean => {
  return isUserAGroupMember(groupInfo) && groupInfo.role === GroupRole.ADMIN;
};

export const isUserANormalGroupMember = (groupInfo: GroupInfo | null): boolean => {
  return isUserAGroupMember(groupInfo) && groupInfo.role === GroupRole.MEMBER;
};

/** User is a group member with limited access to content */
export const doesUserHaveLimitedProAccess = (groupInfo: GroupInfo | null): boolean => {
  return isUserAGroupMember(groupInfo) && groupInfo.accessLevel === GroupMemberAccessLevel.ProLimited;
};

export const isUserAManagedGroupMember = (
  groupInfo: GroupInfo | null,
): groupInfo is GroupInfoCurrentMember => {
  return isUserAGroupMember(groupInfo) && !!groupInfo.isManagedGroup;
};

export const isUserAManagedGroupAdmin = (groupInfo: GroupInfo | null): boolean => {
  return isUserAManagedGroupMember(groupInfo) && groupInfo.role === GroupRole.ADMIN;
};
