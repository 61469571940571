import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { Icon } from 'src/components/Icon/Icon';
import { StyledText } from 'src/components/StyledText';
import { useUserInfo } from 'src/features/auth/hooks';
import { palette, typography } from 'src/styles';

interface Props {}

export const PlanBenefitsList: React.FC<Props> = () => {
  const { t } = useTranslation('planBenefits');
  const { isPro } = useUserInfo();

  const proItems = [
    t('clinicalMonographs'),
    t('algorithms'),
    t('petOwnerGuides'),
    t('calculatorNotesFavorites'),
    t('drugReference'),
    t('dic'),
  ];

  const basicItems = [
    t('drugReference'),
    t('calculator'),
    t('notesFavorites'),
    t('medicationGuides'),
    t('dic'),
  ];

  const items = isPro ? proItems : basicItems;

  return (
    <>
      {items.map((item) => (
        <View key={item} style={styles.item} testID="item-benefit">
          <Icon name="checkmark-circle-2" width={23} color={palette.blue} style={styles.icon} />
          <StyledText style={typography.body2}>{item}</StyledText>
        </View>
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    marginBottom: 16,
  },
  icon: {
    marginRight: 16,
  },
});
