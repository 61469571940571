import * as R from 'ramda';
import React, { ComponentProps, useEffect } from 'react';
import { StyleSheet } from 'react-native';

import { LoadingIndicator } from 'src/components';
import { useStudentVerificationDetails } from 'src/features/studentVerification/hooks/queries';
import { type StudentVerificationDetails } from 'src/features/studentVerification/types';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { SubscriptionForm } from '../components';
import { DEFAULT_PLAN_VALUES } from '../constants';
import {
  arePlanParamsValid,
  getBasePlanSetup,
  getPlanDetailsFromPromotion,
  getPlanMaxLicenseQuantity,
} from '../helpers';
import { usePromotion } from '../hooks';
import { InitialSignUpParams, PromotionSetup, Plan } from '../types';

type Props = ScreenProps<Route.SignUp>;
type FormProps = ComponentProps<typeof SubscriptionForm>;

const getPromoFormProps = (promotionData?: PromotionSetup): FormProps => ({
  initialPlanSetup: promotionData ? getPlanDetailsFromPromotion(promotionData) : DEFAULT_PLAN_VALUES,
});

const getFormProps = ({ quantity, role, plan, billing, trial }: InitialSignUpParams): FormProps => {
  const initialPlanSetup = R.mergeWith(R.defaultTo, DEFAULT_PLAN_VALUES, {
    role,
    plan,
    billingPeriod: billing,
    quantity: trial ? 1 : quantity && R.clamp(1, getPlanMaxLicenseQuantity(plan || Plan.NORMAL), quantity),
  });

  return {
    initialPlanSetup: arePlanParamsValid(initialPlanSetup) ? initialPlanSetup : DEFAULT_PLAN_VALUES,
    isTrial: trial,
  };
};

const getStudentVerificationFormProps = (data: StudentVerificationDetails): FormProps => {
  const plan = getBasePlanSetup(data.planCode);
  return {
    studentVerificationDetails: data,
    initialPlanSetup: {
      ...plan,
      discountCode: '',
      quantity: 1,
    },
  };
};

export const SubscriptionScreen: React.FC<Props> = ({ route: { params }, navigation }) => {
  const promotion = usePromotion();
  const studentVerification = useStudentVerificationDetails(params?.verificationId);

  useEffect(() => {
    if (studentVerification.isError) {
      // removing ID from URL after unsuccessful fetch
      navigation.replace(Route.SignUp, {});
    }
  }, [studentVerification.isError, navigation]);

  if (promotion.isFetching || studentVerification.isFetching) {
    return <LoadingIndicator style={styles.loading} />;
  }

  const formProps = promotion.data
    ? getPromoFormProps(promotion.data)
    : studentVerification.data
    ? getStudentVerificationFormProps(studentVerification.data)
    : getFormProps(params ?? {});

  return <SubscriptionForm {...formProps} />;
};

const styles = StyleSheet.create({
  loading: {
    height: 300,
  },
});
