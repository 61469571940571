import React, { useCallback } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { PaginationComponentProps } from 'src/constants/types';
import { ifWeb, palette, typography } from 'src/styles';

import { IconButton } from './IconButton';
import { Pressable } from './Pressable';
import { StyledText } from './StyledText';

const pageBufferSize = 2;

interface Props extends PaginationComponentProps {
  style?: StyleProp<ViewStyle>;
}

export const Pagination: React.FC<Props> = ({ currentPage, numberOfPages, onPageChange, style }) => {
  const handlePreviousButtonPress = () => {
    onPageChange?.(currentPage - 1);
  };

  const handleNextButtonPress = () => {
    onPageChange?.(currentPage + 1);
  };

  const handlePagePress = (page: number) => {
    onPageChange?.(page);
  };

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === numberOfPages;

  const getPagesToDisplay = useCallback(() => {
    const pagesToDisplay: number[] = [];
    if (numberOfPages <= 3 + pageBufferSize * 2) {
      return Array.from(Array(numberOfPages)).map((_, i) => i + 1);
    } else {
      let left = Math.max(1, currentPage - pageBufferSize);
      let right = Math.min(currentPage + pageBufferSize, numberOfPages);

      if (currentPage - 1 <= pageBufferSize) {
        right = 1 + pageBufferSize * 2;
      }

      if (numberOfPages - currentPage <= pageBufferSize) {
        left = numberOfPages - pageBufferSize * 2;
      }

      for (let i = left; i <= right; i += 1) {
        pagesToDisplay.push(i);
      }

      if (left !== 1) {
        pagesToDisplay.unshift(1);
      }
      if (right !== numberOfPages) {
        pagesToDisplay.push(numberOfPages);
      }
    }

    return pagesToDisplay;
  }, [currentPage, numberOfPages]);

  if (numberOfPages < 2) {
    return null;
  }

  const pages = getPagesToDisplay();

  return (
    <View style={[styles.wrapper, style]}>
      <IconButton
        name="chevron-left"
        onPress={handlePreviousButtonPress}
        disabled={isFirstPage}
        width={6}
        onHoverColor={palette.blue2}
        containerStyle={styles.prevButton}
      />
      {pages.map((page, index) => {
        const displaySeparator = page !== 1 && page - pages[index - 1] > 1;
        const isActive = page === currentPage;

        return (
          <React.Fragment key={page}>
            {displaySeparator && <Separator />}
            <Pressable onPress={() => handlePagePress(page)}>
              {(isHovered) => (
                <View style={[styles.button, isActive && styles.buttonActive]}>
                  <StyledText
                    style={[
                      typography.body1SemiBold,
                      styles.buttonText,
                      isActive && styles.buttonActiveText,
                      isHovered && !isActive && styles.buttonHoverText,
                    ]}
                  >
                    {page}
                  </StyledText>
                </View>
              )}
            </Pressable>
          </React.Fragment>
        );
      })}
      <IconButton
        name="chevron-right"
        onPress={handleNextButtonPress}
        disabled={isLastPage}
        width={6}
        onHoverColor={palette.blue2}
        containerStyle={styles.nextButton}
      />
    </View>
  );
};

const Separator = () => {
  return (
    <View style={styles.separator}>
      <StyledText style={styles.separatorDots}>•••</StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    width: 26,
    height: 26,
    borderRadius: 13,
    alignItems: 'center',
    justifyContent: 'center',
    ...ifWeb({
      transitionProperty: 'background-color',
    }),
  },
  prevButton: {
    marginRight: 15,
  },
  nextButton: {
    marginLeft: 15,
  },
  buttonActive: {
    backgroundColor: palette.blue,
  },
  buttonText: {
    color: palette.blue,
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  buttonActiveText: {
    color: palette.white,
  },
  buttonHoverText: {
    color: palette.blue2,
  },
  separator: {
    width: 26,
    height: 26,
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },
  separatorDots: {
    color: palette.grey2,
  },
});
