import dayjs from 'dayjs';
import { forwardRef, MutableRefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { Popover } from 'src/components/Popovers/Popover';
import { StyledText } from 'src/components/StyledText';
import { TRIAL_DURATION_IN_DAYS } from 'src/constants/constants';
import { MixpanelEvent, MixpanelService } from 'src/features/tracking';
import { formatDateWithMonths } from 'src/helpers';
import { i18n } from 'src/locale';
import { ifWeb, palette, typography } from 'src/styles';

import { PaymentDetailsFooter } from './PaymentDetailsFooter';
import { PaymentDetailsForm, PaymentDetailsRef, Props as PaymentDetailsProps } from './PaymentDetailsForm';
import { SubscriptionBaseProps } from '../../types';
import { SubscriptionSection as Section } from '../SubscriptionSections';

interface Props extends SubscriptionBaseProps, PaymentDetailsProps {
  isLoading?: boolean;
  dispatchMixpanelEvent?: boolean;
  isTrial?: boolean;
}

export const PaymentDetailsFormStep = forwardRef<PaymentDetailsRef, Props>(
  (
    {
      submit,
      active,
      isLoading,
      isSubmitted,
      onDirtyChange,
      stepNo,
      dispatchMixpanelEvent,
      isTrial,
      ...props
    },
    ref,
  ) => {
    const [loading, setLoading] = useState(false);
    const [dirty, setDirty] = useState(false);

    useEffect(() => {
      onDirtyChange?.(dirty);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dirty]);

    const { t } = useTranslation('subscriptionProcess');
    const trialEndDate = formatDateWithMonths(dayjs().add(TRIAL_DURATION_IN_DAYS, 'day'));

    const handleSubmit = async () => {
      setLoading(true);
      try {
        const data = await (ref as MutableRefObject<PaymentDetailsRef>).current?.submit();
        if (data) {
          const { token: _, ...paymentDetails } = data;
          dispatchMixpanelEvent && MixpanelService.track(MixpanelEvent.PaymentDetails);
          submit({ paymentDetails });
        }
      } catch (e) {}
      setLoading(false);
    };

    return (
      <Section
        title={i18n.t('subscriptionProcess:paymentDetails')}
        active={active}
        checked={isSubmitted}
        stepNo={stepNo}
        testID="payment-details-section"
        subtitleContent={
          isTrial ? (
            <Popover
              openOnPress
              content={
                <View style={styles.popoverContent} testID="popover-trial-info">
                  <StyledText style={[styles.popoverText, styles.popoverHeader, typography.body3Bold]}>
                    {t('paymentDetailsTrialPopoverHeader')}
                  </StyledText>
                  <StyledText style={[styles.popoverText, typography.body2]}>
                    {t('paymentDetailsTrialPopoverText', { date: trialEndDate })}
                  </StyledText>
                </View>
              }
            >
              <StyledText style={[typography.body2Bold, styles.subtitle]} testID="trial-info-why">
                {t('paymentDetailsTrialSubtitle')}
              </StyledText>
            </Popover>
          ) : undefined
        }
      >
        <PaymentDetailsForm {...props} onDirtyChange={setDirty} ref={ref} />
        <PaymentDetailsFooter
          buttonProps={{
            title: i18n.t(isSubmitted ? 'saveChanges' : 'next'),
            onPress: handleSubmit,
            testID: 'payment-details-next-btn',
            loading: isLoading || loading,
            disabled: isSubmitted && !dirty,
            variant: 'gradient',
          }}
        />
      </Section>
    );
  },
);

const styles = StyleSheet.create({
  subtitle: {
    color: palette.blue,
  },
  popoverContent: {
    width: 400,
    ...ifWeb({
      maxWidth: '60vw',
    }),
  },
  popoverText: {
    color: palette.white,
  },
  popoverHeader: {
    marginBottom: 8,
  },
});
