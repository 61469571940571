import { Formik, FormikHelpers, FormikProps } from 'formik';
import React from 'react';
import { Platform, StyleSheet } from 'react-native';

import { BaseButton, InputItem } from 'src/components';
import { passwordValidationRules } from 'src/constants/forms';
import { Yup } from 'src/helpers/validation';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';

import type { PasswordRecoveryFormData } from '../types';

const validationSchema: Yup.SchemaOf<PasswordRecoveryFormData> = Yup.object().shape({
  password: passwordValidationRules,
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password')], i18n.t('validation:passwords_dont_match'))
    .required(),
});

const initialValues: PasswordRecoveryFormData = {
  password: '',
  passwordConfirm: '',
};

interface FormProps extends FormikProps<PasswordRecoveryFormData> {
  loading: boolean;
  handleSubmit: () => void;
}

interface Props {
  onSubmit: (
    values: PasswordRecoveryFormData,
    formikHelpers: FormikHelpers<PasswordRecoveryFormData>,
  ) => void;
  loading: boolean;
}

const Form: React.FC<FormProps> = (props) => {
  const { handleChange, handleBlur, values, handleSubmit, errors, touched, loading } = props;
  const { isTablet } = useDeviceInfo();
  const handleEditingSubmit = Platform.OS === 'web' ? handleSubmit : undefined;

  return (
    <>
      <InputItem
        onChangeText={handleChange('password')}
        onBlur={handleBlur('password')}
        value={values.password}
        error={errors.password}
        touched={touched.password}
        label={i18n.t('common:passwordNew')}
        secureTextEntry
        autoCapitalize="none"
        textContentType="password"
        blurOnSubmit
        onSubmitEditing={handleEditingSubmit}
        placeholder={i18n.t('common:enterPassword')}
        testID="new-password-input"
      />
      <InputItem
        onChangeText={handleChange('passwordConfirm')}
        onBlur={handleBlur('passwordConfirm')}
        value={values.passwordConfirm}
        error={errors.passwordConfirm}
        touched={touched.passwordConfirm}
        label={i18n.t('common:passwordConfirm')}
        secureTextEntry
        autoCapitalize="none"
        textContentType="password"
        blurOnSubmit
        placeholder={i18n.t('common:passwordConfirm')}
        onSubmitEditing={handleEditingSubmit}
        containerStyle={styles.inputMargin}
        testID="confirm-new-password-input"
      />
      <BaseButton
        onPress={handleSubmit}
        variant="gradient"
        title={i18n.t('passwordRecovery:submit')}
        containerStyle={styles.button}
        fullWidth={!isTablet}
        loading={loading}
        disabled={loading}
        testID="create-new-password-btn"
      />
    </>
  );
};

export const PasswordRecoveryForm: React.FC<Props> = (props) => {
  return (
    <Formik initialValues={initialValues} onSubmit={props.onSubmit} validationSchema={validationSchema}>
      {(formikProps) => <Form {...formikProps} loading={props.loading} />}
    </Formik>
  );
};

const styles = StyleSheet.create({
  inputMargin: {
    marginTop: 20,
  },
  button: {
    marginTop: 32,
    alignSelf: 'flex-start',
  },
});
