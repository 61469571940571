import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, StyleProp, TextStyle } from 'react-native';

import { ContentStatus, ContentType } from 'src/constants/types';
import { isAlgorithm, isAppendix, isDxTx, isDrug, isHandout } from 'src/helpers';
import { palette } from 'src/styles';

import { Badge } from './Badge';
import { StatusBadge } from './StatusBadge';

interface BadgeProps {
  style: StyleProp<TextStyle>;
  label: string;
}

const getContentBadgeProps = (contentType: ContentType): BadgeProps | undefined => {
  if (isAppendix({ contentType })) {
    return {
      label: 'appendix',
      style: { color: palette.blue },
    };
  }
  if (isDrug({ contentType })) {
    return {
      label: 'drug',
      style: { color: palette.darkBlue },
    };
  }
  if (isAlgorithm({ contentType })) {
    return {
      label: 'algorithm',
      style: { color: palette.blue5 },
    };
  }
  if (isDxTx({ contentType })) {
    return {
      label: 'dxTx',
      style: { color: palette.turquoise, textTransform: 'none' },
    };
  }
  if (isHandout({ contentType })) {
    return {
      label: 'handout',
      style: { color: palette.easternBlue },
    };
  }
};

export interface Props {
  contentType: ContentType;
  statusType?: ContentStatus;
  showBoth?: boolean;
  contentListSpacing?: boolean;
}

export const ContentTypeBadge: React.FC<Props> = ({
  contentType,
  statusType,
  showBoth,
  contentListSpacing,
}) => {
  const { t } = useTranslation('contentTypeLabel');

  const badgeProps = getContentBadgeProps(contentType);

  if (!badgeProps) return null;

  const hasStatus = !!statusType && statusType !== ContentStatus.None;

  const contentBadge =
    (hasStatus && showBoth) || !hasStatus ? (
      <Badge badgeText={t(badgeProps.label)} textStyle={badgeProps.style} testID="content-badge" />
    ) : null;

  const statusBadge = hasStatus ? (
    <StatusBadge
      statusType={statusType}
      style={[showBoth && styles.spacing, contentListSpacing && styles.contentListSpacing]}
    />
  ) : null;

  return (
    <View style={styles.wrapper}>
      {contentBadge}
      {statusBadge}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
  },
  spacing: {
    marginLeft: 12,
  },
  contentListSpacing: {
    marginLeft: 8,
  },
});
