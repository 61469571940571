import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconTextButton } from 'src/components/IconTextButton';
import { LoadingIndicator } from 'src/components/LoadingIndicator';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

import { useInvoiceData } from '../../hooks';
import { Invoice } from '../../types';

interface Props {
  invoice: Invoice;
}

export const InvoiceCell: React.FC<Props> = ({ invoice: { id } }) => {
  const { refetch: fetchInvoiceData, data: pdfLink, isFetching } = useInvoiceData(id);
  const { t } = useTranslation('subscriptionDetails');

  const buttonProps: Omit<React.ComponentProps<typeof IconTextButton>, 'text'> = {
    icon: 'pdf',
    iconPosition: 'left',
    textStyle: typography.body1Bold,
    underlineAtHover: true,
    iconWidth: 20,
    iconColor: palette.navy,
  };

  return isFetching ? (
    <LoadingIndicator size="small" />
  ) : !pdfLink ? (
    <IconTextButton text={t('generatePDF')} onPress={fetchInvoiceData} {...buttonProps} />
  ) : (
    <Link to={pdfLink} external>
      <IconTextButton text={t('openPDF')} {...buttonProps} />
    </Link>
  );
};
