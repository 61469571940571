import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { Carousel } from 'src/components/Carousel';
import { GO_PRO_LINK, HELP_CENTER_LINK, SUPPORT_LINK } from 'src/constants/externalLinks';
import { useUserInfo } from 'src/features/auth/hooks';
import { palette } from 'src/styles';

import { PlanBenefitsCarouselItem, PlanBenefitsItem } from './PlanBenefitsCarouselItem';
import { doesRoleHaveProVariant } from '../../helpers';
import { useSubscriptionInfo } from '../../hooks';

interface Props {}

export const PlanBenefitsCarousel: React.FC<Props> = () => {
  const { t } = useTranslation('planBenefits');
  const { isPro, expiredSubscription } = useUserInfo();
  const { data: subscriptionInfo } = useSubscriptionInfo();

  const items: (PlanBenefitsItem & { isHidden?: boolean })[] = [
    {
      description: t('goBeyondText'),
      title: t('goBeyondTitle'),
      button: {
        link: GO_PRO_LINK,
        text: t('goBeyondButton'),
      },
      isHidden:
        (isPro || !subscriptionInfo || !doesRoleHaveProVariant(subscriptionInfo.planSetup.role)) &&
        !expiredSubscription,
      testID: 'item-go-beyond',
    },
    {
      description: t('questionsText'),
      title: t('questionsTitle'),
      button: {
        link: HELP_CENTER_LINK,
        text: t('questionsButton'),
      },
      isHidden: expiredSubscription,
      testID: 'item-question',
    },
    {
      description: t('helpText'),
      title: t('helpTitle'),
      button: {
        link: SUPPORT_LINK,
        text: t('helpButton'),
      },
      isHidden: expiredSubscription,
      testID: 'item-help',
    },
  ];

  const itemsToDisplay = items.filter((item) => !item.isHidden);

  return (
    <View style={styles.wrapper}>
      <Carousel
        items={itemsToDisplay.map((item) => (
          <PlanBenefitsCarouselItem item={item} key={item.title} singleItem={itemsToDisplay.length === 1} />
        ))}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: palette.blue,
  },
});
