import { FeatureButtonWithSectionId } from 'src/components/ContentItemScreen/ContentItemScreen';
import { SpecialSectionIDs } from 'src/constants/constants';
import { PUBLICATION_DETAILS_SECTION_ID } from 'src/constants/content';
import {
  ContentItemBase,
  ContentItemDetails,
  ContentItemSection,
  ContentItemWithSectionsBase,
  ContentItemWithSectionsDetailsBase,
  ContentListType,
  ContentType,
} from 'src/constants/types';
import type { Algorithm } from 'src/features/algorithms';
import type { Appendix } from 'src/features/appendix';
import type { Drug } from 'src/features/drugs';
import type { DxTx } from 'src/features/dxTx';
import { DrugHandout, ClinicalHandout, Handout, DrugHandoutDetails } from 'src/features/handouts';
import { MixpanelBackToTopInContent, MixpanelBackToTopInList } from 'src/features/tracking';
import { i18n } from 'src/locale';

import { getPublicationDetailsSectionPredecessorIndex } from './functions';
import { createHeadingId, getHeadingsFromMarkdown } from './markdown/utils';

export const clinicalBriefsContentTypes = [
  ContentType.DxTxCondition,
  ContentType.DxTxMisc,
  ContentType.DxTxSignSituation,
];

export const clinicalHandoutsContentTypes = [
  ContentType.HandoutProcedure,
  ContentType.HandoutClinicalConditions,
  ContentType.HandoutVaccine,
  ContentType.HandoutMisc,
];

type ObjectWithContentType = { contentType: ContentType };

export const isDxTx = (data: ObjectWithContentType): data is DxTx =>
  clinicalBriefsContentTypes.includes(data.contentType);
export const isMiscDxTx = (data: ObjectWithContentType) => data.contentType === ContentType.DxTxMisc;

export const isClinicalHandout = (data: ObjectWithContentType): data is ClinicalHandout =>
  clinicalHandoutsContentTypes.includes(data.contentType);

export const isHandoutClinicalConditions = (data: ObjectWithContentType): data is ClinicalHandout =>
  data.contentType === ContentType.HandoutClinicalConditions;
export const isHandoutProcedure = (data: ObjectWithContentType): data is ClinicalHandout =>
  data.contentType === ContentType.HandoutProcedure;
export const isHandoutVaccine = (data: ObjectWithContentType): data is ClinicalHandout =>
  data.contentType === ContentType.HandoutVaccine;
export const isHandoutMisc = (data: ObjectWithContentType): data is ClinicalHandout =>
  data.contentType === ContentType.HandoutMisc;

export const isAppendix = (data: ObjectWithContentType): data is Appendix =>
  data.contentType === ContentType.Appendix;
export const isDrug = (data: ObjectWithContentType): data is Drug => data.contentType === ContentType.Drug;
export const isVeterinaryMedicationGuide = (data: ObjectWithContentType): data is DrugHandout =>
  data.contentType === ContentType.VeterinaryMedicationGuide;
export const isDrugHandout = <T extends DrugHandout | DrugHandoutDetails = DrugHandout>(
  data: ObjectWithContentType,
): data is T =>
  data.contentType === ContentType.DrugHandout ||
  data.contentType === ContentType.VeterinaryMedicationGuide;
export const isAlgorithm = (data: ObjectWithContentType): data is Algorithm =>
  data.contentType === ContentType.Algorithm;
export const isHandout = (data: ObjectWithContentType): data is Handout =>
  isClinicalHandout(data) || isDrugHandout(data);

export const getQuickLinksFromContentData = (
  data: ContentItemBase | ContentItemWithSectionsBase | ContentItemDetails,
) => {
  return (data as ContentItemWithSectionsBase).quickLinks ?? null;
};

export const isContentWithNotes = (data: ObjectWithContentType) =>
  isDrug(data) || isDxTx(data) || isAppendix(data);

export const getButtonsPerSection = (
  featureButtons: undefined | FeatureButtonWithSectionId[],
): undefined | Record<SpecialSectionIDs, FeatureButtonWithSectionId> => {
  if (featureButtons === undefined) {
    return undefined;
  }
  const buttonsWithSections = featureButtons.filter(
    ({ hidden, sectionWithButton }) => !hidden && sectionWithButton !== undefined,
  );
  return Object.fromEntries(buttonsWithSections.map((button) => [button!.sectionWithButton, button]));
};

export const isItemWithSections = (item: ContentItemDetails): item is ContentItemWithSectionsDetailsBase =>
  !!(item as ContentItemWithSectionsDetailsBase)?.sections;

export const getSectionsWithPublicationDetails = (item: ContentItemDetails) => {
  if (!isItemWithSections(item)) {
    return [];
  }
  const sections = item.sections.filter((section) => section.id !== 'notes');

  const publicationDetailsSectionPredecessorIndex = getPublicationDetailsSectionPredecessorIndex(
    item.sections,
  );

  if (item.publicationDetails) {
    sections.splice(publicationDetailsSectionPredecessorIndex, 0, {
      value: item.publicationDetails,
      title: i18n.t('contents:publicationDetails'),
      id: PUBLICATION_DETAILS_SECTION_ID,
    });
  }

  return sections;
};

export const shouldItemHaveTableOfContents = (item: ContentItemDetails) =>
  isItemWithSections(item) && item.sections.filter((item) => !!item.title).length > 0;

export const getMixpanelParamsForBackToTopInContent = (
  contentId: string,
  contentTitle: string,
  contentType: ContentType,
): MixpanelBackToTopInContent => {
  if (isDrug({ contentType })) {
    return {
      'Monograph ID': contentId,
      'Monograph Name': contentTitle,
      Location: 'In Content',
    };
  }
  if (isDrugHandout({ contentType })) {
    return {
      'Drug Handout ID': contentId,
      'Drug Handout Name': contentTitle,
      Location: 'In Content',
    };
  }
  if (isDxTx({ contentType })) {
    return {
      'Dx & Tx ID': contentId,
      'Dx & Tx Name': contentTitle,
      Location: 'In Content',
    };
  }
  if (isClinicalHandout({ contentType })) {
    return {
      'Clinical Handout ID': contentId,
      'Clinical Handout Name': contentTitle,
      Location: 'In Content',
    };
  }

  if (isAppendix({ contentType })) {
    return {
      'Appendix ID': contentId,
      'Appendix Name': contentTitle,
      Location: 'In Content',
    };
  }

  throw new Error(
    `Couldn't get BackToTopInContent Mixpanel properties for following params: "${contentId}" contentId, "${contentTitle}" title and "${contentType}" content type`,
  );
};

export const getMixpanelParamsForBackToTopInList = (listType: ContentListType): MixpanelBackToTopInList => {
  switch (listType) {
    case ContentListType.Algorithms: {
      return { Location: 'Algorithm Index' };
    }
    case ContentListType.Appendix: {
      return { Location: 'Appendix Index' };
    }
    case ContentListType.ClinicalHandouts: {
      return { Location: 'Clinical Handouts Index' };
    }
    case ContentListType.DrugHandouts: {
      return { Location: 'VMG Index' };
    }
    case ContentListType.Drugs: {
      return { Location: 'Drug Monongraph Index' };
    }
    case ContentListType.DxTx: {
      return { Location: 'Dx&Tx Index' };
    }
  }
};

export const findSectionWithHeader = (sections: ContentItemSection[], headingId: string) => {
  return sections.find((section) => {
    const headingsIds = getHeadingsFromMarkdown(section.value).map((header) => createHeadingId(header));
    return headingsIds.includes(headingId);
  });
};
