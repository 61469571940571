import { USER_OTHER_VALUE } from 'src/constants/constants';
import { locationInformationForm } from 'src/constants/forms';
import type { UserFormField, FormField } from 'src/constants/types';
import { Yup } from 'src/helpers/validation';

import * as formOptions from './userDetailsOptions';
import { STUDENTS_ACTIVITIES, VETERINARIAN_ROLES } from '../constants';
import type { OccupationDetails, NullablePersonalInformation } from '../types';

type OccupationDetailsKeys = keyof OccupationDetails;
type PersonalInformationKeys = keyof NullablePersonalInformation;

interface OccupationDetailsField extends UserFormField {
  shouldBeDisplayed?: boolean | ((state: OccupationDetails) => boolean);
  isOptional?: boolean;
  maxLength?: number;
}

const requiredString = Yup.string().required().nullable();

const shouldDisplayRoleField = (state: OccupationDetails): boolean =>
  !!state.activity &&
  !STUDENTS_ACTIVITIES.includes(state.activity) &&
  state.activity !== USER_OTHER_VALUE &&
  state.activity !== 'pharmacy';

export const personalInformationForm: Record<PersonalInformationKeys, FormField> = {
  firstName: {
    type: 'text',
    validation: requiredString.max(50),
    testID: 'first-name-input',
  },
  lastName: {
    type: 'text',
    validation: requiredString.max(50),
    testID: 'last-name-input',
  },
  bornYear: {
    type: 'select',
    validation: requiredString,
    setCustomPlaceholder: true,
    options: formOptions.bornYear,
    testID: 'born-year-select',
  },
  phone: {
    type: 'text',
    validation: requiredString.max(32),
    testID: 'phone-input',
  },
  ...locationInformationForm,
};

export const requiredPersonalInformationFields: PersonalInformationKeys[] = [
  'address1',
  'bornYear',
  'city',
  'country',
  'firstName',
  'lastName',
  'phone',
  'postalCode',
  'practice',
];

export const occupationDetailsForm: Record<OccupationDetailsKeys, OccupationDetailsField> = {
  activity: {
    type: 'select',
    shouldBeDisplayed: true,
    options: formOptions.activity,
    setCustomPlaceholder: true,
    testID: 'activity-select',
  },
  activityOther: {
    type: 'text',
    shouldBeDisplayed: (state) => state.activity === USER_OTHER_VALUE,
    maxLength: 256,
    testID: 'activity-other-input',
  },
  pharmacy: {
    type: 'select',
    options: formOptions.pharmacy,
    shouldBeDisplayed: (state) => state.activity === 'pharmacy',
    testID: 'pharmacy-select',
  },
  pharmacyOther: {
    type: 'text',
    shouldBeDisplayed: (state) => state.pharmacy === USER_OTHER_VALUE,
    maxLength: 128,
    testID: 'pharmacy-other-input',
  },
  graduationYear: {
    type: 'select',
    shouldBeDisplayed: (state) => !!(state.activity && STUDENTS_ACTIVITIES.includes(state.activity)),
    options: formOptions.graduationYear,
    setCustomPlaceholder: true,
    testID: 'graduation-year-select',
  },
  schoolAttending: {
    type: 'select',
    shouldBeDisplayed: (state) => !!(state.activity && STUDENTS_ACTIVITIES.includes(state.activity)),
    options: formOptions.schools,
    setCustomPlaceholder: true,
    testID: 'school-attending-select',
  },
  schoolAttendingOther: {
    type: 'text',
    shouldBeDisplayed: (state) => state.schoolAttending === USER_OTHER_VALUE,
    maxLength: 128,
    testID: 'school-attending-other-input',
  },
  role: {
    type: 'select',
    shouldBeDisplayed: shouldDisplayRoleField,
    options: formOptions.role,
    testID: 'role-select',
  },
  roleOther: {
    type: 'text',
    shouldBeDisplayed: (state) =>
      state.role === 'other-non-veterinarian' || state.role === 'other-veterinarian',
    maxLength: 128,
    testID: 'role-other-input',
  },
  schoolGraduated: {
    type: 'select',
    shouldBeDisplayed: (state) => !!state.role && VETERINARIAN_ROLES.includes(state.role),
    options: formOptions.schools,
    isOptional: true,
    testID: 'school-graduated-select',
  },
  schoolGraduatedOther: {
    type: 'text',
    shouldBeDisplayed: (state) => state.schoolGraduated === USER_OTHER_VALUE,
    maxLength: 128,
    testID: 'school-graduated-other-input',
  },
  yearsOfPractice: {
    type: 'select',
    shouldBeDisplayed: (state) => !!state.role,
    options: formOptions.yearsOfPractice,
    testID: 'years-practice-select',
  },
  numberOfVeterinarians: {
    type: 'select',
    shouldBeDisplayed: (state) => !!state.yearsOfPractice,
    options: formOptions.numberOfVeterinarians,
    testID: 'number-veterinarians-select',
  },
  practiceType: {
    type: 'select',
    shouldBeDisplayed: (state) => !!state.numberOfVeterinarians,
    options: formOptions.practiceType,
    testID: 'practice-type-select',
  },
  practiceTypeOther: {
    type: 'text',
    shouldBeDisplayed: (state) => state.practiceType === USER_OTHER_VALUE,
    maxLength: 128,
    testID: 'practice-type-other-input',
  },
  practiceOwnership: {
    type: 'select',
    shouldBeDisplayed: (state) => !!state.practiceType,
    options: formOptions.practiceOwnership,
    testID: 'practice-ownership-select',
  },
};
