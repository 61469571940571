import React from 'react';
import { StyleSheet, View } from 'react-native';

import { SecondaryButton, StyledText } from 'src/components';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

export interface PlanBenefitsItem {
  title: string;
  description: string;
  button?: {
    link: string;
    text: string;
  };
  testID?: string;
}

interface Props {
  item: PlanBenefitsItem;
  singleItem?: boolean;
}

export const PlanBenefitsCarouselItem: React.FC<Props> = ({ item, singleItem }) => {
  const { description, title, button: buttonProps, testID } = item;
  const { isDesktop } = useDeviceInfo();

  return (
    <View
      style={[styles.wrapper, singleItem && styles.wrapperSingle, isDesktop && styles.wrapperDesktop]}
      testID={testID}
    >
      <StyledText style={[styles.title, typography.body5Bold]}>{title}</StyledText>
      <StyledText style={[styles.description, typography.body2]}>{description}</StyledText>
      {buttonProps && (
        <Link to={buttonProps.link} external>
          <SecondaryButton
            title={buttonProps.text}
            size="tiny"
            titleStyle={styles.buttonText}
            innerContainerStyle={styles.buttonInnerContainer}
            containerStyle={styles.button}
          />
        </Link>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingTop: 32,
    paddingBottom: 75,
    paddingHorizontal: 42,
    backgroundColor: palette.blue,
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
  },
  wrapperSingle: {
    paddingBottom: 32,
  },
  wrapperDesktop: {
    justifyContent: 'flex-start',
  },
  title: {
    color: palette.white,
    marginBottom: 14,
  },
  description: {
    color: palette.white,
  },
  button: {
    marginTop: 20,
  },
  buttonText: {
    color: palette.white,
  },
  buttonInnerContainer: {
    borderColor: palette.white,
  },
});
