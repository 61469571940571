import { onlineManager } from '@tanstack/react-query';
import { groupBy } from 'lodash-es';

import { useDrugNames, useInteractions, useInteractionsCount } from './queries';
import { interactionClassifications } from '../constants';
import { DICClassificationKey, DICMode, DrugInteraction } from '../types';

interface Params {
  drugs: string[];
  all?: boolean;
  mode?: DICMode;
}

/** If there are more drugs selected, we'll use pagination and fetch interactions from one classification only */
const DRUGS_THRESHOLD = 5;

/**
 * Controls what classifications should be displayed in accordion;
 * Fetches interactions if small number of drugs is selected
 */
export const useInteractionsList = ({ drugs, all, mode }: Params) => {
  const isSingleDrugSelected = drugs.length === 1;
  const shouldUsePaginatedList = isSingleDrugSelected || drugs.length > DRUGS_THRESHOLD;

  // User needs to click on a button first to fetch all interactions for one drug
  const shouldDisplaySingleDrugInfo = isSingleDrugSelected && !all;

  const {
    data: paginatedListCount,
    isFetching: isFetchingCount,
    isError: isErrorCount,
    fetchStatus: fetchStatusCount,
  } = useInteractionsCount(drugs, {
    enabled: shouldUsePaginatedList && !shouldDisplaySingleDrugInfo,
  });

  const {
    data: drugNames,
    isLoading: isLoadingDrugNames,
    fetchStatus: fetchStatusDrugName,
  } = useDrugNames(drugs);

  const {
    data: allInteractions,
    isFetching: isFetchingAllInteractions,
    isError: isErrorAllInteractions,
    fetchStatus: fetchStatusAllInteractions,
  } = useInteractions({
    drugs,
    listParams: {
      pageSize: 999,
    },
    params: {
      mode,
    },
    options: {
      enabled: !shouldUsePaginatedList && !!drugs.length,
    },
  });

  const singleDrugName =
    (shouldDisplaySingleDrugInfo && drugNames?.drugs.find((item) => item.id === drugs[0])?.title) ||
    undefined;

  const interactionsByClassification =
    (allInteractions && groupBy(allInteractions.items, (interaction) => interaction.classificationKey)) ??
    [];

  const classifications = shouldDisplaySingleDrugInfo
    ? []
    : interactionClassifications
        .map((classificationData) => ({
          ...classificationData,
          count: shouldUsePaginatedList
            ? paginatedListCount?.[classificationData.classificationKey]
            : interactionsByClassification[classificationData.classificationKey]?.length,
          data: shouldUsePaginatedList
            ? undefined
            : interactionsByClassification[classificationData.classificationKey],
        }))
        .filter((item) => item.count);

  const isFetching = isFetchingAllInteractions || isFetchingCount || isLoadingDrugNames;
  const isError = isErrorAllInteractions || isErrorCount;
  const isOfflineError =
    [fetchStatusAllInteractions, fetchStatusCount, fetchStatusDrugName].includes('paused') &&
    !onlineManager.isOnline();

  const noInteractionsToDisplay =
    !!drugs.length && !isFetching && !shouldDisplaySingleDrugInfo && !classifications.length;

  return {
    shouldUsePaginatedList,
    classifications,
    isFetching,
    isError,
    singleDrugName,
    noInteractionsToDisplay,
    isOfflineError,
  } as ResponsePaginatedList | ResponseWithData;
};

interface ClassificationBase {
  classificationKey: DICClassificationKey;
  count: number;
}

interface ClassificationPaginatedList extends ClassificationBase {
  data: undefined;
}

interface ClassificationWithData extends ClassificationBase {
  data: DrugInteraction[] | undefined;
}

interface ResponseBase {
  shouldUsePaginatedList: boolean;
  isFetching: boolean;
  singleDrugName?: string;
  noInteractionsToDisplay?: boolean;
  isError: boolean;
  isOfflineError: boolean;
}

interface ResponsePaginatedList extends ResponseBase {
  shouldUsePaginatedList: true;
  classifications: ClassificationPaginatedList[];
}

interface ResponseWithData extends ResponseBase {
  shouldUsePaginatedList: false;
  classifications: ClassificationWithData[];
}
