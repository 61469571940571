import React, { Fragment } from 'react';
import { StyleSheet, View } from 'react-native';

import { Container } from 'src/components';
import { StyledText } from 'src/components/StyledText';
import {
  PRIVACY_POLICY_LINK,
  TERMS_AND_CONDITIONS_LINK,
  ACCEPTABLE_USE_POLICY_LINK,
} from 'src/constants/externalLinks';
import { i18n } from 'src/locale';
import { Link } from 'src/navigation/components';
import { palette, ifWeb } from 'src/styles';

export const Footer: React.FC = () => {
  const links: { text: string; link: string }[] = [
    { text: i18n.t('footer:termsAndConditions'), link: TERMS_AND_CONDITIONS_LINK },
    { text: i18n.t('footer:terms'), link: ACCEPTABLE_USE_POLICY_LINK },
    { text: i18n.t('footer:privacyPolicy'), link: PRIVACY_POLICY_LINK },
  ];

  const separator = <StyledText style={[styles.text, styles.separator]}>|</StyledText>;

  return (
    <View style={styles.wrapper}>
      <Container>
        <StyledText style={[styles.text, styles.textCenter]}>
          {i18n.t('footer:copyright', { year: new Date().getFullYear() })}
          {separator}
          {links.map(({ link, text }, index) => (
            <Fragment key={index}>
              <Link to={link} wrapper="text" external>
                {(isHovered) => (
                  <StyledText style={[styles.text, styles.link, isHovered && styles.linkHover]}>
                    {text}
                  </StyledText>
                )}
              </Link>
              {index !== links.length - 1 && separator}
            </Fragment>
          ))}
        </StyledText>
      </Container>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: 24,
    borderTopWidth: 1,
    borderTopColor: palette.grey2,
  },
  text: {
    lineHeight: 16,
    color: palette.grey5,
    fontSize: 12,
  },
  textCenter: {
    textAlign: 'center',
  },
  link: {
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  separator: {
    marginHorizontal: 4,
  },
  linkHover: {
    color: palette.blue,
    textDecorationColor: palette.blue,
    textDecorationLine: 'underline',
  },
});
