import React from 'react';
import { StyleSheet, View } from 'react-native';

import { StyledText } from 'src/components';
import { i18n } from 'src/locale';
import { palette, typography } from 'src/styles';

import { Purchase, BillingPeriod } from '../../types';

type SliderProps = {
  purchasePreview?: Purchase;
  billingPeriod: BillingPeriod;
  min?: number;
  max: number | string;
  overMaxQuantity?: boolean;
};
export const SliderDescription: React.FC<SliderProps> = ({
  purchasePreview,
  billingPeriod,
  min,
  max,
  overMaxQuantity,
}) => {
  return (
    <View style={[styles.descriptionWrapper]}>
      <StyledText style={[typography.text1, styles.numberLabel]}>{min}</StyledText>
      {!!purchasePreview && !overMaxQuantity && (
        <View style={styles.row}>
          {purchasePreview.baseUnitAmount > purchasePreview.notDiscountedUnitAmount && (
            <StyledText style={[typography.text1, styles.oldPrice]}>
              {i18n.format(purchasePreview.baseUnitAmount, 'price')}
            </StyledText>
          )}
          <StyledText style={styles.priceLabel}>
            {i18n.t('subscriptionProcess:pricePerUserPerPeriod', {
              value: purchasePreview.notDiscountedUnitAmount,
              period: `$t(subscriptionProcess:${
                billingPeriod === BillingPeriod.ANNUAL ? 'year' : 'month'
              })`,
            })}
          </StyledText>
        </View>
      )}
      <StyledText style={[typography.text1, styles.numberLabel]}>{max}</StyledText>
    </View>
  );
};

const styles = StyleSheet.create({
  descriptionWrapper: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    marginBottom: 4,
  },
  priceLabel: {
    color: palette.grey4,
    ...typography.text1,
    ...typography.weightBold,
  },
  numberLabel: {
    color: palette.grey5,
  },
  row: {
    flexDirection: 'row',
  },
  oldPrice: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
    marginRight: 5,
    color: palette.grey4,
  },
});
