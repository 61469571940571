import { useRef } from 'react';
import { StyleSheet, View } from 'react-native';

import { PrimaryButton, LoadingIndicator, SecondaryButton } from 'src/components';
import { getErrorMessageForApiError } from 'src/errorHandling/utils';
import { showNotification } from 'src/helpers';
import { i18n } from 'src/locale';
import { ifWeb } from 'src/styles';

import { PaymentDetails } from './PaymentDetails';
import { useBillingInformation, useUpdateBillingInformation } from '../../hooks';
import { PaymentDetailsForm, PaymentDetailsRef } from '../PaymentDetails/PaymentDetailsForm';

interface Props {
  isFormOpen: boolean;
  isPromotion?: boolean;
  isSubmitting?: boolean;
  needToFillPaymentInformation?: boolean;
  toggleForm(isFormOpen: boolean): void;
  setSubmitting(isLoading: boolean): void;
}

export const PaymentDetailsSection: React.FC<Props> = ({
  isFormOpen,
  isPromotion,
  isSubmitting,
  needToFillPaymentInformation,
  setSubmitting,
  toggleForm,
}) => {
  const paymentDetailsRef = useRef<PaymentDetailsRef>(null);
  const { data: billingInformation, isFetching } = useBillingInformation();
  const { mutateAsync: updateBillingInformation } = useUpdateBillingInformation();

  const getRecurlyTokenId = async () => {
    const { token } = await paymentDetailsRef.current!.submit();
    return token.id;
  };

  const save = async () => {
    setSubmitting(true);
    try {
      const token = await getRecurlyTokenId();
      if (token) {
        await updateBillingInformation(token);
        showNotification({ type: 'success' });
        toggleForm(false);
      }
    } catch (error: any) {
      const detailError = error?.response?.data?.detail;
      if (detailError) {
        showNotification({
          type: 'error',
          title: getErrorMessageForApiError(detailError),
          autoHide: false,
        });
      } else {
        showNotification({ type: 'error', title: error?.message });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <View style={styles.zIndexUnset} testID="payment-details-form">
      {isFetching ? (
        <LoadingIndicator style={styles.loader} displayStandbyText />
      ) : isFormOpen ? (
        <>
          <PaymentDetailsForm
            ref={paymentDetailsRef}
            detailsData={isPromotion ? undefined : billingInformation}
            billingAddressInfo={i18n.t('subscriptionDetails:billingAddressTaxInfo')}
          />
          <View style={styles.paymentDetailsButtonsWrapper}>
            {!needToFillPaymentInformation && (
              <SecondaryButton
                size="tiny"
                title={i18n.t('cancelChanges')}
                containerStyle={styles.paymentDetailsButton}
                onPress={() => toggleForm(false)}
                disabled={isSubmitting}
                testID="payment-details-form-cancel-btn"
              />
            )}
            <PrimaryButton
              size="tiny"
              title={i18n.t('saveChanges')}
              onPress={save}
              loading={isSubmitting}
              testID="payment-details-form-submit-btn"
            />
          </View>
        </>
      ) : (
        <>
          {billingInformation && <PaymentDetails billingInformation={billingInformation} />}
          <SecondaryButton
            title={i18n.t('subscriptionDetails:editPaymentInformation')}
            size="tiny"
            containerStyle={styles.editPaymentButton}
            onPress={() => toggleForm(true)}
            disabled={isSubmitting}
            testID="edit-payment-info-btn"
          />
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  loader: {
    marginVertical: 30,
  },
  editPaymentButton: {
    alignSelf: 'flex-start',
    marginTop: 25,
  },
  paymentDetailsButton: {
    marginRight: 10,
  },
  paymentDetailsButtonsWrapper: {
    flexDirection: 'row',
    marginTop: 20,
  },
  zIndexUnset: {
    ...ifWeb({
      zIndex: 'auto',
    }),
  },
});
