import type { Algorithm } from 'src/features/algorithms';
import type { Appendix } from 'src/features/appendix';
import type { Drug } from 'src/features/drugs';
import type { DxTx } from 'src/features/dxTx';
import type { DrugHandout, ClinicalHandout } from 'src/features/handouts';

import { PaginationResponse } from './common';

export enum ContentType {
  VeterinaryMedicationGuide = 'veterinary-medication-guide',
  Drug = 'monograph',
  Appendix = 'appendix',
  Algorithm = 'algorithm',
  DxTxSignSituation = 'clinical-brief-sign-situation',
  DxTxMisc = 'clinical-brief-misc',
  DxTxCondition = 'clinical-brief-condition',
  HandoutProcedure = 'handout-procedure',
  HandoutClinicalConditions = 'handout-clinical-conditions',
  HandoutVaccine = 'handout-vaccine',
  HandoutMisc = 'handout-misc',
  DrugHandout = 'drug-handout',
}

export enum ContentListType {
  Algorithms = 'algorithms',
  Appendix = 'appendix',
  DxTx = 'dx-tx',
  ClinicalHandouts = 'clinical-handouts',
  DrugHandouts = 'drug-handouts',
  Drugs = 'drugs',
}

export type DxTxContentType =
  | ContentType.DxTxCondition
  | ContentType.DxTxMisc
  | ContentType.DxTxSignSituation;

export type ContentInList = {
  [ContentListType.Algorithms]: Algorithm;
  [ContentListType.DxTx]: DxTx;
  [ContentListType.Drugs]: Drug;
  [ContentListType.Appendix]: Appendix;
  [ContentListType.DrugHandouts]: DrugHandout;
  [ContentListType.ClinicalHandouts]: ClinicalHandout;
};

export interface ContentItemBase {
  id: string;
  title: string;
  contentType: ContentType;
  status?: ContentStatus;
  /** Returned only in New and Updated lists */
  seen?: boolean;
}

export interface ContentItemWithSectionsBase extends ContentItemBase {
  quickLinks?: ContentItemQuickLink[];
}

export interface ContentItemDetailsBase extends Omit<ContentItemBase, 'seen'> {
  createdAt: string;
  updatedAt: string;
  publicationDetails?: string;
}

export interface ContentItemWithSectionsDetailsBase
  extends ContentItemWithSectionsBase,
    ContentItemDetailsBase {
  sections: ContentItemSection[];
}

export type ContentItemDetails = ContentItemDetailsBase | ContentItemWithSectionsDetailsBase;

export interface ContentItemSection {
  id: string;
  title: string;
  value: string;
}

export interface ContentItemQuickLink {
  id: string;
  title: string;
}

export enum ContentStatus {
  New = 'New',
  None = 'None',
  Update = 'Updated',
  Archived = 'Archived',
  InDevelopment = 'In Development',
}

export type SpecialAccordionLocation =
  | 'content-list-favorites'
  | 'content-list-new'
  | 'content-list-updated';

export type ContentItemSource =
  | 'home-screen-favorites'
  | 'notes'
  | 'search'
  | 'recently-viewed'
  | 'top-viewed'
  | 'drug-page' // drug handouts assigned to a drug
  | 'dx-tx-page' // clinical handouts assigned to dx-tx
  | SpecialAccordionLocation;

export type ContentCardLocation =
  | 'content-list'
  | 'content-item'
  | 'search-list'
  | 'recently-viewed'
  | 'top-viewed'
  | 'drug-page' // drug handouts assigned to a drug
  | 'dx-tx-page' // clinical handouts assigned to dx-tx
  | SpecialAccordionLocation;

export type ContentListItemCommonProps<C extends ContentItemBase> = {
  location: ContentCardLocation;
  bottomBorder?: boolean;
  topBorder?: boolean;
  sideBorder?: boolean;
  roundedTopBorder?: boolean;
  roundedBottomBorder?: boolean;
  item: C;
};

export interface ContentItemParams {
  id: string;
  source?: ContentItemSource;
  searchQuery?: string;
}

export interface ContentWithSectionsItemParams extends ContentItemParams {
  section?: string;
  subsection?: string;
}

export type LettersResponse = {
  [contentType in ContentType]: string[];
};

export interface TableOfContentsProps {
  item: ContentItemDetails;
  scrollToTop(): void;
  onItemPress(sectionId: string): void;
  activeSectionId?: string;
}

export interface TableOfContentsItem {
  title: string;
  onPress: () => void;
  id: string;
}

export type ContentListResponse<C> = PaginationResponse<C> & {
  /** Returned only for New and Updated lists */
  unseenCount?: number;
};

export type AccordionOpenPageType =
  | 'monograph'
  | 'appendix'
  | 'veterinary-medication-guide'
  | 'algorithm'
  | 'clinical-brief'
  | 'patient-guides';
export type AccordionOpenListType = 'New' | 'Updated';

export interface AccordionOpenParams {
  pageType: AccordionOpenPageType;
  accordion: AccordionOpenListType;
}
