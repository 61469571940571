import { Dispatch, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { Icon, Pressable, StyledText } from 'src/components';
import { IPSignOutConfirmModal } from 'src/features/auth/components/IPSignOutConfirmModal';
import { useSignOut, useUserInfo } from 'src/features/auth/hooks';
import { useDeviceInfo, useOutsideClickDetector, useScreenAccess } from 'src/hooks';
import { getShadow, palette, typography } from 'src/styles';

import { Link } from './Link';
import { ProPromoButton } from './SideBar/ProPromoButton';
import { Route } from '../routes';

interface Props {
  isDropdownOpen: boolean;
  setDropdownOpen: Dispatch<React.SetStateAction<boolean>>;
  toggleDropdown: () => void;
}

interface MenuLink extends Pick<React.ComponentProps<typeof Link>, 'to' | 'testID' | 'external'> {
  text: string;
  isHidden?: boolean;
  key: string;
}

export const AccountMenuDropdown: React.FC<Props> = ({
  isDropdownOpen,
  setDropdownOpen,
  toggleDropdown,
}) => {
  const { t } = useTranslation('navigation');
  const { isAdmin } = useUserInfo();
  const { isScreenAccessible } = useScreenAccess();
  const { isSmallestMobile, isDesktop } = useDeviceInfo();
  const { confirmationModal, handleSignOutPress } = useSignOut({ IPSignOutConfirmModal });

  const wrapperRef = useRef<View>(null);

  const close = () => setDropdownOpen(false);

  useOutsideClickDetector(wrapperRef, close);

  const links: MenuLink[] = [
    {
      text: t('profile'),
      key: 'profile',
      to: { route: Route.AccountInformation },
      isHidden: !isScreenAccessible(Route.AccountInformation),
      testID: 'nav-link-profile',
    },
    {
      text: t('subscriptionDetails'),
      to: { route: Route.SubscriptionDetails },
      isHidden: !isScreenAccessible(Route.SubscriptionDetails) || isAdmin,
      key: 'subscription-details',
      testID: 'nav-link-subscription-details',
    },
    {
      text: t('adminDashboard'),
      to: { route: Route.AdminDashboard },
      isHidden: !isScreenAccessible(Route.AdminDashboard),
      key: 'admin-dashboard',
      testID: 'nav-link-admin-dashboard',
    },
  ];

  return (
    <View ref={wrapperRef}>
      <Pressable onPress={toggleDropdown} testID="account-dropdown-menu-btn">
        {(isHovered) => (
          <View
            style={[
              styles.button,
              isSmallestMobile ? styles.buttonSmallDevices : styles.buttonMediumDevices,
              isDesktop && styles.buttonLargeDevices,
              isHovered && styles.buttonHovered,
            ]}
          >
            <Icon
              name={isSmallestMobile ? 'user-small' : !isDesktop ? 'user-medium' : 'user'}
              color={isDropdownOpen || isHovered ? palette.navy : palette.blue}
              width={isSmallestMobile ? 13 : !isDesktop ? 15 : 16}
            />
          </View>
        )}
      </Pressable>
      {isDropdownOpen && (
        <View style={styles.dropdown} testID="account-dropdown-menu">
          <View style={[styles.menuItem, styles.menuItemFirst]}>
            <ProPromoButton shadow gradientBackground style={styles.proButton} />
          </View>
          {links
            .filter((link) => !link.isHidden)
            .map((link) => (
              <Link
                to={link.to}
                external={link.external}
                key={link.text}
                style={styles.menuItem}
                onPress={close}
              >
                {(isHovered) => (
                  <View style={styles.menuItemAndIconWrapper}>
                    <StyledText
                      testID={link.testID}
                      style={[styles.menuItemText, isHovered && styles.menuItemHovered]}
                    >
                      {link.text}
                    </StyledText>
                    <Icon name="chevron-right" color={palette.navy} width={9} />
                  </View>
                )}
              </Link>
            ))}
          <Pressable onPress={handleSignOutPress}>
            {(isHovered) => (
              <View style={[styles.menuItem, styles.menuItemAndIconWrapper]}>
                <StyledText style={[styles.menuItemText, isHovered && styles.menuItemHovered]}>
                  {t('signOut')}
                </StyledText>
                <Icon name="chevron-right" color={palette.navy} width={9} />
              </View>
            )}
          </Pressable>
        </View>
      )}
      {confirmationModal}
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    borderRadius: 20,
    backgroundColor: palette.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 15px 0px rgba(5, 144, 213, 0.20)',
    userSelect: 'none',
  },
  buttonLargeDevices: {
    width: 40,
    height: 40,
  },
  buttonMediumDevices: {
    width: 36,
    height: 36,
  },
  buttonSmallDevices: {
    width: 30,
    height: 30,
  },
  buttonHovered: {
    boxShadow: '0px 0px 20px 0px rgba(5, 144, 213, 0.25)',
  },
  dropdown: {
    width: 240,
    backgroundColor: palette.white,
    borderRadius: 5,
    right: 0,
    top: 'calc(100% + 10px)',
    position: 'absolute',
    zIndex: 1,
    ...getShadow(0, 0.6, 20),
  },
  menuItem: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderTopWidth: 1,
    borderTopColor: palette.grey2,
  },
  menuItemAndIconWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuItemHovered: {
    color: palette.blue,
    transitionProperty: 'color',
  },
  menuItemFirst: {
    borderTopWidth: 0,
  },
  menuItemText: {
    ...typography.body3,
    color: palette.navy,
    textTransform: 'capitalize',
  },
  proButton: {
    height: 40,
  },
});
