import { Props as PDFViewerProps } from 'src/features/pdfViewer';
import { MixpanelAlgorithmButton, MixpanelEvent, MixpanelService } from 'src/features/tracking';

interface Response
  extends Pick<
    PDFViewerProps,
    | 'onFullScreenClosePress'
    | 'onFullScreenPress'
    | 'onNextPress'
    | 'onPreviousPress'
    | 'onScaleDownPress'
    | 'onScaleUpPress'
  > {}

/** Tracks Mixpanel events for PDF viewer usage on Algorithm screen */
export const useAlgorithmMixpanelEvents = (): Response => {
  const trackMixpanelEvent = (button: MixpanelAlgorithmButton) => {
    MixpanelService.track(MixpanelEvent.AlgorithmInteraction, {
      Button: button,
    });
  };

  return {
    onFullScreenClosePress: () => trackMixpanelEvent('Close Full Screen'),
    onFullScreenPress: () => trackMixpanelEvent('Open Full Screen'),
    onNextPress: () => trackMixpanelEvent('Right Arrow'),
    onPreviousPress: () => trackMixpanelEvent('Left Arrow'),
    onScaleDownPress: () => trackMixpanelEvent('- sign'),
    onScaleUpPress: () => trackMixpanelEvent('+ sign'),
  };
};
