import React from 'react';
import { Trans } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { InfoModal, ButtonColors } from 'src/components';
import { StyledText } from 'src/components/StyledText';
import { SUPPORT_LINK } from 'src/constants/externalLinks';
import { i18n } from 'src/locale';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

import { BillingPeriod, Plan, BasePlanSetup, SubscriptionStatusInfo } from '../../types';

interface Props {
  subscriptionStatus: SubscriptionStatusInfo;
  planSetup: BasePlanSetup;
  cancel(): void;
  confirm(): void;
  isLoading?: boolean;
}

export const CancelRenewalModal: React.FC<Props> = ({
  subscriptionStatus,
  planSetup,
  isLoading,
  cancel,
  confirm,
}) => {
  const isPro = planSetup.plan === Plan.PRO;
  const isMonthly = planSetup.billingPeriod === BillingPeriod.MONTHLY;
  const isMulti = subscriptionStatus.numberOfLicences > 1;
  const isTrial = subscriptionStatus.inTrial;

  // translation key parts
  const tPlan = isPro ? 'Pro' : 'Basic';
  const tBilling = isMonthly ? 'Monthly' : 'Annual';
  const tLicenses = isMulti ? 'Multi' : 'Single';
  const tTrial = isTrial ? 'Trial' : 'Paid';

  return (
    <InfoModal
      header={i18n.t(`cancelRenewal:${isTrial ? 'cancelYourTrial' : 'cancelYourRenewal'}`)}
      testID="modal-cancel-renewal"
      close={cancel}
    >
      <InfoModal.Content>
        <Trans
          i18nKey={[`text${tLicenses}${tTrial}`, `text${tLicenses}${tBilling}`]}
          ns="cancelRenewal"
          values={{
            periodEnd: subscriptionStatus.currentPeriodEndsAt,
            termEnd: subscriptionStatus.currentTermEndsAt,
          }}
          components={translationComponents}
        />
      </InfoModal.Content>
      <StyledText style={styles.h2}>{i18n.t('cancelRenewal:areYouSureToCancel')}</StyledText>
      <InfoModal.Content small>
        {i18n.t(`cancelRenewal:features`, { context: isPro ? 'pro' : 'basic' })}
        {' ' + i18n.t(`cancelRenewal:${isTrial ? 'subscribeToStay' : 'keepYourSubscription'}`)}
      </InfoModal.Content>
      <InfoModal.Content small>
        <Trans
          i18nKey={[`contactSupport${tLicenses}${tPlan}${tTrial}`, `contactSupport${tLicenses}${tPlan}`]}
          ns="cancelRenewal"
          components={{
            ...translationComponents,
            p: <StyledText style={typography.body2} />,
          }}
        />
      </InfoModal.Content>
      <InfoModal.Buttons direction="row">
        <InfoModal.Button title={i18n.t('goBack')} onPress={cancel} variant="secondary" />
        <InfoModal.Button
          title={i18n.t(`cancelRenewal:${isTrial ? 'cancelTrial' : 'cancelRenewal'}`)}
          loading={isLoading}
          onPress={confirm}
          colors={confirmCancelButtonColors}
          testID="modal-submit-btn"
        />
      </InfoModal.Buttons>
    </InfoModal>
  );
};

const confirmCancelButtonColors: ButtonColors = {
  default: { primary: palette.red, secondary: palette.darkRed, content: palette.white },
  active: { primary: palette.darkRed, secondary: palette.darkRed, content: palette.white },
  disabled: { primary: palette.grey2, secondary: palette.grey3, content: palette.white },
};

const styles = StyleSheet.create({
  h2: {
    color: palette.blue,
    textAlign: 'center',
    marginBottom: 5,
    ...typography.body4Bold,
  },
  link: {
    color: palette.blue,
  },
});

const translationComponents = {
  b: <StyledText style={typography.weightBold} />,
  p: <StyledText />,
  h2: <StyledText style={[typography.body4Bold, styles.h2]} />,
  support: <Link to={SUPPORT_LINK} style={styles.link} wrapper="text" external />,
};
