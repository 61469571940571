import { useCallback, useRef, useState } from 'react';

import { SteppedForm } from 'src/components/SteppedForm';
import { useUserInfo } from 'src/features/auth/hooks';
import { usePersonalInformation } from 'src/features/profile';
import {
  PaymentDetailsRef,
  PaymentDetailsFormStep,
  ReviewPlanDetails,
  ReviewYourSubscription,
  PlanSetup,
  ReviewYourSubscriptionData,
  SubscriptionData,
} from 'src/features/subscription';
import { Nullable } from 'src/helpers';

import { useConvertManagedGroup } from '../hooks';

interface Props {
  planSetup: PlanSetup;
  id: string;
}

export const GroupConversionForm: React.FC<Props> = ({ planSetup, id }) => {
  const { mutate: convertGroup, isPending } = useConvertManagedGroup();
  const { email } = useUserInfo();
  const { data: personalInformation } = usePersonalInformation();
  const [subscriptionData, setSubscriptionData] = useState<Nullable<ReviewYourSubscriptionData>>({
    createAccount: {
      email,
    },
    completeYourProfile: {
      information: personalInformation as ReviewYourSubscriptionData['completeYourProfile']['information'],
    },
    paymentDetails: null,
    planSetup: null,
    purchasePreview: null,
  });
  const [dirty, setDirty] = useState(false);

  const paymentDetailsRef = useRef<PaymentDetailsRef>(null);

  const submitStep = useCallback((sectionData: Partial<SubscriptionData>) => {
    setSubscriptionData((state) => ({ ...state, ...sectionData }));
  }, []);

  const submitForm = async () => {
    const { token } = await paymentDetailsRef.current!.submit();
    convertGroup({
      id,
      data: {
        billingInfoTokenId: token.id,
        couponCode: subscriptionData.planSetup?.discountCode || undefined,
      },
    });
  };

  const [focusedStep, setFocusedStep] = useState<number | undefined>();

  return (
    <SteppedForm submitCallback={submitStep} focusedStep={focusedStep} setFocusedStep={setFocusedStep}>
      {(submit, stepNo) => (
        <ReviewPlanDetails
          stepNo={stepNo}
          disablePlanRelatedChanges
          disableDiscountCodeChanges
          email={email}
          data={planSetup}
          active
          isSubmitted={!!subscriptionData.planSetup}
          submit={submit}
        />
      )}
      {(submit, stepNo) => (
        <PaymentDetailsFormStep
          stepNo={stepNo}
          active={!!subscriptionData.planSetup}
          isSubmitted={!!subscriptionData.paymentDetails}
          submit={submit}
          detailsData={subscriptionData.paymentDetails}
          ref={paymentDetailsRef}
          onDirtyChange={setDirty}
        />
      )}
      {(_, stepNo) => (
        <ReviewYourSubscription
          stepNo={stepNo}
          dirty={dirty}
          active={!!subscriptionData.paymentDetails}
          data={subscriptionData as ReviewYourSubscriptionData}
          submit={submitForm}
          submitting={isPending}
        />
      )}
    </SteppedForm>
  );
};
