import { AdminDashboard } from 'src/features/adminDashboard';
import { AlgorithmsScreen, AlgorithmItemScreen } from 'src/features/algorithms';
import { AppendixItemScreen, AppendixListScreen } from 'src/features/appendix';
import { PasswordRecoveryScreen, ResetPasswordScreen, SignInScreen } from 'src/features/auth/screens';
import { CalculatorScreen } from 'src/features/calculator';
import { DICScreen, DICSearchScreen } from 'src/features/dic';
import { DrugItemScreen, DrugsListScreen, FindDrugByNameScreen } from 'src/features/drugs';
import { DxTxItemScreen, DxTxMediaScreen, DxTxListScreen } from 'src/features/dxTx';
import {
  PrintableHandoutsListScreen,
  DrugHandoutsListScreen,
  ClinicalHandoutsListScreen,
  HandoutItemScreen,
  SelectedHandoutsScreen,
} from 'src/features/handouts';
import { HomeScreen } from 'src/features/home/screens/HomeScreen';
import { NotesScreen, NoteScreen } from 'src/features/notes';
import { AccountInformationScreen, EmailChangeLandingScreen } from 'src/features/profile';
import { SearchScreen } from 'src/features/search/screens';
import { StudentReverificationScreen } from 'src/features/studentVerification/screens/StudentReverificationScreen';
import {
  SubscriptionDetailsScreen,
  SubscriptionScreen,
  SubscriptionInvitationScreen,
  GroupConversionScreen,
  GroupInvitationScreen,
  PromotionLandingScreen,
} from 'src/features/subscription';
import { ScreenMap } from 'src/navigation/types';

import { Route } from '../routes';
import { FeaturesScreen, ToolsScreen } from '../screens';

export const screenMap: ScreenMap = {
  [Route.Home]: HomeScreen,
  [Route.AccountInformation]: AccountInformationScreen,
  [Route.SignIn]: SignInScreen,
  [Route.Auth]: SignInScreen,
  [Route.DIC]: DICScreen,
  [Route.DICSearch]: DICSearchScreen,
  [Route.Algorithms]: AlgorithmsScreen,
  [Route.AlgorithmItem]: AlgorithmItemScreen,
  [Route.DxTxList]: DxTxListScreen,
  [Route.DxTxItem]: DxTxItemScreen,
  [Route.DrugHandoutsList]: DrugHandoutsListScreen,
  [Route.ClinicalHandoutsList]: ClinicalHandoutsListScreen,
  [Route.DrugHandoutItem]: HandoutItemScreen,
  [Route.ClinicalHandoutItem]: HandoutItemScreen,
  [Route.SignUp]: SubscriptionScreen,
  [Route.SubscriptionInvitation]: SubscriptionInvitationScreen,
  [Route.ResetPassword]: ResetPasswordScreen,
  [Route.PasswordRecovery]: PasswordRecoveryScreen,
  [Route.SubscriptionDetails]: SubscriptionDetailsScreen,
  [Route.AdminDashboard]: AdminDashboard,
  [Route.FindDrugByName]: FindDrugByNameScreen,
  [Route.DrugsList]: DrugsListScreen,
  [Route.DrugItem]: DrugItemScreen,
  [Route.AppendixItem]: AppendixItemScreen,
  [Route.AppendixList]: AppendixListScreen,
  [Route.EmailChangeLandingScreen]: EmailChangeLandingScreen,
  [Route.GroupInvitation]: GroupInvitationScreen,
  [Route.Notes]: NotesScreen,
  [Route.Note]: NoteScreen,
  [Route.Calculator]: CalculatorScreen,
  [Route.Search]: SearchScreen,
  [Route.SearchWeb]: SearchScreen,
  [Route.DxTxMedia]: DxTxMediaScreen,
  [Route.PrintableHandoutsList]: PrintableHandoutsListScreen,
  [Route.GroupConversion]: GroupConversionScreen,
  [Route.Features]: FeaturesScreen,
  [Route.Tools]: ToolsScreen,
  [Route.PromotionLanding]: PromotionLandingScreen,
  [Route.SelectedHandouts]: SelectedHandoutsScreen,
  [Route.ShareHandout]: SelectedHandoutsScreen,
  [Route.StudentReverification]: StudentReverificationScreen,
};
