import { useEffect } from 'react';

export const useDragScroll = (element?: HTMLDivElement | null) => {
  useEffect(() => {
    if (!element) return;
    element.style.cursor = 'grab';
    element.style.userSelect = 'none';

    const handleMouseDown = (event: MouseEvent) => {
      const startX = event.pageX - element.offsetLeft;
      const startY = event.pageY - element.offsetTop;
      const scrollLeft = element.scrollLeft;
      const scrollTop = element.scrollTop;

      const handleMouseMove = (event: MouseEvent) => {
        const y = event.pageY - element.offsetTop;
        const dY = y - startY;

        const x = event.pageX - element.offsetLeft;
        const dX = x - startX;

        element.scrollTo({ left: scrollLeft - dX, top: scrollTop - dY, behavior: 'smooth' });
      };

      const handleMouseUp = () => {
        element.style.cursor = 'grab';
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
      };

      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    };

    element.addEventListener('mousedown', handleMouseDown);

    return () => {
      element.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseDown);
    };
  }, [element]);
};
