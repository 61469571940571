import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { LoadingIndicator, StyledText } from 'src/components';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { palette, typography, fonts, ifWeb } from 'src/styles';

import { BillingPeriod } from '../../types';

interface Props {
  total?: number;
  billingPeriod: BillingPeriod;
  containerStyle?: StyleProp<ViewStyle>;
  isYearlyPrice: boolean;
  isLoading?: boolean;
}

export const TotalDue: React.FC<Props> = ({
  total,
  billingPeriod,
  containerStyle,
  isYearlyPrice,
  isLoading,
}) => {
  const { isSmallestMobile, isSmallMobile } = useDeviceInfo();

  return (
    <View style={[styles.wrapper, containerStyle]}>
      {isLoading ? (
        <View style={styles.body}>
          <LoadingIndicator displayStandbyText />
        </View>
      ) : (
        <>
          <View style={styles.header}>
            <StyledText style={[typography.body3Bold, styles.title]}>
              {i18n.t(`subscriptionProcess:${isYearlyPrice ? 'totalDue' : 'dueToday'}`)}
            </StyledText>
          </View>
          <View style={styles.body}>
            {total !== undefined && (
              <>
                <StyledText
                  style={[
                    styles.total,
                    isSmallMobile && styles.totalSmallMobile,
                    isSmallestMobile && styles.totalSmallestMobile,
                  ]}
                >
                  {i18n.format(total, 'price')}
                </StyledText>
                {isYearlyPrice && (
                  <StyledText style={[typography.primaryButton, styles.totalSecondLine]}>
                    {i18n.t('subscriptionProcess:perPeriod', {
                      period: billingPeriod === BillingPeriod.ANNUAL ? 'year' : 'month',
                    })}
                  </StyledText>
                )}
              </>
            )}
          </View>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: palette.grey1,
  },
  header: {
    backgroundColor: palette.grey2,
  },
  title: {
    margin: 6,
    textAlign: 'center',
    color: palette.blue,
  },
  body: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  total: {
    color: palette.blue,
    fontFamily: fonts.sourceSans,
    fontWeight: 'bold',
    fontSize: 55,
    lineHeight: 55,
    textAlign: 'center',
    ...ifWeb({
      wordBreak: 'break-all',
    }),
  },
  totalSmallMobile: {
    fontSize: 45,
  },
  totalSmallestMobile: {
    fontSize: 40,
  },
  totalSecondLine: {
    color: palette.blue,
  },
});
