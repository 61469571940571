import { StyleSheet, View } from 'react-native';

import { SecondaryButton } from 'src/components';
import { BoxWithHeader } from 'src/components/BoxWithHeader';
import { useUserInfo } from 'src/features/auth/hooks';
import { i18n } from 'src/locale';

import { PlanBenefitsCarousel } from './PlanBenefitsCarousel';
import { PlanBenefitsList } from './PlanBenefitsList';
import { useSubscriptionRenewalModal, useBillingInformation, useSubscriptionInfo } from '../../hooks';
import { ReactivateRenewalModal } from '../modals/ReactivateRenewalModal';

interface Props {
  handleUpgradeToPro(): void;
  handleReactivateSubscription(): void;
  hideActionButtons?: boolean;
}

export const PlanBenefits: React.FC<Props> = ({
  handleUpgradeToPro,
  handleReactivateSubscription,
  hideActionButtons,
}) => {
  const { isStudent, expiredSubscription } = useUserInfo();
  const { data: subscriptionInfo, isFetching } = useSubscriptionInfo();
  const { data: billingInformation } = useBillingInformation();
  const { showReactivateConfirmation, reactivateModal } =
    useSubscriptionRenewalModal(ReactivateRenewalModal);

  const displayReactivateRenewalInfo =
    !isStudent &&
    subscriptionInfo &&
    !subscriptionInfo.isActive &&
    !!billingInformation?.creditCardInformation;

  return (
    <View style={styles.wrapper} testID="box-plan-benefits">
      <BoxWithHeader
        title={i18n.t('planBenefits:title')}
        style={styles.box}
        rightColumnHeaderContent={
          isFetching || hideActionButtons ? null : expiredSubscription ? (
            <SecondaryButton
              size="tiny"
              title={i18n.t('subscriptionDetails:reactivateSubscription')}
              onPress={handleReactivateSubscription}
              testID="button-reactivate-subscription"
            />
          ) : displayReactivateRenewalInfo ? (
            <SecondaryButton
              size="tiny"
              title={i18n.t('subscriptionDetails:reactivateRenewal')}
              onPress={showReactivateConfirmation}
              testID="button-reactivate-renewal"
            />
          ) : subscriptionInfo?.canUserUpgradeToPro.now ? (
            <SecondaryButton
              size="tiny"
              title={i18n.t('subscriptionDetails:upgradeToPro')}
              onPress={handleUpgradeToPro}
              testID="button-upgrade-to-pro"
            />
          ) : null
        }
      >
        <PlanBenefitsList />
      </BoxWithHeader>
      <PlanBenefitsCarousel />
      {reactivateModal}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    height: '100%',
  },
  box: {
    flex: 1,
  },
});
