import * as R from 'ramda';
import { useCallback, useLayoutEffect, useState } from 'react';

import { getErrorMessageForApiError } from 'src/errorHandling/utils';
import { defaultTitles } from 'src/helpers/notifications';

import { getPurchasePreview } from '../api';
import { getRecurlyPlan } from '../helpers';
import {
  BillingPeriod,
  Plan,
  Role,
  Purchase,
  PurchasePreviewPayload,
  PurchasePreviewProps,
  BillingAddress,
} from '../types';

interface Params {
  role: Role;
  quantity: number;
  discountCode?: string;
  email: string;
  isTrial?: boolean;
  overMaxQuantity?: boolean;
  promotionId?: string;
  plan: Plan;
  billingPeriod: BillingPeriod;
  setErrors?: (errors: Record<string, string>) => void;
  billingAddress?: BillingAddress;
  onError?: (errors: Record<string, string>) => void;
  studentVerificationId?: string;
}

export const usePurchasePreview = ({
  email,
  quantity,
  role,
  discountCode,
  isTrial,
  overMaxQuantity,
  promotionId,
  billingPeriod,
  billingAddress,
  plan,
  setErrors,
  onError,
  studentVerificationId,
}: Params) => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDiscountLoading, setDiscountLoading] = useState(false);
  const [{ purchaseInputParams, purchasePreview }, setPurchasePreview] = useState<{
    purchaseInputParams?: PurchasePreviewPayload;
    purchasePreview?: Purchase;
  }>({});

  // TODO: disable the fetch for unverified students
  const fetchPurchasePreview = useCallback(
    async (purchaseInputParams: PurchasePreviewPayload, onlyDiscountChanged: boolean) => {
      setIsError(false);
      setErrors?.({});
      setDiscountLoading(true);
      if (!onlyDiscountChanged) setLoading(true);

      let errors = {};

      /**
       * Discount codes can be set to work only for specific plans, and when you try applying it to a wrong one,
       * our API will return just the error message. This can lead to preview purchase values being stale
       * and potentially confusing, so on error, we fire the request for the second time without a discount code
       */
      for (let retries = 0; retries < 2; retries++) {
        try {
          const response = await getPurchasePreview(
            retries === 0 ? purchaseInputParams : { ...purchaseInputParams, discountCode: undefined },
          );
          setPurchasePreview({
            purchaseInputParams,
            purchasePreview: response.data,
          });
          break;
        } catch (error: any) {
          // If the error is not a backend error, then we pass a mock one that
          // will trigger the generic error notification
          const errorData = error?.response?.data || {
            axios: { code: 'failed_to_show_purchase_preview', message: defaultTitles.error },
          };
          errors = {
            ...errors,
            ...R.mapObjIndexed(getErrorMessageForApiError, errorData),
          };
        }
      }

      if (!R.isEmpty(errors)) {
        setIsError(true);
        onError?.(errors);
      }

      setErrors?.(errors);
      setDiscountLoading(false);
      if (!onlyDiscountChanged) setLoading(false);
    },
    [setErrors, onError],
  );

  useLayoutEffect(() => {
    const planCode = getRecurlyPlan(role, quantity, plan, billingPeriod);

    const nextPurchaseInputParams: PurchasePreviewPayload = {
      planCode,
      quantity,
      discountCode: discountCode || undefined,
      email,
      isTrial,
      promotionId,
      billingAddress,
      verificationId: studentVerificationId,
    };

    const diff = Object.keys(nextPurchaseInputParams).filter(
      (key) => nextPurchaseInputParams[key] !== purchaseInputParams?.[key],
    );
    const onlyDiscountCodeChanged = R.equals(diff, ['discountCode']);

    if (!overMaxQuantity && email && !R.equals(purchaseInputParams, nextPurchaseInputParams)) {
      fetchPurchasePreview(nextPurchaseInputParams, onlyDiscountCodeChanged);
    }
  }, [
    fetchPurchasePreview,
    role,
    quantity,
    overMaxQuantity,
    plan,
    discountCode,
    billingPeriod,
    email,
    purchaseInputParams,
    isTrial,
    promotionId,
    billingAddress,
    studentVerificationId,
  ]);

  const purchasePreviewComponentProps: PurchasePreviewProps = {
    loading: isLoading,
    billingPeriod: billingPeriod,
    discountCode: purchaseInputParams?.discountCode ?? '',
    purchasePreview: purchasePreview,
    plan: plan,
    role,
    isTrial: isTrial,
    overMaxQuantity: overMaxQuantity,
    isError,
  };

  return { purchasePreviewComponentProps, isLoading, isDiscountLoading, purchasePreview, isError };
};
