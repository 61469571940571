import { AxiosRequestConfig } from 'axios';
import * as pdfjs from 'pdfjs-dist';

/**
 * Implementation based on
 * https://github.com/mozilla/pdf.js/blob/fbfacf88/web/pdf_print_service.js
 * https://github.com/mozilla/pdf.js/blob/fbfacf88/web/viewer.css#L1380
 */

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

export const IS_PRINTING_PDF_WITH_IFRAME =
  !('AbortController' in window) || typeof new Blob().arrayBuffer === 'undefined';
const OUTPUT_ELEMENT_ID = 'print-output';
const SCREEN_PPI = 72;

const renderPage = async (page: pdfjs.PDFPageProxy, canvas: HTMLCanvasElement, resolution = 200) => {
  const outputScale = resolution / SCREEN_PPI;
  const viewport = page.getViewport({ scale: 1 });

  canvas.width = Math.floor(viewport.width * outputScale);
  canvas.height = Math.floor(viewport.height * outputScale);

  const ctx = canvas.getContext('2d')!;

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  return page.render({
    canvasContext: ctx,
    transform: [outputScale, 0, 0, outputScale, 0, 0],
    viewport: viewport,
  }).promise;
};

const saveRenderedPage = (canvas: HTMLCanvasElement, outputElement: HTMLDivElement): Promise<string> => {
  const img = document.createElement('img');

  canvas.toBlob((blob) => {
    img.src = URL.createObjectURL(blob!);
  });

  outputElement.appendChild(img);

  return new Promise((resolve, reject) => {
    img.onload = () => resolve(img.src);
    img.onerror = () => reject();
  });
};

const cleanPreviousOutput = () => {
  const outputElement = document.getElementById(OUTPUT_ELEMENT_ID);

  if (outputElement) {
    outputElement.querySelectorAll('img').forEach((img) => {
      URL.revokeObjectURL(img.src);
    });
    outputElement.remove();
  }
};

const printWithPdfjs = async (file: Blob) => {
  const buffer = await file.arrayBuffer();
  const data = new Uint8Array(buffer);
  const pdf = await pdfjs.getDocument(data).promise;

  cleanPreviousOutput();
  const printOutput = document.createElement('div');
  const canvas = document.createElement('canvas');
  printOutput.id = OUTPUT_ELEMENT_ID;
  printOutput.appendChild(canvas);
  document.body.appendChild(printOutput);

  for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
    const page = await pdf.getPage(pageNum);

    if (pageNum === 1) {
      const pageStyle = document.createElement('style');
      const viewport = page.getViewport({ scale: 1 });
      pageStyle.textContent = `@page { size: ${viewport.width}pt ${viewport.height}pt; }`;
      printOutput.append(pageStyle);
    }

    await renderPage(page, canvas);
    await saveRenderedPage(canvas, printOutput);
  }

  window.print();
};

const printWithIframe = async (file: Blob) => {
  const blobUrl = URL.createObjectURL(file);

  const frame = document.createElement('iframe');
  frame.style.display = 'none';
  frame.src = blobUrl;
  frame.addEventListener(
    'load',
    () => {
      frame.contentWindow!.print();
    },
    { once: true },
  );

  // iframes get loaded only after getting added to the DOM
  document.body.appendChild(frame);

  window.addEventListener(
    'focus',
    () => {
      frame.remove();
    },
    { once: true },
  );
};

export const print = IS_PRINTING_PDF_WITH_IFRAME ? printWithIframe : printWithPdfjs;

export const download = async (file: Blob, filename: string) => {
  const blobUrl = URL.createObjectURL(file);

  const link = document.createElement('a');
  link.download = filename;
  link.href = blobUrl;
  link.click();
};

export const pdfRequestConfig: Partial<AxiosRequestConfig> = {
  adapter: undefined,
  responseType: 'blob',
};
