import * as R from 'ramda';

import type { ApiError } from 'src/constants/types';
import { getErrorMessageForApiError } from 'src/errorHandling/utils';

import { VETERINARIAN_ROLES } from './constants';
import { requiredPersonalInformationFields } from './forms';
import type {
  NullablePersonalInformation,
  NullablePersonalInformationPayload,
  OccupationDetails,
  OccupationDetailsErrorResponse,
  OccupationDetailsErrors,
  OccupationDetailsPayload,
  PersonalInformationErrorResponse,
  PersonalInformationErrors,
} from './types';

const bornYearNullString = 'NULL';

export const parsePersonalInformationFromBackend = (
  values: NullablePersonalInformationPayload,
): NullablePersonalInformation => {
  const { address, demographics, bornYear, ...rest } = values;

  return {
    ...rest,
    ...(address || {
      address1: null,
      address2: null,
      city: null,
      country: null,
      postalCode: null,
      state: null,
    }),
    ...(demographics || {
      practice: null,
    }),
    bornYear: bornYear === null ? bornYearNullString : bornYear.toString(),
  };
};

export const parsePersonalInformationForBackend = (
  values: NullablePersonalInformation,
): NullablePersonalInformationPayload => ({
  address: {
    address1: values.address1,
    address2: values.address2,
    city: values.city,
    country: values.country,
    postalCode: values.postalCode,
    state: values.state || null,
  },
  demographics: {
    practice: values.practice,
  },
  bornYear: values.bornYear === bornYearNullString ? null : Number(values.bornYear),
  firstName: values.firstName,
  lastName: values.lastName,
  phone: values.phone,
});

export const parsePersonalInformationErrorsFromBackend = (
  data: PersonalInformationErrorResponse,
): PersonalInformationErrors => {
  const { address = {}, demographics = {}, ...rest } = data;

  const extract = R.mapObjIndexed(getErrorMessageForApiError);
  return {
    ...extract(address as Record<string, ApiError>),
    ...extract(demographics as Record<string, ApiError>),
    ...extract(rest as Record<string, ApiError>),
  } as PersonalInformationErrors;
};

export const parseOccupationDetailsFromBackend = (data: OccupationDetailsPayload): OccupationDetails => {
  const parsed = {
    ...data,
    graduationYear: data.graduationYear?.toString() || null,
  };

  delete parsed.roleType;
  return parsed;
};

export const parseOccupationDetailsForBackend = (data: OccupationDetails): OccupationDetailsPayload => {
  let role: OccupationDetailsPayload['role'];
  let roleType: OccupationDetailsPayload['roleType'];

  if (!data.role) {
    role = null;
    roleType = null;
  } else {
    role = data.role;

    if (VETERINARIAN_ROLES.includes(data.role)) {
      roleType = 'veterinarian';
    } else {
      roleType = 'non-veterinarian';
    }
  }

  return {
    ...data,
    graduationYear: data.graduationYear ? parseInt(data.graduationYear) : null,
    role,
    roleType,
  };
};

export const parseOccupationDetailsErrorsFromBackend = (
  errors: OccupationDetailsErrorResponse,
): OccupationDetailsErrors => {
  const { roleType: _, ...rest } = errors;
  return R.mapObjIndexed(getErrorMessageForApiError, rest);
};

export const isPersonalInformationSectionFilled = (data?: NullablePersonalInformation | null) => {
  if (!data) return false;

  for (const key of requiredPersonalInformationFields) {
    if (!data[key]) return false;
  }
  return true;
};
