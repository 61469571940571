import { ComponentProps } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

import { SecondaryButton } from 'src/components';
import { StyledText } from 'src/components/StyledText';
import { GO_PRO_LINK, SUPPORT_LINK } from 'src/constants/externalLinks';
import { i18n } from 'src/locale';
import { Link } from 'src/navigation/components';
import { palette } from 'src/styles';

import { PlanDetailsBox } from './PlanDetailsBox';
import { doesPromotionHaveProPlan } from '../../helpers';
import { useSubscriptionInfo } from '../../hooks';
import { Plan } from '../../types';

interface Props {
  boxProps?: Partial<ComponentProps<typeof PlanDetailsBox>>;
  handleUpgradeToPro(): void;
  hideActionButtons?: boolean;
}

export const PlanTypeBox: React.FC<Props> = ({ boxProps, handleUpgradeToPro, hideActionButtons }) => {
  const { t } = useTranslation('subscriptionDetails');
  const { data } = useSubscriptionInfo();

  const isPro = data?.planSetup.plan === Plan.PRO;
  const displayPromoUserUpgradeInfo =
    data?.planSetup.plan === Plan.NORMAL && data?.promotion && !doesPromotionHaveProPlan(data.promotion);

  return (
    <PlanDetailsBox
      title={t('planType')}
      subtitle={i18n.t(`planVariants:${isPro ? 'pro' : 'normal'}`)}
      testID="mini-box-plan-type"
      {...boxProps}
    >
      <>
        {data?.canUserUpgradeToPro.now && (
          <>
            {!hideActionButtons && (
              <SecondaryButton
                title={t('upgradeToPro')}
                size="tiny"
                containerStyle={styles.button}
                onPress={handleUpgradeToPro}
                testID="button-upgrade-to-pro"
              />
            )}
            <Trans
              i18nKey="subscriptionDetails:discoverPro"
              components={{
                p: <StyledText style={styles.details} testID="discover-pro" />,
                goPro: <Link to={GO_PRO_LINK} wrapper="text" external style={styles.highlightedText} />,
              }}
            />
          </>
        )}
        {data?.canUserUpgradeToPro.afterLicencesReduction && (
          <Trans
            i18nKey="subscriptionDetails:contactSalesToUpgrade"
            components={{
              p: <StyledText style={styles.details} testID="contact-sales-to-upgrade" />,
              sales: <Link to={SUPPORT_LINK} wrapper="text" external style={styles.highlightedText} />,
            }}
          />
        )}
        {displayPromoUserUpgradeInfo && (
          <Trans
            i18nKey="subscriptionDetails:contactToUpgradePromotional"
            values={{ campaignName: data.promotion?.campaignName }}
            components={{
              p: <StyledText style={styles.details} testID="promo-account-upgrade-info" />,
              customerService: (
                <Link to={SUPPORT_LINK} wrapper="text" external style={styles.highlightedText} />
              ),
            }}
          />
        )}
      </>
    </PlanDetailsBox>
  );
};

const styles = StyleSheet.create({
  highlightedText: {
    color: palette.blue,
  },
  details: {
    color: palette.grey5,
    lineHeight: 20,
  },
  button: {
    marginBottom: 5,
  },
});
