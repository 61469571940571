import React from 'react';

import { PlumbsLogo } from 'src/assets/images/logos';
import { useIsSignedIn } from 'src/features/auth/hooks';
import { isWeb } from 'src/helpers/common';
import { Route } from 'src/navigation';
import { Link } from 'src/navigation/components/Link';

export const MenuLogo: React.FC = () => {
  const isSignedIn = useIsSignedIn();

  const logo = <PlumbsLogo width={138} height={24} />;

  return isWeb ? (
    <Link to={{ route: isSignedIn ? Route.Home : Route.SignIn }} testID="home-link">
      {logo}
    </Link>
  ) : (
    logo
  );
};
