import React from 'react';

import { i18n } from 'src/locale';

import { SubscriptionReview, Props } from './SubscriptionReview';
import { SubscriptionSection as Section } from '../SubscriptionSections';

/**
 * contents of <Section> were extracted to a separate component, so they execute
 * only when the section is active (i.e. all data is filled)
 */
export const ReviewYourSubscription: React.FC<Props> = (props) => {
  return (
    <Section
      title={i18n.t('subscriptionProcess:reviewSubscription')}
      active={props.active}
      stepNo={props.stepNo}
      testID="review-your-subscription-section"
    >
      <SubscriptionReview {...props} />
    </Section>
  );
};
