import React, { ComponentProps, useEffect, useRef, useState } from 'react';

import { CircleIconButton, FullScreenTemplate } from 'src/components';
import { MixpanelEvent, MixpanelService } from 'src/features/tracking';
import { i18n } from 'src/locale';
import { Route } from 'src/navigation';
import { ScreenProps } from 'src/navigation/types';

import { NoteEditor, RemoveNoteConfirmModal } from '../components';
import { getMixpanelNoteParams, isContentNote } from '../helpers';
import { useNote, useRemoveNote, useSaveNote } from '../hooks';
import { EditorRef, ExistingNoteScreenParams } from '../types';

type Props = ScreenProps<Route.Note>;

type EditorProps = ComponentProps<typeof NoteEditor>;

export const NoteScreen: React.FC<Props> = ({ route: { params }, navigation }) => {
  const initialIdRef = useRef((params as ExistingNoteScreenParams).id);
  const [showWarning, setShowWarning] = useState(false);

  const editing = 'id' in params;
  const id = editing ? params.id : undefined;

  const { data: note, isFetching } = useNote(id, { enabled: editing });

  const saveNote = useSaveNote();
  const removeNote = useRemoveNote();
  const editorRef = useRef<EditorRef>(null);

  useEffect(() => {
    if (initialIdRef.current && note) {
      MixpanelService.track(
        MixpanelEvent.NoteViewed,
        getMixpanelNoteParams(isContentNote(note) ? note : undefined),
      );
    }
  }, [note]);

  const save: EditorProps['save'] = async ({ value, titleValue }) => {
    saveNote(
      {
        note: editing
          ? { ...note!, note: value, title: titleValue! }
          : params.contentId
          ? {
              note: value,
              title: params.sectionTitle,
              contentId: params.contentId,
              contentTitle: params.contentTitle,
              contentType: params.contentType,
              section: params.section,
            }
          : {
              note: value,
              title: titleValue!,
            },
      },
      {
        onSuccess: (note) => {
          if (!editing) {
            navigation.setParams({ id: note.id });
          }
        },
      },
    );
  };

  const showRemoveWarning = () => {
    editorRef.current?.blur();
    setShowWarning(true);
  };
  const dismissRemoveWarning = () => {
    setShowWarning(false);
  };
  const remove = async () => {
    if (note) {
      removeNote(note);
    }
    dismissRemoveWarning();
    navigation.goBack();
  };

  const removeButton = (
    <CircleIconButton
      name="trash"
      width={18}
      strokeWidth={1.3}
      onPress={showRemoveWarning}
      nonScalingStroke
    />
  );

  return (
    <FullScreenTemplate
      title={i18n.t('screens:note')}
      showBackButton
      actionButtonRight={removeButton}
      keyboardAvoiding
      noScroll
    >
      {isFetching ? null : editing ? (
        note ? (
          isContentNote(note) ? (
            <NoteEditor
              title={note.title}
              contentTitle={note.contentTitle}
              sectionId={note.section}
              initialValue={note.note}
              contentType={note.contentType}
              contentId={note.contentId}
              save={save}
              editorRef={editorRef}
            />
          ) : (
            <NoteEditor
              title={note.title}
              initialValue={note.note}
              save={save}
              titleEditable
              editorRef={editorRef}
            />
          )
        ) : null
      ) : params.contentId !== null ? (
        <NoteEditor
          contentTitle={params.contentTitle}
          title={params.sectionTitle}
          sectionId={params.section}
          contentType={params.contentType}
          contentId={params.contentId}
          save={save}
          editorRef={editorRef}
        />
      ) : (
        <NoteEditor title="" save={save} titleEditable editorRef={editorRef} />
      )}

      {showWarning && (
        <RemoveNoteConfirmModal confirm={remove} cancel={dismissRemoveWarning} type="remove" />
      )}
    </FullScreenTemplate>
  );
};
