import {
  useRecurly,
  Elements,
  RecurlyProvider,
  CardNumberElementChangeEvent,
} from '@recurly/react-recurly';
import { useFormik } from 'formik';
import * as R from 'ramda';
import { CSSProperties, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { RecurlyError, TokenPayload } from 'recurly__recurly-js';

import { InputItem, Select, StyledText, Subheader, Switch } from 'src/components';
import { COUNTRIES_WITH_STATES } from 'src/constants/constants';
import type { PersonalAddress } from 'src/features/profile';
import { SentryService } from 'src/features/tracking';
import { showNotification } from 'src/helpers';
import { Yup } from 'src/helpers/validation';
import { useCountriesList } from 'src/hooks/queries/countries';
import { i18n } from 'src/locale';
import { EnvService } from 'src/services';
import { palette, typography } from 'src/styles';

import { CardCvvElement, CardMonthElement, CardNumberElement, CardYearElement } from './RecurlyElements';
import { getCreditCardNumberPlaceholder, getCvvPlaceholder } from '../../helpers';
import { usePromotion } from '../../hooks';
import { BillingInformation, CardEvents, CardType } from '../../types';
import { SubscriptionSection as Section } from '../SubscriptionSections';

type RecurlyErrorRecord = { field: string; messages: string[] };
type ValidationRecurlyError = RecurlyError & {
  details: RecurlyErrorRecord[];
};

const isRecurlyValidationError = (obj: any): obj is ValidationRecurlyError => 'details' in obj;

export interface Props {
  addressData?: PersonalAddress;
  detailsData?: BillingInformation | null;
  disabled?: boolean;
  onDirtyChange?: (dirty: boolean) => void;
  billingAddressInfo?: string;
}

export interface PaymentDetailsRef {
  submit: () => Promise<BillingInformation & { token: TokenPayload }>;
}

const initialAddressState = {
  address1: '',
  address2: '',
  country: '',
  state: '',
  postalCode: '',
  city: '',
};

const initValues = {
  ...initialAddressState,
  firstName: '',
  lastName: '',
  expMonth: '',
  expYear: '',
  lastFour: '',
  cardType: '' as CardType,
  cvv: '',
};

const requiredString = Yup.string().required().nullable();
const validationSchema = Yup.object().shape({
  firstName: requiredString,
  lastName: requiredString,
  address1: requiredString,
  address2: Yup.string().nullable(),
  postalCode: requiredString,
  city: requiredString,
  country: requiredString,
  state: Yup.string().when('country', {
    is: (value: string) => COUNTRIES_WITH_STATES.includes(value),
    then: requiredString,
  }),
});

const getRecurlyErrorMessage = (field: string, recurlyMessage: string) => {
  switch (recurlyMessage) {
    case 'is invalid': {
      const messageKey = `recurly:${field}Error`;
      return i18n.exists(messageKey) ? i18n.t(messageKey) : i18n.t('recurly:isInvalid');
    }
    case "can't be blank":
    case "can't be empty":
      return i18n.t('validation:thisFieldIsRequired');
    case 'must be three digits':
      return i18n.t('recurly:mustBeThreeDigits');
    case 'must be four digits':
      return i18n.t('recurly:mustBeFourDigits');
    case 'is too long (maximum is 50 characters)':
      return i18n.t('validation:maxTextLength', { max: 50 });
    case 'is too long (maximum is 20 characters)':
      return i18n.t('validation:maxTextLength', { max: 20 });
    case 'must be less than 2050':
      return i18n.t('recurly:isInvalid');
    default:
      return recurlyMessage;
  }
};

const cardTypeTranslations = {
  discover: 'Discover',
  union_pay: 'UnionPay',
  master: 'Mastercard',
  american_express: CardType.AmericanExpress,
  elo: 'Elo',
  visa: 'Visa',
  jcb: 'JCB',
  diners_club: CardType.DinersClub,
  hipercard: 'Hipercard',
  unknown: CardType.Unknown,
};

export const BasePaymentDetails = forwardRef<PaymentDetailsRef, Props>(
  ({ addressData, detailsData, disabled, onDirtyChange, billingAddressInfo }, ref) => {
    const { data: promotion } = usePromotion();
    const showPromoInfo = !!promotion?.specialInstruction;
    const { countriesOptions, getStatesOptions } = useCountriesList();

    const {
      values,
      setValues,
      handleChange,
      handleSubmit,
      errors,
      setErrors,
      setFieldError,
      touched,
      setFieldTouched,
      dirty,
      initialValues: initFormikValues,
    } = useFormik({
      initialValues: detailsData
        ? {
            ...detailsData.creditCardInformation,
            ...detailsData.billingAddress,
            cvv: 'dummy',
          }
        : initValues,
      validationSchema,
      enableReinitialize: true,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit: () => {},
    });

    const setValue = handleChange as (field: string) => (value: string) => void;
    const onBlur = (field: string) => () => setFieldTouched(field);

    useEffect(() => {
      onDirtyChange?.(dirty);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dirty]);

    const {
      firstName,
      lastName,
      lastFour,
      cardType,
      address1,
      address2,
      postalCode,
      city,
      country,
      state,
    } = values;
    const cardPlaceholder = lastFour ? getCreditCardNumberPlaceholder(lastFour, cardType) : '';
    const cvvPlaceholder = cardType ? getCvvPlaceholder(cardType) : '';

    const [isCopyAddressDataChecked, setIsCopyAddressDataChecked] = useState(false);
    useEffect(() => {
      setValues({
        ...values,
        ...R.pipe(
          R.pick(['address1', 'address2', 'city', 'country', 'state', 'postalCode']),
          R.mapObjIndexed(R.defaultTo('')),
        )(isCopyAddressDataChecked ? addressData : initFormikValues),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCopyAddressDataChecked]);

    const [recurlyErrors, setRecurlyErrors] = useState<Record<string, string>>({});
    const onRecurlyElementChange =
      (fieldName: string) =>
      ({ valid, length }: CardEvents) => {
        setValue(fieldName)(length ? Math.random().toString().slice(-length) : ''); // we don't know the exact value but we need to trigger formik to change state to dirty

        setRecurlyErrors(
          valid ? R.omit([fieldName]) : R.assoc(fieldName, i18n.t(`recurly:${fieldName}Error`)),
        );
      };
    const onCardNumberChange = (event: CardNumberElementChangeEvent) => {
      onRecurlyElementChange('lastFour')(event);
      setValue('lastFour')(event.lastFour);
      setValue('cardType')(cardTypeTranslations[event.brand] || CardType.Unknown);
    };

    const formRef = useRef<HTMLFormElement>(null);
    const recurly = useRecurly();
    useImperativeHandle(ref, () => ({
      submit: () =>
        new Promise((resolve, reject) => {
          handleSubmit();
          recurly.token(formRef.current!, (error, token) => {
            if (error) {
              if (!isRecurlyValidationError(error)) {
                SentryService.captureMessage('Unrecognized Recurly error', {
                  error,
                });
                showNotification({ type: 'error' });
                reject(error);
                return;
              }

              error.details.forEach((errorRecord) => {
                const field =
                  {
                    number: 'lastFour',
                    month: 'expMonth',
                    year: 'expYear',
                    first_name: 'firstName',
                    last_name: 'lastName',
                    postal_code: 'postalCode',
                  }[errorRecord.field] || errorRecord.field;
                const message = getRecurlyErrorMessage(field, errorRecord.messages[0]);

                setFieldError(field, message);
                setRecurlyErrors(R.assoc(field, message));
              });

              SentryService.captureMessage('Recurly validation error', {
                error,
              });

              reject(error);
            } else {
              setErrors({});
              resolve({
                token,
                billingAddress: { city, state, address1, address2, country, postalCode },
                creditCardInformation: {
                  firstName,
                  lastName,
                  lastFour,
                  cardType,
                  // not passing values from formik to avoid confusion (they hold random values)
                  expMonth: '',
                  expYear: '',
                },
              });
            }
          });
        }),
    }));

    return (
      <form
        ref={formRef}
        data-testid="payment-details-form-inputs"
        data-is-disabled={`${disabled ? 'disabled' : 'enabled'}`}
      >
        <Subheader
          title={i18n.t('subscriptionProcess:creditCardInformation')}
          icon="padlock"
          wrapperStyle={showPromoInfo && styles.headerNoMargin}
        />
        {showPromoInfo && (
          <View>
            <StyledText style={[typography.body1, styles.promoInstruction]} testID="credit-card-copy">
              {promotion.specialInstruction}
            </StyledText>
          </View>
        )}
        <Section.Form>
          <Section.Row>
            <Section.Item testID="credit-card-number-input">
              <CardNumberElement
                label={i18n.t(`recurly:cardLabel`)}
                placeholder={cardPlaceholder}
                onChange={onCardNumberChange}
                disabled={disabled}
                touched={touched.lastFour}
                onBlur={onBlur('lastFour')}
                error={recurlyErrors.lastFour}
              />
            </Section.Item>
            <Section.Item>
              <InputItem
                readOnly={disabled}
                value={firstName}
                onChangeText={setValue('firstName')}
                label={i18n.t(`recurly:nameLabel`)}
                placeholder={i18n.t(`recurly:namePlaceholder`)}
                dataSet={{ recurly: 'first_name' }}
                touched={touched.firstName}
                onBlur={onBlur('firstName')}
                error={errors.firstName}
                testID="payment-details-first-name"
              />
            </Section.Item>
            <Section.Item>
              <InputItem
                readOnly={disabled}
                value={lastName}
                onChangeText={setValue('lastName')}
                label={i18n.t(`recurly:surnameLabel`)}
                placeholder={i18n.t(`recurly:surnamePlaceholder`)}
                dataSet={{ recurly: 'last_name' }}
                touched={touched.lastName}
                onBlur={onBlur('lastName')}
                error={errors.lastName}
                testID="payment-details-last-name"
              />
            </Section.Item>
          </Section.Row>
          <Section.Row>
            <Section.Form>
              <Section.Row>
                <Section.Item testID="credit-card-exp-month-input">
                  <CardMonthElement
                    label={i18n.t(`recurly:monthLabel`)}
                    placeholder={detailsData?.creditCardInformation?.expMonth.toString().padStart(2, '0')}
                    onChange={onRecurlyElementChange('expMonth')}
                    disabled={disabled}
                    touched={touched.expMonth}
                    onBlur={onBlur('expMonth')}
                    error={recurlyErrors.expMonth}
                  />
                </Section.Item>
              </Section.Row>
              <Section.Row>
                <Section.Item testID="credit-card-exp-year-input">
                  <CardYearElement
                    label={i18n.t(`recurly:yearLabel`)}
                    placeholder={detailsData?.creditCardInformation?.expYear.toString().slice(-2)}
                    onChange={onRecurlyElementChange('expYear')}
                    disabled={disabled}
                    touched={touched.expYear}
                    onBlur={onBlur('expYear')}
                    error={recurlyErrors.expYear}
                  />
                </Section.Item>
              </Section.Row>
            </Section.Form>
            <Section.Item testID="credit-card-cvv-input">
              <CardCvvElement
                label={i18n.t(`recurly:cvvLabel`)}
                placeholder={cvvPlaceholder}
                onChange={onRecurlyElementChange('cvv')}
                disabled={disabled}
                touched={touched.cvv}
                onBlur={onBlur('cvv')}
                error={recurlyErrors.cvv}
              />
            </Section.Item>
          </Section.Row>
        </Section.Form>
        <Subheader title={i18n.t('subscriptionProcess:billingAddress')} spreader />
        {!!billingAddressInfo && (
          <View>
            <StyledText style={[typography.body1, styles.billingAddressInfo]}>
              {billingAddressInfo}
            </StyledText>
          </View>
        )}
        {!!addressData && !disabled && (
          <Section.Item style={styles.copyAddressSection}>
            <Switch checked={isCopyAddressDataChecked} onChange={setIsCopyAddressDataChecked} />
            <StyledText style={styles.copyAddressInfo}>
              {i18n.t('subscriptionProcess:useAboveAddress')}
            </StyledText>
          </Section.Item>
        )}
        <Section.Form>
          <Section.Row>
            <Section.Item>
              <InputItem
                readOnly={disabled}
                onChangeText={setValue('address1')}
                touched={touched.address1}
                onBlur={onBlur('address1')}
                label={i18n.t(`recurly:address1Label`)}
                placeholder={i18n.t(`recurly:address1Placeholder`)}
                dataSet={{ recurly: 'address1' }}
                error={errors.address1}
                value={address1}
                testID="address1-input"
              />
            </Section.Item>
            <Section.Item>
              <InputItem
                readOnly={disabled}
                onChangeText={setValue('address2')}
                touched={touched.address2}
                onBlur={onBlur('address2')}
                label={i18n.t(`recurly:address2Label`)}
                placeholder={i18n.t(`recurly:address2Placeholder`)}
                dataSet={{ recurly: 'address2' }}
                error={errors.address2}
                value={address2}
                testID="address2-input"
              />
            </Section.Item>
            <InputItem
              readOnly={disabled}
              onChangeText={setValue('city')}
              touched={touched.city}
              onBlur={onBlur('city')}
              label={i18n.t(`recurly:cityLabel`)}
              placeholder={i18n.t(`recurly:cityPlaceholder`)}
              dataSet={{ recurly: 'city' }}
              error={errors.city}
              value={city}
              testID="city-input"
            />
          </Section.Row>
          <Section.Row>
            <Section.Item>
              <Select
                readOnly={disabled}
                touched={touched.country}
                onBlur={onBlur('country')}
                placeholder={i18n.t('recurly:countryPlaceholder')}
                label={i18n.t('recurly:countryLabel')}
                options={countriesOptions}
                value={country}
                error={errors.country}
                onChange={({ value }) => setValues({ ...values, country: value || '', state: '' })}
                testID="country-select"
                searchType="internal"
              />
              <input value={country || ''} readOnly data-recurly="country" style={cssStyles.hiddenInput} />
            </Section.Item>
            <Section.Item>
              <InputItem
                readOnly={disabled}
                onChangeText={setValue('postalCode')}
                touched={touched.postalCode}
                onBlur={onBlur('postalCode')}
                label={i18n.t(`recurly:postalCodeLabel`)}
                placeholder={i18n.t(`recurly:postalCodePlaceholder`)}
                dataSet={{ recurly: 'postal_code' }}
                error={errors.postalCode}
                value={postalCode}
                testID="postal-code-input"
              />
            </Section.Item>
            {COUNTRIES_WITH_STATES.includes(country) && (
              <Section.Item>
                <Select
                  readOnly={disabled}
                  touched={touched.state}
                  onBlur={onBlur('state')}
                  placeholder={i18n.t('recurly:statePlaceholder')}
                  label={i18n.t('recurly:stateLabel')}
                  options={getStatesOptions(country)}
                  value={state}
                  error={errors.state}
                  onChange={R.pipe(R.propOr('', 'value'), setValue('state'))}
                  testID="state-select"
                  searchType="internal"
                />
                <input value={state || ''} readOnly data-recurly="state" style={cssStyles.hiddenInput} />
              </Section.Item>
            )}
          </Section.Row>
        </Section.Form>
      </form>
    );
  },
);

export const PaymentDetailsForm: typeof BasePaymentDetails = forwardRef((props, ref) => (
  <RecurlyProvider
    publicKey={EnvService.getEnv('RECURLY_PUBLIC_KEY')!}
    required={['cvv', 'address1', 'country', 'city', 'postal_code']}
  >
    <Elements>
      <BasePaymentDetails {...props} ref={ref} />
    </Elements>
  </RecurlyProvider>
));

const cssStyles: Record<string, CSSProperties> = {
  hiddenInput: { display: 'none' },
};

const styles = StyleSheet.create({
  copyAddressSection: { flexDirection: 'row', alignItems: 'center' },
  copyAddressInfo: { marginLeft: 10, color: palette.grey8 },
  promoInstruction: { marginBottom: 24, color: palette.grey5 },
  headerNoMargin: { marginBottom: 0 },
  billingAddressInfo: {
    color: palette.grey5,
    marginTop: -16,
    marginBottom: 16,
  },
});

//test card number 4111-1111-1111-1111
