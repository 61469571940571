import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { InputMessage } from 'src/components/InputMessage';
import { LoadingIndicator } from 'src/components/LoadingIndicator';
import { Slider } from 'src/components/Slider';
import { StyledText } from 'src/components/StyledText';
import { SUPPORT_LINK } from 'src/constants/externalLinks';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Link } from 'src/navigation/components';
import { ifWeb, palette, typography } from 'src/styles';

import { SliderDescription } from './SliderDescription';
import {
  NORMAL_SUBSCRIPTION_QUANTITY_THRESHOLDS,
  PRO_SUBSCRIPTION_QUANTITY_THRESHOLDS,
} from '../../constants';
import { BillingPeriod, Plan, Purchase } from '../../types';

interface Props {
  defaultQuantity?: number;
  onQuantityChange: (quantity: number) => void;
  purchasePreview?: Purchase;
  billingPeriod: BillingPeriod;
  minQuantity: number;
  maxQuantity: number;
  error?: string;
  plan: Plan;
  loading?: boolean;
  disabled?: boolean;
  needFewerLicensesMessage?: React.ReactNode;
}

export const UsersQuantitySlider: React.FC<Props> = ({
  defaultQuantity,
  onQuantityChange,
  purchasePreview,
  billingPeriod,
  minQuantity,
  maxQuantity,
  error,
  plan,
  loading,
  disabled,
  needFewerLicensesMessage,
}) => {
  const [value, setValue] = useState<number>(defaultQuantity || minQuantity);
  const { isDesktop } = useDeviceInfo();

  const maxValue = maxQuantity + 1;
  const minValue = minQuantity;
  const overMaxQuantity = value >= maxValue;

  useEffect(() => {
    setValue(defaultQuantity || minQuantity);
  }, [defaultQuantity, minQuantity]);

  const quantityThresholds =
    plan === Plan.NORMAL ? NORMAL_SUBSCRIPTION_QUANTITY_THRESHOLDS : PRO_SUBSCRIPTION_QUANTITY_THRESHOLDS;

  const minValueOverMax = minValue >= maxValue;

  const marks: Record<number, any> = {
    [minValue]: {},
    [maxValue]: {},
    ...quantityThresholds.reduce((obj, threshold) => {
      if (threshold <= minValue || threshold >= maxValue) {
        return obj;
      }
      return { ...obj, [threshold]: {} };
    }, {}),
  };

  return (
    <View style={styles.wrapper} testID="plan-details-slider-component">
      <View style={styles.sliderWrapper}>
        <Slider
          marks={minValueOverMax || value > maxValue ? {} : marks}
          min={minValueOverMax ? 0 : minValue} // 0 to move the handler to the right side
          max={minValueOverMax ? minValue : Math.max(maxValue, value)}
          value={value}
          getHandleText={(value) => `${value}${value > maxQuantity ? '+' : ''}`}
          onChange={setValue}
          onAfterChange={onQuantityChange}
          disabled={disabled || minValueOverMax}
        />
      </View>
      {loading ? (
        <LoadingIndicator size="small" displayStandbyText />
      ) : (
        <SliderDescription
          purchasePreview={purchasePreview}
          billingPeriod={billingPeriod}
          min={minValueOverMax ? undefined : minValue}
          max={minValueOverMax ? minValue : `${maxValue}+`}
          overMaxQuantity={overMaxQuantity}
        />
      )}
      {error && <InputMessage message={error} type="error" />}
      {minValue > 1 &&
        (needFewerLicensesMessage || (
          <View style={isDesktop ? styles.needFewerWrapper : styles.narrowFewerWrapper}>
            <StyledText style={typography.body1}>
              {i18n.t('subscriptionProcess:needFewerLicenses')}
            </StyledText>
            <Link to={SUPPORT_LINK} external>
              {(isHovered: boolean) => (
                <StyledText style={[styles.link, typography.body1, isHovered && styles.linkHover]}>
                  {i18n.t('subscriptionProcess:contact')}
                </StyledText>
              )}
            </Link>
          </View>
        ))}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: { marginBottom: 10 },
  sliderWrapper: {
    paddingLeft: 12,
    paddingRight: 16,
    marginTop: 20,
    marginBottom: 8,
  },
  needFewerWrapper: {
    flexDirection: 'row',
    marginTop: 64,
  },
  narrowFewerWrapper: {
    flexDirection: 'row',
    marginTop: 16,
    marginBottom: -2,
  },
  link: {
    color: palette.blue,
  },
  linkHover: {
    color: palette.blue2,
    ...ifWeb({
      textDecorationLine: 'underline',
    }),
  },
});
