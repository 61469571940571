import dayjs from 'dayjs';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { StyledText } from 'src/components';
import { TRIAL_DURATION_IN_DAYS } from 'src/constants/constants';
import { HELP_CANCEL_SUBSCRIPTION_LINK } from 'src/constants/externalLinks';
import { formatDateWithMonths } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { Link } from 'src/navigation/components';
import { palette, typography } from 'src/styles';

import { SubscriptionSection as Section } from './SubscriptionSections';

interface Props {}
interface ItemProps {
  title: string;
  content: string | React.ReactNode;
  fullWidth?: boolean;
  testID?: string;
}

export const TrialFAQ: React.FC<Props> = () => {
  const { t } = useTranslation('subscriptionProcess');
  const { isDesktop } = useDeviceInfo();

  const trialStartDate = formatDateWithMonths(dayjs());
  const trialEndDate = formatDateWithMonths(dayjs().add(TRIAL_DURATION_IN_DAYS, 'd'));

  return (
    <Section title={t('faq')} active>
      <View style={styles.itemsWrapper}>
        <Item
          title={t('faqWillIBeChargedQ')}
          fullWidth={!isDesktop}
          testID="faq-will-be-charged"
          content={
            <StyledText>
              <Trans
                i18nKey="subscriptionProcess:faqWillIBeChargedA"
                values={{ start: trialStartDate, end: trialEndDate }}
                components={{
                  highlight: <StyledText style={styles.highlightedText} />,
                }}
              />
            </StyledText>
          }
        />
        <Item
          title={t('faqHowCancelQ')}
          fullWidth={!isDesktop}
          testID="faq-how-cancel"
          content={
            <StyledText>
              <Trans
                i18nKey="subscriptionProcess:faqHowCancelA"
                components={{
                  help: (
                    <Link
                      to={HELP_CANCEL_SUBSCRIPTION_LINK}
                      style={styles.highlightedText}
                      wrapper="text"
                      external
                    />
                  ),
                }}
              />
            </StyledText>
          }
        />
        <Item
          title={t('faqWhatAfterQ')}
          fullWidth={!isDesktop}
          testID="faq-what-after"
          content={
            <StyledText>
              <Trans
                i18nKey="subscriptionProcess:faqWhatAfterA"
                values={{ date: trialEndDate }}
                components={{
                  highlight: <StyledText style={styles.highlightedText} />,
                }}
              />
            </StyledText>
          }
        />
      </View>
    </Section>
  );
};

const Item: React.FC<ItemProps> = ({ title, content, fullWidth, testID }) => (
  <View style={[styles.item, fullWidth && styles.itemFullWidth]} testID={testID}>
    <StyledText style={[styles.title, typography.body3Bold]}>{title}</StyledText>
    <StyledText style={typography.body2}>{content}</StyledText>
  </View>
);

const styles = StyleSheet.create({
  itemsWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  item: {
    padding: 20,
    borderWidth: 1,
    borderColor: palette.grey2,
    width: '31%',
    marginBottom: 16,
  },
  itemFullWidth: {
    width: '100%',
  },
  title: {
    color: palette.navy,
    marginBottom: 16,
  },
  highlightedText: {
    color: palette.blue,
  },
});
