import { client } from 'src/api/client';
import { convertObjectToSnakeCase } from 'src/helpers';

import type { FavoritesFilter, FavoritesIds, FavoritesItemsListResponse } from './types';

export const FAVORITES_URL = '/accounts/user/favorites/';

export const fetchFavoritesList = async (filter: FavoritesFilter) => {
  const response = await client.request<FavoritesItemsListResponse>({
    url: `${FAVORITES_URL}all/`,
    method: 'GET',
    params: convertObjectToSnakeCase({
      page: 1,
      pageSize: 999999,
      contentType: filter === 'all' ? undefined : filter,
    }),
  });

  return response.data.results;
};

export const fetchFavoritesIds = async () => {
  const response = await client.request<FavoritesIds>({
    url: FAVORITES_URL,
    method: 'GET',
  });

  return response.data;
};

export const addToFavorites = async (id: string) =>
  client.request({
    url: FAVORITES_URL,
    method: 'POST',
    data: {
      contentId: id,
    },
  });

export const removeFromFavorites = async (id: string) =>
  client.request({
    url: `${FAVORITES_URL}${id}/`,
    method: 'DELETE',
  });
