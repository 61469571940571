import { StyleProp, ViewStyle } from 'react-native';
import Svg, { Mask, Path, Rect } from 'react-native-svg';

import { definitions } from 'src/assets/icons/definitions';
import { palette } from 'src/styles';

interface Props {
  style?: StyleProp<ViewStyle>;
  width?: number;
  variant?: 'gradient' | 'onHoverGradient' | 'inverted' | 'outlined';
}

const MASK_ID = 'proBadgeMask';

const ProText: React.FC<{ fill: string }> = ({ fill }) => (
  <Path
    d="m12 8.61c0 .81-.47 1.25-1.44 1.25h-.92v-2.37h.82c.96 0 1.54.3 1.54 1.12zm13.57-1.14c-1.07 0-1.74.93-1.74 2.49s.67 2.57 1.74 2.57 1.74-1 1.74-2.57-.68-2.49-1.74-2.49zm-11.9 1.14c0-1.9-1.39-2.52-3.1-2.52h-2.69v7.82h1.76v-2.65h1c1.63 0 3.03-.82 3.03-2.65zm7.57 5.3-1.77-3.13c.8991-.3784 1.458-1.287 1.39-2.26 0-1.86-1.36-2.43-3-2.43h-2.86v7.82h1.76v-2.82h1l1.5 2.82zm7.88-3.91c0-2.54-1.44-4-3.55-4s-3.57 1.41-3.57 4 1.44 4.1 3.56 4.1 3.56-1.58 3.56-4.1zm-11.42-2.51h-.91v2.2h.91c.93 0 1.43-.4 1.43-1.17s-.5-1.03-1.43-1.03z"
    fill={fill}
    fillRule="evenodd"
  />
);

export const ProBadge: React.FC<Props> = ({ variant = 'gradient', style, width = 37 }) => {
  const height = width / 1.85;
  const gradientId = variant === 'gradient' ? 'proGradient' : 'pvdGradient';

  return (
    <Svg viewBox="0 0 37 20" style={style} width={width} height={height}>
      {variant === 'inverted' && (
        <>
          <Mask id={MASK_ID}>
            <Rect width="100%" height="100%" fill="#fff" />
            <ProText fill="#000" />
          </Mask>
          <Rect width="100%" height="100%" rx={5} fill={palette.white} mask={`url(#${MASK_ID})`} />
        </>
      )}
      {['gradient', 'onHoverGradient'].includes(variant) && (
        <>
          {definitions[gradientId]}
          <Rect width="100%" height="100%" rx={5} fill={`url(#${gradientId})`} />
          <ProText fill={palette.white} />
        </>
      )}
      {variant === 'outlined' && (
        <>
          <Rect
            width={35}
            height={18}
            rx={5}
            strokeWidth={2}
            stroke={palette.white}
            fill="none"
            x={1}
            y={1}
          />
          <ProText fill={palette.white} />
        </>
      )}
    </Svg>
  );
};
