import * as R from 'ramda';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect } from 'react';
import { Trans } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import { BaseButton, PrimaryButton, StyledText, Subheader } from 'src/components';
import { SUPPORT_LINK } from 'src/constants/externalLinks';
import { StudentVerification } from 'src/features/studentVerification/components/StudentVerification';
import { useStudentVerificationContext } from 'src/features/studentVerification/context/StudentVerificationContext';
import { MixpanelEvent, MixpanelService } from 'src/features/tracking';
import { capitalizeString, kebabCaseToNormal } from 'src/helpers';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Link } from 'src/navigation/components';
import { ifWeb, palette, typography } from 'src/styles';

import { DiscountCodeInput } from './DiscountCodeInput';
import { SelectorsSection } from './SelectorsSection';
import { UsersQuantitySlider } from './UsersQuantitySlider';
import { billingPlanSelectorOptions, planSelectorOptions, roleSelectorOptions } from '../../constants';
import {
  hasPlanSetupMonthlyAndAnnualPaymentOptions,
  promotionAllowsPlanSelection,
  hasRoleNormalAndProVariants,
} from '../../helpers';
import { usePlanDetailsForm, usePromotion } from '../../hooks';
import { Plan, PlanSetup, Purchase, SubscriptionBaseProps } from '../../types';
import { PurchasePreview } from '../PurchasePreview';
import { SubscriptionSection as Section } from '../SubscriptionSections';

interface Props extends SubscriptionBaseProps {
  data: PlanSetup;
  minQuantity?: number;
  email: string;
  disablePlanChange?: boolean;
  dispatchMixpanelEvent?: boolean;
  disablePlanRelatedChanges?: boolean;
  disableDiscountCodeChanges?: boolean;
}

export interface ReviewPlanDetailsRef {
  setErrors: (errors: Record<string, string>) => void;
}

const ReviewPlanDetailsContent = forwardRef<ReviewPlanDetailsRef, Props>(
  (
    {
      submit,
      isSubmitted,
      data,
      onDirtyChange,
      minQuantity = 1,
      email,
      disablePlanChange,
      dispatchMixpanelEvent,
      disablePlanRelatedChanges,
      disableDiscountCodeChanges,
    },
    ref,
  ) => {
    const onSubmit = useCallback(
      (params: { planSetup: PlanSetup; purchasePreview: Purchase }) => {
        dispatchMixpanelEvent &&
          MixpanelService.track(MixpanelEvent.ReviewPlanDetails, {
            "Your Plan's Number of Users Plan Details": params.planSetup.quantity,
            "Your Plumb's Plan Details": capitalizeString(kebabCaseToNormal(params.planSetup.plan)),
            'Your Role/Occupation Plan Details': capitalizeString(kebabCaseToNormal(params.planSetup.role)),
          });
        submit(params);
      },
      [submit, dispatchMixpanelEvent],
    );

    const { setRole, isStudentVerified, verificationId, isUserRedirectedFromEmailNotification } =
      useStudentVerificationContext();

    const { data: promotionData } = usePromotion();

    const {
      values,
      errors,
      setValue,
      isStudent,
      maxQuantity,
      handlePlanChange,
      handleRoleChange,
      handleBillingPeriodChange,
      handleSubmit,
      overMaxQuantity,
      setErrors,
      purchasePreview,
      purchasePreviewComponentProps,
      isDiscountLoading,
      isPurchasePreviewLoading,
    } = usePlanDetailsForm({
      data,
      minQuantity,
      disablePlanRelatedChanges,
      onSubmit,
      email,
      promotionId: promotionData?.id,
      studentVerificationId: isStudentVerified ? verificationId : undefined,
    });

    const { quantity, role, plan, billingPeriod, discountCode } = values;

    const dirty = !R.equals(data, values);

    const { isTablet, isDesktop } = useDeviceInfo();

    useEffect(() => {
      onDirtyChange?.(dirty);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dirty]);

    useLayoutEffect(() => {
      setRole(role);
    }, [role, setRole]);

    useImperativeHandle(ref, () => ({
      setErrors,
    }));

    const isPromotion = !!promotionData;

    const isRoleChangeDisabled =
      disablePlanRelatedChanges || isPromotion || isUserRedirectedFromEmailNotification;
    const isPlanChangeDisabled =
      disablePlanRelatedChanges ||
      disablePlanChange ||
      !hasRoleNormalAndProVariants(role) ||
      (isPromotion && !promotionAllowsPlanSelection(promotionData));
    const isBillingPeriodChangeDisabled =
      disablePlanRelatedChanges ||
      (isPromotion && (promotionData.proAnnualPrice === null || promotionData.proMonthlyPrice === null));
    const isQuantityChangeDisabled = disablePlanRelatedChanges || isPromotion;
    const displayPlanChangeInfo =
      hasRoleNormalAndProVariants(role) && isPlanChangeDisabled && plan === Plan.PRO && !isPromotion;

    const isSubmitButtonDisabled =
      (!!errors && !R.isEmpty(errors)) ||
      (isSubmitted && !dirty) ||
      isPurchasePreviewLoading ||
      isDiscountLoading;

    const isSubmitButtonDisplayed = !isStudent || isStudentVerified;

    const displayBillingPeriodSelector = hasPlanSetupMonthlyAndAnnualPaymentOptions(role, plan, quantity);

    return (
      <>
        {!isPromotion && (
          <>
            <Subheader title={i18n.t('subscriptionProcess:yourRole')} />
            <Section.Item
              style={[
                styles.selector,
                isRoleChangeDisabled &&
                  ((isDesktop && styles.roleSelectorDisabledDesktop) ||
                    (isTablet && styles.roleSelectorDisabledTablet)),
              ]}
            >
              <SelectorsSection
                selected={role}
                onPress={handleRoleChange}
                options={roleSelectorOptions}
                disabled={isRoleChangeDisabled}
                testID="role-occupation-selector"
              />
            </Section.Item>
          </>
        )}
        {isStudent ? (
          <>
            <StudentVerification email={email} />
            {isStudentVerified && <PurchasePreview {...purchasePreviewComponentProps} taxNotCalculated />}
          </>
        ) : (
          <>
            <Section.Form breakpoint={!isStudent && 'desktop'}>
              <Section.Row breakpoint="desktop">
                <Subheader title={i18n.t('subscriptionProcess:yourPlumbPlan')} />
                <Section.Item style={isTablet && isPlanChangeDisabled && styles.planSelectorHalf}>
                  <SelectorsSection
                    selected={plan}
                    options={planSelectorOptions}
                    onPress={handlePlanChange}
                    disabled={isPlanChangeDisabled}
                    testID="plan-selector"
                  />
                  {displayPlanChangeInfo && (
                    <Trans
                      i18nKey="subscriptionProcess:wantToChangeYourPlan"
                      components={{
                        p: <StyledText style={typography.body2} />,
                        contact: (
                          <Link
                            to={SUPPORT_LINK}
                            external
                            hoverStyles={styles.linkHover}
                            style={styles.link}
                          />
                        ),
                      }}
                    />
                  )}
                </Section.Item>
                {displayBillingPeriodSelector && (
                  <>
                    <Subheader title={i18n.t('subscriptionProcess:billingCycle')} />
                    <Section.Item
                      style={isTablet && isBillingPeriodChangeDisabled && styles.planSelectorHalf}
                    >
                      <SelectorsSection
                        selected={billingPeriod}
                        options={billingPlanSelectorOptions}
                        onPress={handleBillingPeriodChange}
                        disabled={isBillingPeriodChangeDisabled}
                        testID="billing-selector"
                      />
                    </Section.Item>
                  </>
                )}
                <Section.Item>
                  <Subheader title={i18n.t('subscriptionProcess:numOfUsers')} />
                </Section.Item>
                <Section.Item>
                  <UsersQuantitySlider
                    onQuantityChange={setValue('quantity')}
                    defaultQuantity={quantity}
                    purchasePreview={purchasePreview}
                    billingPeriod={billingPeriod}
                    minQuantity={minQuantity}
                    maxQuantity={maxQuantity}
                    error={errors?.quantity}
                    plan={plan}
                    loading={isPurchasePreviewLoading}
                    disabled={isQuantityChangeDisabled}
                  />
                </Section.Item>
              </Section.Row>
              <Section.Row breakpoint={isStudent ? false : 'desktop'}>
                {(discountCode || !disableDiscountCodeChanges) && !isPromotion && (
                  <Section.Item>
                    <DiscountCodeInput
                      setDiscountCode={setValue('discountCode')}
                      savedValue={discountCode}
                      alwaysVisible={isStudent}
                      loading={isDiscountLoading}
                      disabled={overMaxQuantity || disableDiscountCodeChanges}
                      error={errors?.discountCode}
                      label={i18n.t('subscriptionProcess:discountCode')}
                    />
                  </Section.Item>
                )}
                <View style={styles.planSummary}>
                  <PurchasePreview {...purchasePreviewComponentProps} taxNotCalculated />
                </View>
              </Section.Row>
            </Section.Form>
          </>
        )}
        <Section.ButtonsWrapper>
          {overMaxQuantity ? (
            <PrimaryButton
              title={i18n.t('contactUs')}
              onPress={() => {
                window.open(SUPPORT_LINK, '_blank');
              }}
            />
          ) : isSubmitButtonDisplayed ? (
            <BaseButton
              title={i18n.t(isSubmitted ? 'saveChanges' : 'next')}
              onPress={handleSubmit}
              disabled={isSubmitButtonDisabled}
              testID="plan-details-next-btn"
              variant="gradient"
            />
          ) : null}
        </Section.ButtonsWrapper>
      </>
    );
  },
);

export const ReviewPlanDetails = forwardRef<ReviewPlanDetailsRef, Props>((props, ref) => {
  return (
    <Section
      title={i18n.t('subscriptionProcess:reviewPlanDetails')}
      active={props.active}
      checked={props.isSubmitted}
      stepNo={props.stepNo}
      testID="review-plan-details-section"
    >
      {props.active && <ReviewPlanDetailsContent {...props} ref={ref} />}
    </Section>
  );
});

const styles = StyleSheet.create({
  selector: {
    zIndex: 1,
  },
  roleSelectorDisabledTablet: {
    width: '53%',
  },
  roleSelectorDisabledDesktop: {
    width: '25.5%',
  },
  planSelectorHalf: {
    width: '53%',
  },
  planSummary: {
    marginTop: 20,
  },
  link: {
    color: palette.blue,
  },
  linkHover: {
    color: palette.blue2,
    ...ifWeb({
      textDecorationLine: 'underline',
    }),
  },
});
