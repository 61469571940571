import dayjs from 'dayjs';
import * as R from 'ramda';
import React, { useCallback, useEffect, useRef, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import type { TokenPayload } from 'recurly__recurly-js';

import { ConfirmationScreen, Container, FullScreenTemplate, Header } from 'src/components';
import { SteppedForm } from 'src/components/SteppedForm';
import { RecaptchaError } from 'src/constants/errors';
import { isPersonalInformationSectionFilled, personalInformationEmptyState } from 'src/features/profile';
import {
  StudentVerificationProvider,
  useStudentVerificationContext,
} from 'src/features/studentVerification/context/StudentVerificationContext';
import type {
  StudentVerificationParams,
  StudentVerificationDetails,
} from 'src/features/studentVerification/types';
import { SentryService, MixpanelEvent, MixpanelService } from 'src/features/tracking';
import { Nullable, showNotification, closeNotification, isObjectNotEmpty } from 'src/helpers';
import { clearRedirectTo } from 'src/navigation/utils';
import { AppDispatch } from 'src/state/store';
import { ifWeb } from 'src/styles';

import { CompleteYourProfile, CompleteYourProfileRef } from './CompleteYourProfile';
import { CreateAnAccount, CreateAnAccountRef } from './CreateAnAccount';
import { PaymentDetailsRef } from './PaymentDetails/PaymentDetailsForm';
import { PaymentDetailsFormStep } from './PaymentDetails/PaymentDetailsFormStep';
import { Recaptcha, RecaptchaRef } from './Recaptcha';
import { ReviewPlanDetails, ReviewPlanDetailsRef } from './ReviewPlanDetails/ReviewPlanDetails';
import { ReviewPlanDetailsTrial } from './ReviewPlanDetails/ReviewPlanDetailsTrial';
import { ReviewYourSubscription } from './ReviewYourSubscription/ReviewYourSubscription';
import { TrialFAQ } from './TrialFAQ';
import { TrialInfoBox } from './TrialInfoBox';
import {
  getBasePlanSetup,
  getErrorMessagesForSubscriptionForm,
  trackSubscriptionStepGAEvent,
} from '../helpers';
import { usePromotion, usePurchaseSubscription } from '../hooks';
import { clearPromotionParams } from '../state';
import {
  Role,
  Plan,
  PlanSetup,
  SubscriptionData,
  CreateAccount,
  SubscriptionFormSectionIndex,
} from '../types';

interface Props {
  initialPlanSetup?: PlanSetup;
  isTrial?: boolean;
  subscriptionInvitationId?: string;
  studentVerificationDetails?: StudentVerificationDetails;
}

const initialState: Nullable<SubscriptionData> = {
  createAccount: null,
  planSetup: null,
  purchasePreview: null,
  completeYourProfile: null,
  paymentDetails: null,
};

const Form: React.FC<Props> = ({
  initialPlanSetup,
  isTrial,
  subscriptionInvitationId,
  studentVerificationDetails,
}) => {
  const [subscriptionData, setSubscriptionData] = useState<Nullable<SubscriptionData>>(initialState);
  const { verificationId, studentData } = useStudentVerificationContext();
  const [isSubmitting, setSubmitting] = useState(false);
  const { mutate: purchaseSubscription, isSuccess: isSubmitted } = usePurchaseSubscription();

  const dispatch: AppDispatch = useDispatch();
  const recaptchaRef = useRef<RecaptchaRef>(null);

  const createAnAccountRef = useRef<CreateAnAccountRef>(null);
  const reviewPlanDetailsRef = useRef<ReviewPlanDetailsRef>(null);
  const completeYourProfileRef = useRef<CompleteYourProfileRef>(null);
  const paymentDetailsRef = useRef<PaymentDetailsRef>(null);

  const { t } = useTranslation('subscriptionProcess');

  useEffect(() => {
    MixpanelService.track(MixpanelEvent.SubscriptionPageView);

    return () => {
      closeNotification();
    };
  }, []);

  useLayoutEffect(() => {
    if (isSubmitted) {
      window.scrollTo({ top: 0 });
      closeNotification();

      if (promotion) {
        dispatch(clearPromotionParams());
        clearRedirectTo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted]);

  useEffect(() => {
    if (studentVerificationDetails) {
      const { email } = studentVerificationDetails;
      setStudentProfileData(studentVerificationDetails);
      setSubscriptionData((data) => ({
        ...data,
        createAccount: data.createAccount || {
          email,
          confirmEmail: email,
          password: '',
          confirmPassword: '',
        },
      }));
    }
  }, [studentVerificationDetails]);

  useEffect(() => {
    if (studentData) {
      setStudentProfileData(studentData);
    }
  }, [studentData]);

  const submitStep = useCallback(
    (sectionData: Partial<SubscriptionData>) => {
      setSubscriptionData((prev) => {
        trackSubscriptionStepGAEvent(sectionData, prev);
        return { ...prev, ...sectionData };
      });
    },
    [setSubscriptionData],
  );

  const [dirtyStates, setDirtyStates] = useState<Record<string, boolean>>({});
  const onDirtyChange = useCallback(
    (stepName: string) => (newDirty: boolean) => setDirtyStates(R.assoc(stepName, newDirty)),
    [setDirtyStates],
  );

  const { data: promotion } = usePromotion();

  const [focusedStep, setFocusedStep] = useState<number | undefined>();

  if (isSubmitted) {
    const { email } = subscriptionData.createAccount as CreateAccount;

    return (
      <ConfirmationScreen
        icon="dollar-checkmark"
        title={t('thankYouForSubscribing')}
        body={t('subscriptionConfirmed', { email })}
        email={email}
      />
    );
  }

  const handleSubscriptionPurchase = (captchaToken: string, billingInfoToken: TokenPayload) => {
    purchaseSubscription(
      {
        subscriptionData: subscriptionData as SubscriptionData,
        billingInfoToken,
        captchaToken,
        promotionParams: promotion?.params,
        isTrial,
        studentVerificationId: verificationId,
        subscriptionInvitationId,
      },
      {
        onError: (error: any) => {
          const errors = error?.response?.data;

          if (!errors) {
            showNotification({ type: 'error', autoHide: false });
            return;
          }

          const { accountErrors, demographicsErrors, otherErrors, planErrors, profileErrors } =
            getErrorMessagesForSubscriptionForm(errors);

          createAnAccountRef.current?.setErrors(accountErrors);
          reviewPlanDetailsRef.current?.setErrors(planErrors);
          completeYourProfileRef.current?.setInformationErrors(profileErrors);
          completeYourProfileRef.current?.setOccupationErrors(demographicsErrors);

          if (isObjectNotEmpty(accountErrors)) {
            setFocusedStep(SubscriptionFormSectionIndex.Account);
          } else if (isObjectNotEmpty(planErrors)) {
            setFocusedStep(SubscriptionFormSectionIndex.PlanDetails);
          } else if (isObjectNotEmpty(profileErrors) || isObjectNotEmpty(demographicsErrors)) {
            setFocusedStep(SubscriptionFormSectionIndex.Profile);
          } else if (isObjectNotEmpty(otherErrors)) {
            const messages = Object.values(otherErrors).join('\n');
            showNotification({ type: 'error', autoHide: false, title: messages || '' });
          }
        },
        onSettled: () => {
          setSubmitting(false);
        },
      },
    );
  };

  const submit = async () => {
    try {
      const captchaToken = await recaptchaRef.current!.execute();
      // user may need to solve a captcha challenge, so we start loading after it resolves
      setSubmitting(true);
      const { token } = await paymentDetailsRef.current!.submit();
      handleSubscriptionPurchase(captchaToken, token);
    } catch (err: any) {
      if (err instanceof RecaptchaError) {
        showNotification({ type: 'error' });
        SentryService.captureException(err);
      }
      setSubmitting(false);
    }
  };

  const isProTrial = initialPlanSetup?.plan === Plan.PRO && isTrial;
  const planDetailsData = subscriptionData.planSetup || initialPlanSetup;

  const isCompleteYourProfileSectionFilled =
    isPersonalInformationSectionFilled(subscriptionData.completeYourProfile?.information) &&
    !!subscriptionData.completeYourProfile?.occupation;

  const isCreateAnAccountSectionFilled =
    !!subscriptionData.createAccount?.email &&
    !!subscriptionData.createAccount?.confirmEmail &&
    !!subscriptionData.createAccount?.password &&
    !!subscriptionData.createAccount?.confirmPassword;

  const setStudentProfileData = (data: StudentVerificationParams) => {
    const { firstName, country, lastName, organizationName, birthDate, planCode } = data;
    const { role } = getBasePlanSetup(planCode);
    const year = dayjs(birthDate).get('year');
    // @ts-ignore
    setSubscriptionData((data) => ({
      ...data,
      completeYourProfile: {
        information: {
          ...personalInformationEmptyState,
          firstName,
          lastName,
          country,
          bornYear: year.toString(),
        },
        occupation: {
          activity: role === Role.PHARMACY_STUDENT ? 'student-pharmacy' : 'student-veterinary-medical',
          schoolAttending: 'other',
          schoolAttendingOther: organizationName,
          graduationYear: undefined!,
        },
      },
    }));
  };

  return (
    <FullScreenTemplate isLoading={!planDetailsData}>
      <Header
        title={t(isProTrial ? 'proTrialTitle' : isTrial ? 'trialTitle' : 'subscription')}
        subtitle={
          promotion
            ? promotion.pageIntroCopyDefinition
            : isProTrial
            ? t('proTrialSubtitle')
            : isTrial
            ? t('trialSubtitle')
            : undefined
        }
      />
      <Container verticalPadding>
        {isTrial && <TrialInfoBox isPro={initialPlanSetup?.plan === Plan.PRO} />}
        <SteppedForm
          stepStyle={styles.zIndexUnset}
          submitCallback={submitStep}
          focusedStep={focusedStep}
          setFocusedStep={setFocusedStep}
        >
          {(submitStep, stepNo) => (
            <CreateAnAccount
              stepNo={stepNo}
              ref={createAnAccountRef}
              data={subscriptionData.createAccount}
              submit={submitStep}
              isSubmitted={isCreateAnAccountSectionFilled}
              onDirtyChange={onDirtyChange('account')}
              active
              initialEmail={promotion?.params.email}
            />
          )}
          {planDetailsData &&
            ((submitStep, stepNo) =>
              isTrial ? (
                <ReviewPlanDetailsTrial
                  stepNo={stepNo}
                  active={isCreateAnAccountSectionFilled}
                  isSubmitted={!!subscriptionData.planSetup}
                  onDirtyChange={onDirtyChange('plan')}
                  submit={submitStep}
                  data={planDetailsData}
                  email={subscriptionData.createAccount?.email || ''}
                />
              ) : (
                <ReviewPlanDetails
                  stepNo={stepNo}
                  ref={reviewPlanDetailsRef}
                  submit={submitStep}
                  isSubmitted={!!subscriptionData.planSetup}
                  active={isCreateAnAccountSectionFilled}
                  data={planDetailsData}
                  onDirtyChange={onDirtyChange('plan')}
                  email={subscriptionData.createAccount?.email || ''}
                  dispatchMixpanelEvent
                  disablePlanRelatedChanges={!!subscriptionInvitationId}
                />
              ))}
          {(submitStep, stepNo) => (
            <CompleteYourProfile
              stepNo={stepNo}
              ref={completeYourProfileRef}
              data={subscriptionData.completeYourProfile}
              submit={submitStep}
              isSubmitted={isCompleteYourProfileSectionFilled}
              active={!!subscriptionData.planSetup}
              onDirtyChange={onDirtyChange('profile')}
            />
          )}
          {(submitStep, stepNo) => (
            <PaymentDetailsFormStep
              stepNo={stepNo}
              ref={paymentDetailsRef}
              addressData={subscriptionData.completeYourProfile?.information}
              submit={submitStep}
              isSubmitted={!!subscriptionData.paymentDetails}
              detailsData={subscriptionData.paymentDetails}
              active={isCompleteYourProfileSectionFilled}
              onDirtyChange={onDirtyChange('payment')}
              dispatchMixpanelEvent
              isTrial={isTrial}
            />
          )}
          {(_, stepNo, scrollToStep) => (
            <ReviewYourSubscription
              stepNo={stepNo}
              scrollToStep={scrollToStep}
              data={subscriptionData as SubscriptionData}
              active={!!subscriptionData.paymentDetails}
              submit={submit}
              submitting={isSubmitting}
              dirty={R.values(dirtyStates).some(R.identity)}
              isTrial={isTrial}
              promotionId={promotion?.params.promoId}
              studentVerificationId={verificationId}
            />
          )}
        </SteppedForm>
        <Recaptcha ref={recaptchaRef} />
        {isTrial && <TrialFAQ />}
      </Container>
    </FullScreenTemplate>
  );
};

export const SubscriptionForm: React.FC<Props> = (props) => (
  <StudentVerificationProvider>
    <Form {...props} />
  </StudentVerificationProvider>
);

const styles = StyleSheet.create({
  zIndexUnset: {
    ...ifWeb({
      zIndex: 'auto',
    }),
  },
});
