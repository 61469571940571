import { useEffect, useMemo } from 'react';

import { ContentItemBase, ContentListItemCommonProps } from 'src/constants/types';
import { AlgorithmCard } from 'src/features/algorithms/components';
import { AppendixCard } from 'src/features/appendix/components';
import { DrugCard } from 'src/features/drugs/components';
import { DxTxCard } from 'src/features/dxTx/components';
import { HandoutCard } from 'src/features/handouts/components';
import { SentryService } from 'src/features/tracking';
import { isAlgorithm, isAppendix, isDrug, isDxTx, isHandout } from 'src/helpers';

const getComponent = (
  item: ContentItemBase,
):
  | React.NamedExoticComponent<ContentListItemCommonProps<any>>
  | React.FC<ContentListItemCommonProps<any>>
  | null => {
  if (isDrug(item)) {
    return DrugCard;
  }
  if (isHandout(item)) {
    return HandoutCard;
  }
  if (isAppendix(item)) {
    return AppendixCard;
  }
  if (isAlgorithm(item)) {
    return AlgorithmCard;
  }
  if (isDxTx(item)) {
    return DxTxCard;
  }

  return null;
};

export function CardStrategy<T extends ContentItemBase>(props: ContentListItemCommonProps<T>) {
  const { item } = props;

  const Component = useMemo(() => getComponent(item), [item]);

  useEffect(() => {
    if (!Component) {
      SentryService.captureException("Couldn't find a card comopnent for given content type", {
        providedElement: item,
      });
    }
  }, [Component, item]);

  return Component ? <Component {...props} /> : null;
}
