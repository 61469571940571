import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { StyledText } from 'src/components/StyledText';
import { palette, typography } from 'src/styles';

interface Props {
  title: string;
  subtitle?: string;
  style?: StyleProp<ViewStyle>;
  inColumn?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  testID?: string;
}

export const PlanDetailsBox: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  subtitle,
  children,
  inColumn,
  isFirst,
  isLast,
  style,
  testID,
}) => {
  return (
    <View
      style={[
        styles.wrapper,
        inColumn && styles.wrapperInColumn,
        isFirst && styles.wrapperFirst,
        isLast && styles.wrapperLast,
        style,
      ]}
      testID={testID}
    >
      <StyledText style={styles.title} testID="plan-details-box-title">
        {title}
      </StyledText>
      {!!subtitle && (
        <StyledText style={styles.subtitle} testID="plan-details-box-subtitle">
          {subtitle}
        </StyledText>
      )}
      <View style={styles.contentWrapper}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: 32,
    paddingHorizontal: 32,
    borderRightWidth: 1,
    borderColor: palette.grey2,
    flex: 1,
  },
  wrapperInColumn: {
    paddingHorizontal: 0,
    borderRightWidth: 0,
    borderBottomWidth: 1,
    flex: 'none',
  },
  wrapperFirst: {
    paddingLeft: 0,
  },
  wrapperLast: {
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  title: {
    color: palette.blue,
    marginBottom: 5,
    ...typography.body2Bold,
  },
  subtitle: {
    marginBottom: 5,
    ...typography.body2,
  },
  contentWrapper: {
    alignItems: 'flex-start',
  },
});
