import { WebSocketMessage } from 'src/constants/types/websocket';
import { convertObjectToSnakeCase } from 'src/helpers/functions';

import { EnvService } from './EnvService';

type Subscription = {
  remove(): void;
};
type WebSocketEvent = keyof WebSocketEventMap;
type Handler<E> = (ev: E) => void;
type Handlers = {
  [Key in WebSocketEvent]: Set<Handler<WebSocketEventMap[Key]>>;
};

class WebSocketService {
  socket?: WebSocket;
  url: string;
  handlers: Handlers;

  constructor() {
    this.url = EnvService.getEnv('WEBSOCKET')!;
    this.handlers = {
      open: new Set(),
      message: new Set(),
      close: new Set(),
      error: new Set(),
    };
  }

  subscribe<E extends WebSocketEvent>(event: E, handler: Handler<WebSocketEventMap[E]>): Subscription {
    this.handlers[event].add(handler);

    return {
      remove: () => {
        this.handlers[event].delete(handler);
      },
    };
  }

  connect() {
    this.socket = new WebSocket(this.url);

    for (const eventName of Object.keys(this.handlers)) {
      this.socket.addEventListener(eventName, (e) => {
        for (const handler of this.handlers[eventName]) {
          handler(e);
        }
      });
    }
  }

  close(code?: number) {
    this.socket?.close(code);
    this.socket = undefined;
  }

  send(message: WebSocketMessage) {
    if (this.socket?.readyState === this.socket?.OPEN) {
      const parsedMessage = JSON.stringify(convertObjectToSnakeCase(message));
      this.socket?.send(parsedMessage);
    }
  }
}

const instance = new WebSocketService();

export { instance as WebSocketService };
