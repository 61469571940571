import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, StyleSheet, View } from 'react-native';

import { BaseButton, TextArea } from 'src/components';
import { useNativeKeyboard } from 'src/hooks/useNativeKeyboard';

import { useHandoutsPrintAndDownload } from '../hooks';
import type { ShareHandoutsFormProps } from '../types';

interface Props extends ShareHandoutsFormProps {}

export const PrintHandouts: React.FC<Props> = ({ onShareSuccess }) => {
  const { t } = useTranslation('handouts');
  const { generateAndPrint, loading, noteValue, selectedHandouts, setNoteValue } =
    useHandoutsPrintAndDownload({ action: 'print', onSuccess: onShareSuccess });
  const { isKeyboardOpen } = useNativeKeyboard();

  return (
    <View testID="print-handouts-form">
      <KeyboardAvoidingView behavior="padding" enabled={isKeyboardOpen} keyboardVerticalOffset={420}>
        <TextArea
          label={t('addInstructions')}
          placeholder={t('addInstructionsPlaceholder')}
          readOnly={loading}
          onChangeText={(note: string) => setNoteValue(note)}
          value={noteValue}
          testID="input-print-content"
        />
        <BaseButton
          title={t('print', { count: selectedHandouts.length })}
          variant="gradient"
          icon="chevron-right-circle"
          containerStyle={styles.submitButton}
          titleStyle={styles.submitButtonTitle}
          loading={loading}
          onPress={generateAndPrint}
          testID="print-handout-btn"
        />
      </KeyboardAvoidingView>
    </View>
  );
};

const styles = StyleSheet.create({
  submitButton: {
    alignSelf: 'flex-start',
    marginTop: 28,
  },
  submitButtonTitle: {
    textTransform: 'none',
  },
});
