import RCSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { CSSProperties } from 'react';
import { StyleSheet, View } from 'react-native';

import { palette } from 'src/styles';

import { Slider as BaseComponent } from './Slider';
import { StyledText } from './StyledText';

type Props = React.ComponentProps<typeof BaseComponent>;

export const Slider: React.FC<Props> = ({
  min,
  max,
  marks,
  value,
  disabled,
  onChange,
  onAfterChange,
  getHandleText = (value) => value.toString(),
}) => {
  return (
    <RCSlider
      marks={marks}
      min={min}
      max={max}
      value={value}
      handle={SliderHandle(getHandleText(value))}
      onChange={onChange}
      onAfterChange={onAfterChange}
      dotStyle={cssStyles.dot}
      railStyle={cssStyles.rail}
      trackStyle={cssStyles.track}
      style={cssStyles.slider}
      disabled={disabled}
    />
  );
};

const SliderHandle = (text: string) => (props: any) => {
  const { dragging: _, ...rest } = props;

  return (
    <RCSlider.Handle {...rest} data-testid="slider-handle">
      <View style={[styles.handle, rest.disabled && styles.handleDisabled]}>
        <StyledText style={styles.text}>{text}</StyledText>
      </View>
    </RCSlider.Handle>
  );
};

const cssStyles: Record<string, CSSProperties> = {
  slider: {
    backgroundColor: palette.transparent,
  },
  dot: {
    height: 15,
    bottom: -5,
    width: 0,
    borderWidth: 0,
    borderRightWidth: 2,
    borderColor: palette.grey5,
    opacity: 0.4,
    marginLeft: 2,
  },
  rail: {
    backgroundColor: palette.grey4,
    height: 3,
    width: '101%',
  },
  track: {
    display: 'none',
  },
};

const styles = StyleSheet.create({
  handle: {
    backgroundColor: palette.blue,
    height: 28,
    width: 28,
    borderRadius: 14,
    top: -10,
    left: -10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  handleDisabled: {
    backgroundColor: palette.grey5,
  },
  text: {
    fontWeight: 'bold',
    color: palette.white,
  },
});
