import { debounce } from 'debounce';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { BaseButton, Subheader } from 'src/components';
import {
  useOccupationDetailsForm,
  personalInformationForm,
  personalInformationEmptyState,
  PersonalInformationErrors,
  PersonalInformation,
  ProfileInformation,
  isPersonalInformationSectionFilled,
} from 'src/features/profile';
import { MixpanelService, MixpanelEvent } from 'src/features/tracking';
import { useUniversalForm } from 'src/hooks/forms/useUniversalForm';
import { useCountriesList } from 'src/hooks/queries/countries';
import { i18n } from 'src/locale';

import { SubscriptionSection as Section } from './SubscriptionSections';
import { usePromotion } from '../hooks';
import { SubscriptionBaseProps } from '../types';

interface Props extends SubscriptionBaseProps {
  data: ProfileInformation | null;
  submitLabel?: string;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  error?: boolean | PersonalInformationErrors;
  additionalContent?: React.ReactNode;
}

export interface CompleteYourProfileRef {
  setInformationErrors: (errors: Record<string, string>) => void;
  setOccupationErrors: (errors: Record<string, string>) => void;
}

export const CompleteYourProfileForm = forwardRef<CompleteYourProfileRef, Props>(
  (
    {
      submit,
      data,
      isSubmitted,
      onDirtyChange,
      submitLabel,
      submitLoading,
      submitDisabled,
      additionalContent,
    },
    ref,
  ) => {
    const [sectionData, setSectionData] = useState<Partial<ProfileInformation>>(
      data || {
        information: undefined,
        occupation: undefined,
      },
    );
    const { data: countries = [] } = useCountriesList();

    const { data: promotion } = usePromotion();

    const defaultPersonalInfo = promotion
      ? {
          ...personalInformationEmptyState,
          firstName: promotion.params.firstName,
          lastName: promotion.params.lastName,
          country: countries.find((c) => c.name === promotion.params.country)?.code,
        }
      : personalInformationEmptyState;

    const {
      renderField: renderFieldInformation,
      handleSubmit: handleSubmitInformation,
      setErrors: setInformationErrors,
      dirty: personalInformationDirty,
    } = useUniversalForm(
      sectionData.information || defaultPersonalInfo,
      (values) => editPersonalData(values as PersonalInformation, 'information'),
      personalInformationForm,
    );
    const {
      renderField: renderFieldOccupation,
      handleSubmit: handleSubmitOccupation,
      setErrors: setOccupationErrors,
      dirty: occupationDetailsDirty,
    } = useOccupationDetailsForm(sectionData.occupation, (values) =>
      editPersonalData(values, 'occupation'),
    );

    const handleSubmit = useMemo(() => debounce(submit, 200), [submit]);

    useEffect(() => {
      if (isPersonalInformationSectionFilled(sectionData.information) && !!sectionData.occupation) {
        handleSubmit({ completeYourProfile: sectionData as ProfileInformation });
      }
      // If `handleSubmit` is passed to the array, then the form gets submited
      // whenever it changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionData]);

    function editPersonalData<K extends keyof ProfileInformation>(
      values: ProfileInformation[K],
      variant: K,
    ) {
      setSectionData({ ...sectionData, [variant]: { ...values } });
    }

    const submitSection = () => {
      handleSubmitInformation();
      handleSubmitOccupation();
      MixpanelService.track(MixpanelEvent.CompleteYourProfile);
    };

    const buttonLabel: string = submitLabel ?? i18n.t(isSubmitted ? 'saveChanges' : 'next');

    const dirty = personalInformationDirty || occupationDetailsDirty;
    useEffect(() => {
      onDirtyChange?.(dirty);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dirty]);
    useImperativeHandle(ref, () => ({
      setInformationErrors,
      setOccupationErrors,
    }));

    return (
      <>
        <Subheader title={i18n.t('accountInformation:personalInformation')} />
        <Section.Form>
          <Section.Row>
            <Section.Item>{renderFieldInformation('firstName')}</Section.Item>
            <Section.Item>{renderFieldInformation('lastName')}</Section.Item>
          </Section.Row>
          <Section.Row>
            <Section.Item>{renderFieldInformation('phone')}</Section.Item>
            <Section.Item>{renderFieldInformation('bornYear')}</Section.Item>
          </Section.Row>
        </Section.Form>
        <Subheader title={i18n.t('subscriptionProcess:addressInformation')} spreader />
        <Section.Form>
          <Section.Row>
            <Section.Item>{renderFieldInformation('practice')}</Section.Item>
            <Section.Item>{renderFieldInformation('address1')}</Section.Item>
            <Section.Item>{renderFieldInformation('address2')}</Section.Item>
            <Section.Item>{renderFieldInformation('city')}</Section.Item>
          </Section.Row>
          <Section.Row>
            <Section.Item>{renderFieldInformation('country')}</Section.Item>
            <Section.Item>{renderFieldInformation('postalCode')}</Section.Item>
            <Section.Item>{renderFieldInformation('state')}</Section.Item>
          </Section.Row>
        </Section.Form>
        <Subheader title={i18n.t('accountInformation:occupationDetails')} spreader />
        <Section.Form>
          <Section.Row>
            <Section.Item>{renderFieldOccupation('activity')}</Section.Item>
            <Section.Item>{renderFieldOccupation('activityOther')}</Section.Item>
            <Section.Item>{renderFieldOccupation('pharmacy')}</Section.Item>
            <Section.Item>{renderFieldOccupation('pharmacyOther')}</Section.Item>
            <Section.Item>{renderFieldOccupation('graduationYear')}</Section.Item>
            <Section.Item>{renderFieldOccupation('role')}</Section.Item>
            <Section.Item>{renderFieldOccupation('roleOther')}</Section.Item>
            <Section.Item>{renderFieldOccupation('schoolGraduated')}</Section.Item>
            <Section.Item>{renderFieldOccupation('schoolGraduatedOther')}</Section.Item>
          </Section.Row>
          <Section.Row>
            <Section.Item>{renderFieldOccupation('schoolAttending')}</Section.Item>
            <Section.Item>{renderFieldOccupation('schoolAttendingOther')}</Section.Item>
            <Section.Item>{renderFieldOccupation('yearsOfPractice')}</Section.Item>
            <Section.Item>{renderFieldOccupation('numberOfVeterinarians')}</Section.Item>
            <Section.Item>{renderFieldOccupation('practiceType')}</Section.Item>
            <Section.Item>{renderFieldOccupation('practiceTypeOther')}</Section.Item>
            <Section.Item>{renderFieldOccupation('practiceOwnership')}</Section.Item>
          </Section.Row>
        </Section.Form>
        {additionalContent}
        <Section.ButtonsWrapper>
          <BaseButton
            title={buttonLabel}
            onPress={submitSection}
            disabled={(isSubmitted && !dirty) || submitLoading || submitDisabled}
            loading={submitLoading}
            testID="complete-profile-next-btn"
            variant="gradient"
          />
        </Section.ButtonsWrapper>
      </>
    );
  },
);

export const CompleteYourProfile = forwardRef<CompleteYourProfileRef, Props>((props, ref) => {
  return (
    <Section
      title={i18n.t('subscriptionProcess:completeYourProfile')}
      active={props.active}
      checked={props.isSubmitted}
      stepNo={props.stepNo}
      testID="complete-profile-section"
    >
      {props.active && <CompleteYourProfileForm ref={ref} {...props} />}
    </Section>
  );
});
