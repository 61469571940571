import { isObject, merge } from 'lodash-es';
import { useSelector } from 'react-redux';

import {
  ContentCardLocation,
  ContentItemBase,
  ContentStatus,
  SpecialAccordionLocation,
} from 'src/constants/types';
import {
  MixpanelService,
  MixpanelEvent,
  MixpanelQuickLinkName,
  getMixpanelQuickLinkLocation,
} from 'src/features/tracking';
import {
  getContentItemSource,
  isAlgorithm,
  isAppendix,
  isDxTx,
  isDrug,
  isDrugHandout,
  isHandout,
  getQuickLinksFromContentData,
} from 'src/helpers';
import { usePaywallInContentCard } from 'src/hooks';
import { Route } from 'src/navigation';
import { searchQuerySelector } from 'src/state/selectors';

import { ContentListItemCard } from './ContentListItemCard';
import { QuickLink } from './QuickLinks';

interface Props<T extends ContentItemBase> extends React.PropsWithChildren {
  data: T;
  location: ContentCardLocation | SpecialAccordionLocation;
  bottomBorder?: boolean;
  topBorder?: boolean;
  sideBorder?: boolean;
  roundedTopBorder?: boolean;
  roundedBottomBorder?: boolean;
  description?: string;
}

type LinkProps = React.ComponentProps<typeof ContentListItemCard>['linkProps'];

export const ContentListItem = <T extends ContentItemBase>({
  data,
  location,
  bottomBorder,
  topBorder,
  sideBorder,
  roundedTopBorder,
  roundedBottomBorder,
  description,
  children,
}: Props<T>) => {
  const { contentType, id, title, status, seen } = data;

  const { isContentBlocked, paywallElement, openPaywall, onCardPress } = usePaywallInContentCard({
    contentType: data.contentType,
  });

  const inDevelopment = status === ContentStatus.InDevelopment;
  const query = useSelector(searchQuerySelector);

  const getLinkProps = (): LinkProps => {
    const source = getContentItemSource(location);
    const searchQuery = source === 'search' ? query : undefined;
    if (isContentBlocked || inDevelopment) {
      return undefined;
    }

    const params = { id, source, searchQuery };

    if (isDrug(data)) {
      return {
        to: {
          route: Route.DrugItem,
          params,
        },
      };
    }

    if (isAppendix(data)) {
      return {
        to: {
          route: Route.AppendixItem,
          params,
        },
      };
    }
    if (isDxTx(data)) {
      return {
        to: {
          route: Route.DxTxItem,
          params,
        },
      };
    }

    if (isHandout(data)) {
      return {
        to: {
          route: isDrugHandout(data) ? Route.DrugHandoutItem : Route.ClinicalHandoutItem,
          params,
        },
      };
    }

    if (isAlgorithm(data)) {
      return {
        to: {
          route: Route.AlgorithmItem,
          params,
        },
      };
    }
  };

  const handleQuickLinkPress = (title: string) => {
    if (isContentBlocked) {
      openPaywall();
    } else {
      MixpanelService.track(MixpanelEvent.QuickLinkClicked, {
        'Drug ID': data.id,
        'Drug Title': data.title,
        'Link Name': title as MixpanelQuickLinkName,
        ...getMixpanelQuickLinkLocation(location as ContentCardLocation, isDrug(data) ? 'drug' : 'dx-tx')!,
      });
    }
  };

  const quickLinks: QuickLink[] | undefined = (() => {
    const links = getQuickLinksFromContentData(data);
    return links && !inDevelopment
      ? links?.map((link) => ({
          content: link.title,
          key: link.id,
          onPress: () => handleQuickLinkPress(link.title),
          linkProps: !isContentBlocked ? getQuickLinkProps(link.id, getLinkProps()) : undefined,
        }))
      : undefined;
  })();

  return (
    <>
      <ContentListItemCard
        title={title}
        contentType={contentType}
        linkProps={getLinkProps()}
        onPress={onCardPress}
        inDevelopment={inDevelopment}
        bottomBorder={bottomBorder}
        topBorder={topBorder}
        sideBorder={sideBorder}
        roundedTopBorder={roundedTopBorder}
        roundedBottomBorder={roundedBottomBorder}
        id={data.id}
        description={description}
        quickLinks={quickLinks}
        location={location}
        isNew={seen === false}
      >
        {children}
      </ContentListItemCard>
      {paywallElement}
    </>
  );
};

const getQuickLinkProps = (linkId: string, baseLinkProps?: LinkProps): LinkProps | undefined => {
  return baseLinkProps && isObject(baseLinkProps?.to)
    ? merge(baseLinkProps, {
        to: {
          params: {
            section: linkId,
          },
        },
      })
    : undefined;
};
