import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { DividerButton } from 'src/components/DividerButton';
import { Icon } from 'src/components/Icon/Icon';
import { Li } from 'src/components/Li';
import { StyledText } from 'src/components/StyledText';
import { PRO_SOLUTION_LINK, PVD_SOLUTION_LINK } from 'src/constants/externalLinks';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { i18n } from 'src/locale';
import { Link } from 'src/navigation/components';
import { ifWeb, palette, typography } from 'src/styles';

interface Props {}

interface ColumnProps {
  title: string;
  description: string;
  listTKey: string;
  linkText: string;
  linkTo: string;
  style?: StyleProp<ViewStyle>;
}

export const PlansComparison: React.FC<Props> = () => {
  const [isExpaned, setExpanded] = useState(false);
  const { isDesktop } = useDeviceInfo();

  const { t } = useTranslation('subscriptionDetails');

  const toggle = () => setExpanded((state) => !state);

  return (
    <View style={styles.wrapper}>
      {isExpaned && (
        <View style={[isDesktop && styles.columnsWrapperDesktop]}>
          <Column
            title={i18n.t('planVariants:normal')}
            description={t('pvdDescription')}
            listTKey="subscriptionDetails:pvdList"
            linkTo={PVD_SOLUTION_LINK}
            linkText={t('explorePvd')}
            style={[styles.column, isDesktop && styles.columnDesktop]}
          />
          <Column
            title={i18n.t('planVariants:pro')}
            description={t('proDescription')}
            listTKey="subscriptionDetails:proList"
            linkTo={PRO_SOLUTION_LINK}
            linkText={t('explorePro')}
            style={[styles.column, isDesktop && [styles.columnDesktop, styles.columnRightDesktop]]}
          />
        </View>
      )}
      <DividerButton
        title={t(isExpaned ? 'hidePlans' : 'comparePlans')}
        onPress={toggle}
        buttonSize="small"
      />
    </View>
  );
};

const Column: React.FC<ColumnProps> = ({ description, title, linkText, linkTo, listTKey, style }) => {
  return (
    <View style={style}>
      <StyledText style={[styles.title, typography.body4Bold]}>{title}</StyledText>
      <StyledText style={[styles.description, typography.body2]}>{description}</StyledText>
      <StyledText style={[typography.body3Bold]}>{i18n.t('subscriptionDetails:includes')}</StyledText>
      <View style={styles.list}>
        <Trans
          i18nKey={listTKey}
          components={{
            li: <Li />,
          }}
        />
      </View>
      <Link to={linkTo} external wrapperStyle={styles.linkWrapper}>
        {(isHovered) => (
          <View style={styles.link}>
            <StyledText
              style={[styles.linkText, isHovered && styles.linkTextHover, typography.body2SemiBold]}
            >
              {linkText}
            </StyledText>
            <Icon
              name="arrow-right-thin"
              color={isHovered ? palette.navy : palette.blue}
              style={[styles.linkArrow, isHovered && styles.linkArrowHover]}
            />
          </View>
        )}
      </Link>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: 30,
  },
  columnsWrapperDesktop: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  column: {
    paddingVertical: 20,
  },
  columnDesktop: {
    width: '50%',
    paddingRight: 25,
  },
  columnRightDesktop: {
    borderLeftWidth: 1,
    borderLeftColor: palette.grey2,
    paddingLeft: 25,
    paddingRight: 0,
  },
  title: {
    color: palette.blue,
    marginBottom: 8,
  },
  description: {
    marginBottom: 15,
  },
  list: {
    marginBottom: 20,
    marginTop: 5,
  },
  linkWrapper: {
    alignSelf: 'flex-start',
  },
  link: {
    flexDirection: 'row',
  },
  linkText: {
    color: palette.blue,
    ...ifWeb({
      transitionProperty: 'color',
    }),
  },
  linkTextHover: {
    color: palette.navy,
  },
  linkArrow: {
    marginLeft: 6,
    ...ifWeb({
      transitionProperty: 'transform',
    }),
  },
  linkArrowHover: {
    ...ifWeb({
      transform: [
        {
          translateX: 5,
        },
      ],
    }),
  },
});
