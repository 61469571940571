import { CardNumberElementChangeEvent, IndividualElementChangeEvent } from '@recurly/react-recurly';
import * as R from 'ramda';
import type { TokenPayload } from 'recurly__recurly-js';

import { Error400Response, PaginationListFetchParams, PaginationResponse } from 'src/constants/types';
import type {
  OccupationDetailsPayload,
  PersonalInformationPayload,
  ProfileInformation,
} from 'src/features/profile';
import type { StudentVerificationScreenParams } from 'src/features/studentVerification/types';

import { SubscriptionFormSource } from '../adminDashboard';

export enum RecurlyPlan {
  VETERINARY_PROFESSIONAL_BASIC_INDIVIDUAL = 'veterinary-professional-basic-individual',
  VETERINARY_PROFESSIONAL_BASIC_PRACTICE = 'veterinary-professional-basic-practice',
  PHARMACY_BASIC_STUDENT = 'pharmacy-basic-student',
  PHARMACY_BASIC_SINGLE = 'pharmacy-basic-single',
  PHARMACY_BASIC_MULTIPLE = 'pharmacy-basic-multiple',
  VETERINARY_PRO_INDIVIDUAL_ANNUAL = 'veterinary-pro-individual-annual',
  VETERINARY_PRO_INDIVIDUAL_MONTHLY = 'veterinary-pro-individual-monthly',
  VETERINARY_PRO_PRACTICE_ANNUAL = 'veterinary-pro-practice-annual',
  VETERINARY_PRO_PRACTICE_MONTHLY = 'veterinary-pro-practice-monthly',
  VETERINARY_PRO_STUDENT_ANNUAL = 'veterinary-pro-student-annual',
}

export type RecurlyStudentPlan =
  | RecurlyPlan.PHARMACY_BASIC_STUDENT
  | RecurlyPlan.VETERINARY_PRO_STUDENT_ANNUAL;

export enum Role {
  VETERINARY = 'veterinary-professional',
  VETERINARY_STUDENT = 'veterinary-student',
  PHARMACIST = 'pharmacist',
  PHARMACY_STUDENT = 'pharmacy-student',
}

export type StudentRole = Role.VETERINARY_STUDENT | Role.PHARMACY_STUDENT;

export enum Plan {
  NORMAL = 'normal',
  PRO = 'pro',
}

export enum BillingPeriod {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  FAILED = 'failed',
  FUTURE = 'future',
  PAUSED = 'paused',
}

export interface RegisterData {
  createAccount: CreateAccount;
  completeYourProfile: ProfileInformation;
}

interface SubscriptionBase {
  planSetup: PlanSetup;
  purchasePreview: Purchase;
  paymentDetails: BillingInformation;
}

export interface SubscriptionData extends SubscriptionBase, RegisterData {}

export interface ReviewYourSubscriptionData {
  createAccount: Pick<SubscriptionData['createAccount'], 'email'>;
  planSetup: SubscriptionData['planSetup'];
  completeYourProfile: {
    information: SubscriptionData['completeYourProfile']['information'];
  };
  purchasePreview: SubscriptionData['purchasePreview'];
  paymentDetails: SubscriptionData['paymentDetails'];
}

export type CreateAccount = {
  email: string;
  password: string;
  confirmPassword: string;
  confirmEmail: string;
};

export type BasePlanSetup = {
  role: Role;
  plan: Plan;
  billingPeriod: BillingPeriod;
};

export type PlanSetup = BasePlanSetup & {
  quantity: number;
  discountCode: string;
};

export interface PurchaseSubscriptionQueryParams {
  subscriptionData: SubscriptionData;
  billingInfoToken: TokenPayload;
  captchaToken: string;
  isTrial?: boolean;
  subscriptionInvitationId?: string;
  studentVerificationId?: string;
  promotionParams?: PromotionLandingScreenParams;
}

export interface SubscriptionBaseProps {
  stepNo: number;
  submit: (sectionData: Partial<SubscriptionData>) => void;
  isSubmitted: boolean;
  active: boolean;
  onDirtyChange?: (dirty: boolean) => void;
}

export type CardEvents = CardNumberElementChangeEvent | IndividualElementChangeEvent;

export enum CardType {
  AmericanExpress = 'American Express',
  Dankort = 'Dankort',
  DinersClub = 'Diners Club',
  Discover = 'Discover',
  Forbrugsforeningen = 'Forbrugsforeningen',
  JCB = 'JCB',
  Laser = 'Laser',
  Maestro = 'Maestro',
  MasterCard = 'MasterCard',
  TestCard = 'Test Card',
  UnionPay = 'Union Pay',
  Unknown = 'Unknown',
  Visa = 'Visa',
  TarjetaNaranja = 'Tarjeta Naranja',
}

export interface BillingAddress {
  address1: string;
  address2: string;
  city: string;
  country: string;
  postalCode: string;
  state: string;
}

export interface BillingInformation {
  creditCardInformation: {
    lastFour: string;
    expMonth: string;
    expYear: string;
    firstName: string;
    lastName: string;
    cardType: CardType;
  };
  billingAddress: BillingAddress;
}

export type GetBillingInformationResponse = BillingInformation | Record<string, never>;

export interface SubscriptionStatusInfo {
  priceAtRenewal: number;
  state: SubscriptionStatus;
  currentPeriodEndsAt: string;
  currentTermEndsAt: string;
  numberOfLicences: number;
  planCode: RecurlyPlan;
  couponCode: string;
  /** Whether the current plan allows for automatic renewal */
  autoRenew: boolean;
  remainingBillingCycles: number;
  totalBillingCycles: number;
  inTrial?: boolean;
  /** Promotion applied to user's subscription */
  promotion?: PromotionSetup;
}

export type SingleLicensePlan = RecurlyPlan;
export type QuantityDependentPlan = {
  single: RecurlyPlan | BillingPeriodDependentPlan;
  multiple: RecurlyPlan | BillingPeriodDependentPlan;
};
export type BillingPeriodDependentPlan = Record<BillingPeriod, RecurlyPlan>;

export type PlanConfig = SingleLicensePlan | QuantityDependentPlan | BillingPeriodDependentPlan;

export function isQuantityDependentPlan(plan: PlanConfig): plan is QuantityDependentPlan {
  return R.has('single', plan);
}

export function isBillingPeriodDependentPlan(plan: PlanConfig): plan is BillingPeriodDependentPlan {
  return R.has(BillingPeriod.MONTHLY, plan);
}

export type RoleConfig = {
  [Plan.NORMAL]?: PlanConfig;
  [Plan.PRO]?: PlanConfig;
};

export type RolesConfig = Record<Role, RoleConfig>;

export interface HasPlanParams {
  planCode: RecurlyPlan;
  quantity: number;
  discountCode?: string;
}

export interface PurchasePreviewPayload extends HasPlanParams {
  email: string;
  isTrial?: boolean;
  promotionId?: string;
  billingAddress?: BillingAddress;
  verificationId?: string; // for students verification
}

export type PurchasePreviewErrorResponse = Error400Response<PurchasePreviewPayload>;

export type Purchase = {
  unitAmount: number;
  baseUnitAmount: number;
  quantity: number;
  discount: number;
  annualDiscount: number;
  dueToday: number;
  dueAtRenewal: number;
  dueAtRenewalSubtotal?: number;
  dueAtRenewalTax?: number;
  renewalDate: string;
  notDiscountedUnitAmount: number;
  credit?: number;
  balance?: number;
  refund?: number;
  dueTodaySubtotal: number;
  dueTodayTax: number;
};

export type PurchaseSubscriptionAsExistingUserPayload = HasPlanParams & {
  /** Student Verification ID */
  verificationId?: string;
};

type PromotionPayload = {
  promotionId: string;
  hospitalName?: string;
  /** Required in all cases, except when the user is already a promotion participant
   * and they're using the same promotion to upgrade plan */
  userIdentifier?: string;
};

export type ApplyPromotionParams = PromotionPayload & {
  planCode: RecurlyPlan;
};

export type PurchaseSubscriptionAsNewUserPayload = PurchaseSubscriptionAsExistingUserPayload &
  PersonalInformationPayload &
  Partial<PromotionPayload> & {
    email: string;
    password: string;
    demographics: OccupationDetailsPayload & PersonalInformationPayload['demographics'];
    isTrial?: boolean;
    captchaToken: string;
    subscriptionInvitationId?: string;
    billingInfoTokenId?: string;
    verificationId?: string; // for student verification
  };

export interface ChangePlanPayload extends HasPlanParams {}

export interface GroupInvitationParams {
  memberId: string;
  encryptedEmail: string;
}

export interface GroupInvitationValidationPayload {
  memberId: string;
  email: string;
}

export interface GroupInvitationValidationResponse {
  email: string;
  isNew: boolean;
  isInvitedAsSupportStaff: boolean;
  groupName: string;
}

export interface AcceptGroupInvitationPayloadSignedOut {
  password: string;
  email: string;
  membershipId: string;
}

export interface AcceptGroupInvitationPayloadSignedIn {
  membershipId: string;
}

export type AcceptGroupInvitationPayload =
  | AcceptGroupInvitationPayloadSignedOut
  | AcceptGroupInvitationPayloadSignedIn;

export interface AcceptGroupInvitationResponse {
  access: string;
  refresh: string;
}

export type RegisterAsGroupMemberPayload = PersonalInformationPayload & {
  email: string;
  password: string;
  membershipId: string;
  demographics: OccupationDetailsPayload & PersonalInformationPayload['demographics'];
};

export interface SubscriptionResponse {
  id: string;
  invoiceNumber: string;
}

export interface CheckEmailPayload {
  email: string;
  password?: string;
  isNewSubscriber?: boolean;
}

export interface SubscriptionInvitationInfoResponse {
  plan: RecurlyPlan;
  quantity: number;
}

export interface ReactivateRenewalModalProps {
  subscriptionStatus: SubscriptionStatusInfo;
  cancel(): void;
  confirm(): void;
  isLoading?: boolean;
}
export interface GroupConversionScreenParams {
  id: string;
}

export interface GroupConversionInfo {
  plan:
    | RecurlyPlan.PHARMACY_BASIC_MULTIPLE
    | RecurlyPlan.VETERINARY_PROFESSIONAL_BASIC_PRACTICE
    | RecurlyPlan.VETERINARY_PRO_PRACTICE_MONTHLY
    | RecurlyPlan.VETERINARY_PRO_PRACTICE_ANNUAL;
  quantity: number;
  couponCode?: string;
}

export interface ConvertGroupPayload {
  id: string;
  data: {
    billingInfoTokenId: string;
    couponCode?: string;
  };
}

export interface InitialSignUpParams {
  role?: Role;
  plan?: Plan;
  quantity?: number;
  billing?: BillingPeriod;
  trial?: boolean;
}

export interface PromotionLandingScreenParams {
  promoId: string;
  firstName?: string;
  lastName?: string;
  country?: string;
  email?: string;
  /** Anything that clients might use to identify users in their internal systems.
  Not related to our user UUID. */
  userID: string;
  hospital?: string;
  isSubscribed?: boolean;
}

export interface SignUpScreenProps extends InitialSignUpParams, StudentVerificationScreenParams {}

export interface SubscriptionInvitationScreenProps {
  invitationId: string;
}

export type PromotionSetup = {
  id: string;
  campaignName: string;
  pageIntroCopyDefinition: string;
  calculationBoxIntro: string;
  calculationBoxAlternativeIntro: string;
  specialInstruction: string;
  subhead: string;
  basicPrice: number | null;
  proAnnualPrice: number | null;
  proMonthlyPrice: number | null;
  enableSubscriptionTermination: boolean;
  isUserIdentifierUnique: boolean;
  campaignUserType: 'existing' | 'new' | 'both';
};

export interface PurchasePreviewProps {
  loading: boolean;
  billingPeriod: BillingPeriod;
  discountCode: string;
  purchasePreview?: Purchase;
  plan: Plan;
  role: Role;
  isTrial?: boolean;
  overMaxQuantity?: boolean;
  taxNotCalculated?: boolean;
  isError?: boolean;
}

export interface Invoice {
  id: string;
  createdAt: string;
  total: number;
  number: number;
  pdfLink?: string;
}

export type InvoicesSortableColumns = 'createdAt' | 'total' | 'number';

export type InvoicesOrdering = InvoicesSortableColumns | `-${InvoicesSortableColumns}`;
export interface InvoicesListParams {
  ordering?: InvoicesOrdering;
}
export interface InvoicesListFetchParams extends Required<PaginationListFetchParams>, InvoicesListParams {}

export interface InvoicesListFetchResponse extends PaginationResponse<Invoice> {}

export enum SubscriptionFormSectionIndex {
  Account = 0,
  PlanDetails = 1,
  Profile = 2,
  Payment = 3,
}

export interface PlanUpdateParams {
  isUpgradingToPro: boolean;
  isIncreasingNumberOfLicences: boolean;
  source?: SubscriptionFormSource;
}
