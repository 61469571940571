import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, StyleSheet } from 'react-native';

import { BaseButton } from 'src/components';
import { useNativeKeyboard } from 'src/hooks/useNativeKeyboard';

import { EmailHandoutsForm } from './EmailHandoutsForm';
import { useHandoutsEmailing } from '../hooks';
import type { ShareHandoutsFormProps } from '../types';

interface Props extends ShareHandoutsFormProps {}

export const EmailHandouts: React.FC<Props> = ({ onShareSuccess }) => {
  const { t } = useTranslation('handouts');
  const {
    errors,
    handleOnBlur,
    handleOnChange,
    handleSubmit,
    loading,
    touched,
    values,
    dirty,
    isValid,
    selectedHandouts,
    setValues,
  } = useHandoutsEmailing({ onSuccess: onShareSuccess });

  const { keyboardHeight } = useNativeKeyboard();

  const marginAnim = useMemo(() => new Animated.Value(0), []);

  const animateMargin = useCallback(
    (newMargin: number) => {
      Animated.timing(marginAnim, {
        toValue: newMargin,
        duration: 500,
        useNativeDriver: false,
      }).start();
    },
    [marginAnim],
  );

  useEffect(() => {
    // we need to add extra margin on the bottom to avoid the keyboard overlapping the submit button
    // this is because of weird behavior of KeyboardAvoidingView on iOS
    // which adds extra white space at the end of the View when we have more than one text field.
    animateMargin(keyboardHeight);
  }, [animateMargin, keyboardHeight]);

  return (
    <Animated.View style={{ marginBottom: marginAnim }} testID="email-handouts-form">
      <EmailHandoutsForm
        errors={errors}
        handleOnBlur={handleOnBlur}
        handleOnChange={handleOnChange}
        touched={touched}
        values={values}
        setValues={setValues}
      />
      <BaseButton
        title={t('email', { count: selectedHandouts.length })}
        variant="gradient"
        icon="chevron-right-circle"
        containerStyle={styles.submitButton}
        titleStyle={styles.submitButtonTitle}
        loading={loading}
        disabled={!dirty || !isValid || loading}
        onPress={handleSubmit}
        testID="email-handout-btn"
      />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  submitButton: {
    alignSelf: 'flex-start',
    marginTop: 28,
  },
  submitButtonTitle: {
    textTransform: 'none',
  },
});
