import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';

import { BaseButton, StyledText } from 'src/components';
import type { PersonalInformation } from 'src/features/profile';
import { showNotification } from 'src/helpers';
import { useCountriesList } from 'src/hooks/queries/countries';
import { useDeviceInfo } from 'src/hooks/useDeviceInfo';
import { palette } from 'src/styles';

import { StepSummary } from './StepSummary';
import { TotalDue } from './TotalDue';
import { getCreditCardNumberPlaceholder, getCvvPlaceholder, isStudentRole } from '../../helpers';
import { usePurchasePreview } from '../../hooks';
import { BillingPeriod, Plan, ReviewYourSubscriptionData, SubscriptionFormSectionIndex } from '../../types';
import { PurchasePreview } from '../PurchasePreview';
import { SubscriptionSection as Section } from '../SubscriptionSections';
import { TermsAndConditionsCheck } from '../TermsAndConditionsCheck';

const emptyInitialInformation: PersonalInformation = {
  firstName: '',
  lastName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: '',
  phone: '',
  bornYear: '',
  postalCode: '',
  practice: '',
};

export interface Props {
  data: ReviewYourSubscriptionData;
  submit: () => void;
  submitting?: boolean;
  dirty: boolean;
  active: boolean;
  stepNo: number;
  isTrial?: boolean;
  promotionId?: string;
  studentVerificationId?: string;
  scrollToStep?: (step: SubscriptionFormSectionIndex) => void;
}

export const SubscriptionReview: React.FC<Props> = ({
  data,
  submit,
  submitting,
  dirty,
  isTrial,
  promotionId,
  studentVerificationId,
  scrollToStep,
}) => {
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const { t } = useTranslation('subscriptionProcess');
  const { isTablet, isDesktop } = useDeviceInfo();
  const { getCountryFullName, getStateFullName } = useCountriesList();

  const handlePurhcasePreviewError = useCallback((errors: Record<string, string>) => {
    const message = Object.values(errors).join('\n');
    showNotification({ type: 'error', title: message, autoHide: false });
  }, []);

  const {
    purchasePreview,
    isLoading: isPurchasePreviewLoading,
    purchasePreviewComponentProps,
    isError: isPurchasePreviewError,
  } = usePurchasePreview({
    ...data.planSetup,
    email: data.createAccount.email,
    billingAddress: data.paymentDetails.billingAddress,
    isTrial,
    promotionId,
    onError: handlePurhcasePreviewError,
    studentVerificationId,
  });

  const { email } = data.createAccount;

  const { billingPeriod, quantity, plan, role } = data.planSetup;
  const studentPlan = isStudentRole(role);

  const planSummary = {
    [t('plan')]: t(plan === Plan.NORMAL ? 'planVariants:normal' : 'planVariants:pro'),
    [t('users')]: t('numUsers', { count: quantity }),
    [t('billingCycle')]: t(billingPeriod === BillingPeriod.ANNUAL ? 'annual' : 'monthly'),
    [t('contractTerm')]: t('numMonths', { count: 12 }),
  };

  const { firstName, lastName, address1, address2, city, state, country, phone } =
    data.completeYourProfile.information || emptyInitialInformation;

  const stateName = state ? getStateFullName(country, state) : '';
  const countryName = getCountryFullName(country);

  const accountSummary = {
    [t('fullName')]: `${firstName} ${lastName}`,
    [t('address')]: [address1, address2, city, stateName].filter(Boolean).join(', '),
    [t('country')]: countryName,
    [t('phoneNumber')]: phone,
  };

  const {
    firstName: cardFirstName,
    lastName: cardLastName,
    lastFour,
    cardType,
  } = data.paymentDetails.creditCardInformation;
  const cardSummary = {
    [t('nameOnCard')]: `${cardFirstName} ${cardLastName}`,
    [t('brand')]: cardType,
    [t('cardNumber')]: getCreditCardNumberPlaceholder(lastFour, cardType),
    [t('cvv')]: getCvvPlaceholder(cardType),
  };

  return (
    <>
      <View style={isTablet && styles.stepsSummaryHorizontal}>
        <StepSummary
          title={t('planInformation')}
          data={planSummary}
          scrollToStep={scrollToStep && (() => scrollToStep(SubscriptionFormSectionIndex.PlanDetails))}
          containerStyle={isTablet ? styles.stepSummaryDesktop : styles.stepSummarySpacing}
          testID="review-subscription-plan-information"
        />
        <StepSummary
          title={t('accountInformation')}
          data={accountSummary}
          scrollToStep={scrollToStep && (() => scrollToStep(SubscriptionFormSectionIndex.Account))}
          containerStyle={isTablet ? styles.stepSummaryDesktop : styles.stepSummarySpacing}
          testID="review-subscription-account-information"
        />
        <StepSummary
          title={t('cardInformation')}
          data={cardSummary}
          scrollToStep={scrollToStep && (() => scrollToStep(SubscriptionFormSectionIndex.Payment))}
          containerStyle={isTablet && styles.stepSummaryDesktop}
          testID="review-subscription-card-information"
        />
      </View>
      <StyledText style={styles.emailInfo}>
        <Trans
          i18nKey="subscriptionProcess:emailConfirmation"
          components={{ b: <StyledText style={styles.email} /> }}
          values={{ email }}
        />
      </StyledText>
      <View style={isTablet && styles.paymentSummaryHorizontal}>
        <PurchasePreview {...purchasePreviewComponentProps} />
        <TotalDue
          total={purchasePreview?.dueToday}
          billingPeriod={billingPeriod}
          containerStyle={[
            isTablet ? styles.totalDue : styles.totalDueVertical,
            isDesktop && styles.totalDueDesktop,
          ]}
          isYearlyPrice={purchasePreview?.dueToday === purchasePreview?.dueAtRenewal}
          isLoading={isPurchasePreviewLoading}
        />
      </View>
      <Section.ButtonsWrapper>
        <TermsAndConditionsCheck isChecked={termsAndConditions} onChange={setTermsAndConditions} />
        <BaseButton
          disabled={!termsAndConditions || dirty || isPurchasePreviewLoading || isPurchasePreviewError}
          title={t('subscriptionProcess:purchaseSubscription')}
          onPress={submit}
          loading={submitting}
          testID="review-subscription-purchase-btn"
          variant="gradient"
        />
      </Section.ButtonsWrapper>
      <View style={[styles.disclaimerWrapper, !isTablet && styles.disclaimerWrapperSmall]}>
        <StyledText style={styles.disclaimer}>
          {t(`subscriptionProcess:disclaimer:${studentPlan ? 'student' : 'vetOrPharmacist'}`)}
        </StyledText>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  stepsSummaryHorizontal: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  stepSummaryDesktop: {
    width: '31.5%',
  },
  stepSummarySpacing: {
    marginBottom: 20,
  },
  emailInfo: {
    marginVertical: 32,
    textAlign: 'center',
  },
  email: {
    fontWeight: 'bold',
  },
  paymentSummaryHorizontal: {
    flexDirection: 'row',
  },
  totalDue: {
    width: '50%',
    marginLeft: '2.5%',
  },
  totalDueDesktop: {
    width: '40%',
  },
  totalDueVertical: {
    marginTop: 20,
    minHeight: 200,
  },
  disclaimerWrapper: {
    alignItems: 'center',
    marginTop: 32,
    borderTopWidth: 1,
    borderTopColor: palette.grey2,
    paddingTop: 32,
    paddingBottom: 12,
  },
  disclaimerWrapperSmall: {
    marginTop: 20,
    paddingTop: 20,
    paddingBottom: 0,
  },
  disclaimer: {
    color: palette.grey8,
    textAlign: 'center',
    maxWidth: 957,
  },
});
