import { createRef, useEffect, useMemo } from 'react';
import { View, ViewStyle } from 'react-native';

import { isWeb } from 'src/helpers';

type FormStep<T> = (
  submitStep: (data: T) => void,
  stepNumber: number,
  scrollToStep: (stepNo: number) => void,
) => JSX.Element;

interface Props<T> {
  submitCallback: (data: T) => void;
  children: (FormStep<T> | undefined)[];
  stepStyle?: ViewStyle;
  /**
   * NOTE: this does not update when the user scrolls. Only changes when the
   * step is manually changed
   */
  focusedStep: number | undefined;
  setFocusedStep: (desiredStep: number) => void;
}

export const SteppedForm = <T,>({
  children,
  submitCallback,
  stepStyle,
  focusedStep,
  setFocusedStep,
}: Props<T>) => {
  const stepRefs = useMemo(
    () => Array.from({ length: children.length }, () => createRef<View>()),
    [children.length],
  );

  /*
   * We must delay the scrolling to be after any renders (hence the effect).
   *
   * Otherwise, the browsers starts scolling before the rerenders finish and
   * stops at what used to be the bottom of the page. The result is the browser
   * scrolling not far enough.
   *
   * This is a problem on GroupInvitationForm and GroupConversionForm.
   * The number of steps on SubscriptionForm masks the problem.
   */
  useEffect(() => {
    if (!isWeb || focusedStep === undefined) {
      return;
    }
    const ref = stepRefs[focusedStep];
    const element = ref.current as unknown as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [focusedStep, stepRefs]);

  const scrollHandlers = useMemo(
    () =>
      Array.from({ length: children.length }, (_, index) => () => {
        const nextValidIndex = children.findIndex((child, childIndex) => childIndex > index && child);
        if (nextValidIndex === -1) {
          return;
        }

        setFocusedStep(nextValidIndex);
      }),
    [children, setFocusedStep],
  );

  const submitHandlers = useMemo(
    () =>
      scrollHandlers.map((scrollToNextSection) => (data: T) => {
        submitCallback(data);
        scrollToNextSection();
      }),
    [scrollHandlers, submitCallback],
  );

  return (
    <>
      {children.map((child, step) => (
        <View ref={stepRefs[step]} style={stepStyle} key={step}>
          {child?.(submitHandlers[step], step + 1, setFocusedStep)}
        </View>
      ))}
    </>
  );
};
